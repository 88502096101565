<div id="review-repair-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-2 md:w-90-percent review-return">
                <div>
                    <!-- <h1 class="lg:hidden">{{ 'recycle_review.title' | translate }}</h1> -->
                    <div class="flex justify-between w-full gap-2 md:flex-column">
                        <div class="flex flex-column gap-1 md:w-full w-50-percent">
                            <div class="flex flex-column gap--5 return-items">
                                <h2>{{ 'recycle_review.recycle_items' | translate }}</h2>
                                <div *ngFor="let product of productsToRecycle" class="flex flex-column gap-1 block-white">
                                    <div class="flex align-center relative h-full gap-1 product-container">
                                        <div *ngIf="product?.image_url" class="product-image"
                                            [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat', height: '80px'}">
                                        </div>
                                        <div class="product-details-left">
                                            <div class="bold-featured-text">{{product.name}}</div>
                                            <div>{{product.option_2.value}} / {{product.option_1.value}}</div>
                                            <div *ngIf="product.quantity" class="md:block hidden">x{{product.quantity}}
                                            </div>
                                        </div>
                                        <div *ngIf="product.quantity" class="product-details-right md:hidden block">
                                            <div>x{{product.quantityRecycle}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-column gap-1 md:w-full w-50-percent">
                            <div class="flex flex-column gap--5">
                                <h2>{{ 'repair_review.personal_information' | translate }}</h2>
                                <div *ngIf="returnDetails" class="block-white flex flex-column gap--5">
                                    <div>{{returnDetails?.first_name}} {{returnDetails?.last_name}}</div>
                                    <div class="flex justify-between gap--5">
                                        <div *ngIf="!editEmail">{{emailAddress}}</div>
                                        <form #form="ngForm" *ngIf="editEmail" class="w-full flex flex-column">
                                            <div class="flex sm:flex-column gap--5">
                                                <div class="relative flex flex-column w-full form-input">
                                                    <input id="emailAddress" type="text" [(ngModel)]="emailAux" required
                                                        (keypress)="saveEmail($event, true)" name="emailAddress"
                                                        required>
                                                    <div *ngIf="!validEmail() && submittedEmail"
                                                        class="extra-text-invalid height-24 flex align-center">{{
                                                        'repair_review.valid_email' |
                                                        translate }}</div>
                                                </div>
                                            </div>
                                        </form>
                                        <a *ngIf="!editEmail" class="flex align-center extra-text"
                                            style="max-height: 3rem; color: var(--color-primary)" (click)="editEmail = true">{{
                                            'repair_review.edit' |
                                            translate }}</a>
                                        <a *ngIf="editEmail" class="flex align-center extra-text"
                                            style="max-height: 3rem; color: var(--color-primary)" (click)="saveEmail($event, false)">{{
                                            'repair_review.save' |
                                            translate }}</a>
                                    </div>
                                </div>
                                <div *ngIf="!returnDetails" class="block-white flex flex-column gap-1">
                                    <a class="flex extra-text" style="color: var(--color-primary)"
                                        [class]="{'error-message' : submitted && errorSubmitted}"
                                        (click)="addPersonalInfo()">
                                        {{'repair_review.add_personal_info' | translate }}</a>
                                    <div class="error-message-warning" style="justify-content: start;">
                                        <div class="flex">
                                            <mat-icon>error_outline</mat-icon>
                                        </div>
                                        {{'repair_review.provide_personal_info' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-column gap--5">
                                <h2>{{ 'recycle_review.delivery_address' | translate }}</h2>
                                <div *ngIf="dataAddress" class="block-white flex justify-between">
                                    <div>
                                        <div>{{dataAddress?.full_name}}</div>
                                        <div>{{dataAddress?.address1}}</div>
                                        <div>{{dataAddress?.address2}}</div>
                                        <div>{{dataAddress?.city}} {{dataAddress?.zip}}</div>
                                        <div>{{dataAddress?.country}}</div>
                                    </div>
                                    <a class="flex align-center extra-text" style="color: var(--color-primary)"
                                        (click)="editAddress()">{{ 'repair_review.edit' | translate }}</a>
                                </div>
                                <div *ngIf="!dataAddress" class="block-white flex flex-column gap-1">
                                    <a class="flex extra-text" style="color: var(--color-primary)"
                                        [class]="{'error-message' : submitted && errorSubmitted}"
                                        (click)="editAddress()">
                                        {{'repair_review.add_address' | translate }}</a>
                                    <div class="error-message-warning" style="justify-content: start;">
                                        <div class="flex">
                                            <mat-icon>error_outline</mat-icon>
                                        </div>
                                        {{'repair_review.provide_location' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="error-message-warning" style="justify-content: start;">
                                <div class="flex">
                                    <mat-icon>error_outline</mat-icon>
                                </div>
                                {{'recycle_review.message_label_recycle' | translate }}
                            </div>
                            <button class="w-full button-btn" type="submit" (click)="submitRecycle()"
                                [disabled]="!dataAddress || !returnDetails">
                                <div class="flex h-100 relative gap-1 align-center justify-center">
                                    <div class="relative justify-start">
                                        <mat-spinner *ngIf="loadButton"></mat-spinner>{{
                                        'recycle_review.recycle_items' | translate }}
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer></footer>