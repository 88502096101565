import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ReturnService } from 'src/app/service/return.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductReturn, Return } from 'src/app/api/return';
import { MatDialog } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-return-review',
  templateUrl: './return-review.component.html',
  styleUrls: ['./return-review.component.css']
})
export class ReviewReturnComponent implements OnInit {

  title = "Review your return";

  steps = [""]

  stepID = 2;

  previousRoute = '/return/return-resolution';

  orderInfo: any = JSON.parse(localStorage.getItem('orderInfo')!)

  selectPaymentOption = JSON.parse(localStorage.getItem('selectPaymentOption')!)

  dataAddress: any = JSON.parse(localStorage.getItem('dataAddressR')!);

  shipping: any = JSON.parse(localStorage.getItem('shipping')!);

  returnDetails: any = JSON.parse(localStorage.getItem("returnDetails")!);

  products: any = JSON.parse(localStorage.getItem('products')!);

  productsReturn: any = JSON.parse(localStorage.getItem('productsReturn')!)

  returnFees: any = JSON.parse(localStorage.getItem('returnFees')!);

  currency: any = JSON.parse(localStorage.getItem('currency')!);

  hasLabel: any = JSON.parse(localStorage.getItem('hasLabel')!);

  IBAN: any = JSON.parse(localStorage.getItem('IBAN')!);

  returnableProducts: any = [];

  totalQuantityReturn: number = 0;

  totalRefund: number = 0;

  totalAll: number = 0;

  submitted: boolean = false;

  loadButton: boolean = false;

  errorSubmitted: boolean = false;

  heightImg: number = 80;

  haveRefunds: boolean = false;

  images: any = [];

  countries: any = [];

  paymentActive: boolean = false;

  error: any;

  acceptTerms: boolean = false;

  terms_conditions: any = environment.terms_conditions;

  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef | undefined;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    private readonly translateService: TranslateService,
    private cd: ChangeDetectorRef,

  ) { }

  async ngOnInit(): Promise<void> {

    // load data into steps, translated
    await this.translateService.get('dummyTranslation').toPromise().then();

    this.translateService.stream('review').subscribe(val => {
      this.title = val.title
      this.steps = [val.steps.choose_item, val.steps.resolve, val.steps.review]
    });

    this.haveRefunds = false
    this.returnableProducts = this.products.filter((p: any) => (p.return_reasons[0]?.exchange == false && p.return_reasons[0].option.length !== 0) || (p.return_reasons[0]?.exchange == true))
    this.returnableProducts.map((item: any) => {
      this.totalQuantityReturn = this.totalQuantityReturn + item.totalQuantityReturn
      item.return_reasons.map((return_reason: any) => {
        if (!return_reason.exchange) {
          this.haveRefunds = true;
          this.totalRefund = this.totalRefund + return_reason.quantityReturn * item.price
        }
      });
      this.totalAll = this.totalRefund
      if(this.selectPaymentOption.fee_flag == 1 && this.returnFees[0]){
        this.totalAll -= this.returnFees[0].value
      }
    });

    this.countries = await this.returnService.getCountries();

    if (!this.dataAddress) {
      this.dataAddress = {
        first_name: this.returnDetails.first_name,
        last_name: this.returnDetails.last_name,
        full_name: this.returnDetails.first_name + ' ' + this.returnDetails.last_name,
        address1: this.shipping.address,
        address2: this.shipping.address2,
        province: this.shipping.province,
        zip: this.shipping.zipcode,
        city: this.shipping.city,
        country: this.shipping.country,
        country_id: this.countries.find((country: any) => country.country_code == this.shipping.country_code).country_id,
        country_code: this.shipping.country_code,
        phone: this.shipping.phone
      }
    }

    this.totalRefund = parseFloat(this.totalRefund.toFixed(2))
    this.totalAll = parseFloat(this.totalAll.toFixed(2))
    this.images = await this.returnService.GetAllImages();
  }

  editAddress() {
    this.dialog.open(ReturnAddressDialog, { disableClose: false, width: '600px', maxWidth: '90%' });
  }

  previousPage() {
    var nonExchangesProducts = this.products.filter((p: any) => p.return_reasons.some((r: any) => (!r.exchange && (r.option.length > 0 || (r.option && r.option.option)))));
    if (nonExchangesProducts.length == 0) {
      this.router.navigate(['/return/return-request']);
    } else {
      this.router.navigate(['/return/return-resolution']);
    }
  }

  async submitReturn() {
    this.submitted = true
    this.loadButton = true
    this.errorSubmitted = false
    if (this.acceptTerms) {
      var productsReturn: ProductReturn[] = []
      for (var products of this.returnableProducts) {
        for (var return_reason of products.return_reasons) {
          var img: any[] = []
          return_reason.images.map((i: any) => {
            img.push(this.images.find((el: any) => el.id == i.image).base64)
          })
          var productReturn: ProductReturn = {
            line_item_id: products.line_item_id,
            product_id: products.product_id,
            sku: products.sku,
            ean: products.ean,
            quantity: return_reason.quantityReturn,
            exchange: return_reason.exchange,
            product_id_exc: return_reason.product_id_exc ? return_reason.product_id_exc : null,
            variant_id_exc: return_reason.variant_id_exc ? return_reason.variant_id_exc : null,
            return_id: return_reason.option ? return_reason.option.option_id : JSON.parse(localStorage.getItem('exchangeOption')!),
            comment: return_reason.comment ? return_reason.comment : null,
            images: img
          }
          productsReturn.push(productReturn)
        }
      }



      // last_name: this.returnDetails.last_name,
      var orderReturn: Return = {
        id: this.orderInfo.id,
        first_name: this.dataAddress.full_name.split(' ')[0],
        last_name: this.dataAddress.full_name.split(' ').pop(),
        address_name: this.dataAddress.full_name,
        address_line1: this.dataAddress.address1,
        address_line2: this.dataAddress.address2,
        phone_number: this.dataAddress.phone.toString(),
        zipcode: this.dataAddress.zip,
        province: this.dataAddress.province,
        city: this.dataAddress.city,
        id_country: this.dataAddress.country_id,
        ordername: this.orderInfo.ordername,
        email: this.orderInfo.email,
        id_return_payment: this.haveRefunds ? this.selectPaymentOption.id_return_payment : JSON.parse(localStorage.getItem('exchangePayment')!),
        return_fee_id: this.returnFees[0]?.return_fee_id,
        order_lines: productsReturn,
        pre_return: Number(this.hasLabel),
        IBAN: this.IBAN,
        country_code: this.countries.find((country: any) => country.country_id === this.dataAddress.country_id).country_code,
      }

      this.returnService.postReturn(orderReturn).then(
        data => {
          this.router.navigate(['/return/return-success'])
        },
        error => {
          this.errorSubmitted = true
          this.loadButton = false
          setTimeout(async () => {
            await this.returnService.getLoginSession(this.orderInfo.idBrand).then((appDetails) => {
              sessionStorage.setItem('appDetails', JSON.stringify(appDetails.brand))
            })
          }, 2500);
        }
      )
    } else {
      this.loadButton = false
    }
  }

  getImageById(id: any) {
    return this.images.find((el: any) => el.id == id)?.base64
  }
}



@Component({
  selector: 'address-dialog',
  templateUrl: 'address-dialog.html',
})
export class ReturnAddressDialog {

  selectedCountryCallingCode: any = null;

  email: any = null;

  full_name: any = null;

  address: any = null;

  address2: any = "";

  city: any = null;

  zip: any = null;

  province: any = null;

  phone: any = null;

  submitted: boolean = false;

  selectedAddress: any = null;

  selectedCountry: any = null;

  countries: any = [];

  dataAddress = JSON.parse(localStorage.getItem('dataAddressR')!);

  login_data: any = JSON.parse(localStorage.getItem('login_data')!);

  countryCallingCode: any = [];

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    public readonly translateService: TranslateService,
    private cd: ChangeDetectorRef,

  ) { }

  async ngOnInit() {
    this.countries = await this.returnService.getCountriesWithReturnFee()
    this.countryCallingCode = await this.returnService.getCountriesX();

  }
  /* changeSelected() {
     this.selectedCountryCallingCode = this.countryCallingCode.find((c: any) => c == this.selectedCountryCallingCode).cca2
   }*/

  async saveAddress() {
    this.submitted = true
    if (this.full_name && this.address && this.city && this.province && this.zip && this.phone && this.selectedCountry && this.selectedCountryCallingCode && this.selectedCountry) {
      //const country_code = this.countries.find((country:any) => country.country_id === this.selectedCountry).country_code
      const callingCode = this.countryCallingCode.find((country: any) => country.cca2 == this.selectedCountryCallingCode)
      const callingCodePref = callingCode.idd.root + callingCode.idd.suffixes[0]

      this.dataAddress = {
        full_name: this.full_name,
        address1: this.address,
        address2: this.address2,
        province: this.province,
        zip: this.zip,
        city: this.city,
        country_id: this.selectedCountry,
        country: this.countries.find((country: any) => country.country_id == this.selectedCountry).country_name,
        phone: callingCodePref + this.phone.toString()
      }
      // var returnFeeSelected = this.countries.find((country: any) => country.country_id == this.selectedCountry)
      localStorage.setItem('dataAddressR', JSON.stringify(this.dataAddress));
      //localStorage.setItem('returnFeeSelectedR', JSON.stringify(returnFeeSelected));
      new ReviewReturnComponent(this.router, this.dialog, this.returnService, this.translateService, this.cd).dialog.closeAll()
      window.location.reload();
    }
  }

  cancelAddress() {
    this.full_name = null
    this.address = null
    this.address2 = null
    this.city = null
    this.zip = null

    new ReviewReturnComponent(this.router, this.dialog, this.returnService, this.translateService, this.cd).dialog.closeAll()
  }
}