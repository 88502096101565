import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ReturnService } from 'src/app/service/return.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/service/notification.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-return-request',
  templateUrl: './return-request.component.html',
  styleUrls: ['./return-request.component.css']
})
export class RequestReturnComponent implements OnInit {

  title = "What would you like to return?";

  steps = [""]

  stepID = 0;

  previousRoute = '/menu';

  appDetails: any = this.main.appDetails;

  currency: any = JSON.parse(localStorage.getItem('currency')!);

  permissionExchange: boolean = JSON.parse(localStorage.getItem('permissionExchange')!);

  returnOptions: any = JSON.parse(localStorage.getItem('options')!);

  productsReturn: any = JSON.parse(localStorage.getItem('productsReturn')!);

  products: any = JSON.parse(localStorage.getItem('products')!);

  hasLabel: any = JSON.parse(localStorage.getItem('hasLabel')!);

  returnableProducts: any = [];

  nonReturnableProducts: any = [];

  permissionExchangeProduct: boolean = JSON.parse(localStorage.getItem('permissionExchangeProduct')!);

  selectedProduct: any = { dialogProduct: false };

  moreDetails: boolean = false;

  moreDetailsOption: any;

  submitted: boolean = false;

  submittedSize: boolean = false;

  errorSize: boolean = false;

  error_upload: boolean = false;

  error_upload_format: boolean = false;

  allOutOfStock: boolean = false;

  heightImg: number = 80;

  heightDialog: number = 0;

  images: any = []

  imagesUpload: any = []

  loadingSizesExchange: boolean = false;

  constructor(
    private main: AppComponent,
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    private readonly translateService: TranslateService,
    private notificationService: NotificationService
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.appDetails["2hand"] == 0 && this.appDetails.repair == 0) {
      this.notificationService.updateData(JSON.parse(localStorage.getItem('not')!));
    }

    // load data into steps, translated
    await this.translateService.get('dummyTranslation').toPromise().then();

    this.translateService.stream('request').subscribe(val => {
      this.title = val.title
      this.steps = [val.steps.choose_item, val.steps.resolve, val.steps.review]
    });


    if (!localStorage.getItem('hasLabel') && environment.pre_label == true) {
      this.dialog.open(LabelDialog, { disableClose: true });
    }
    this.currency = JSON.parse(localStorage.getItem('currency')!);
    this.permissionExchange = JSON.parse(localStorage.getItem('permissionExchange')!);
    this.permissionExchangeProduct = JSON.parse(localStorage.getItem('permissionExchangeProduct')!);
    var permission2Hand = JSON.parse(sessionStorage.getItem('permission2Hand')!);
    var permissionRepair = JSON.parse(sessionStorage.getItem('permissionRepair')!);

    if (!permission2Hand && !permissionRepair) {
      this.previousRoute = ''
    }
    for (var product of this.products) {
      if (product.return_reasons && product.return_reasons.length > 1 && (!product.return_reasons[product.return_reasons.length - 1].exchange && product.return_reasons[product.return_reasons.length - 1].option.length == 0)) {
        product.return_reasons.pop()
      }
    }

    if (this.products) {
      this.productsReturn = this.products.filter((p: any) => p.return_reasons.some((r: any) => r.exchange || (!r.exchange && (r.option.length > 0 || (r.option && r.option.option)))))
      this.returnableProducts = this.products.filter((p: any) => p.returnable == true)
      this.nonReturnableProducts = this.products.filter((p: any) => p.returnable == false)
    }

    window.addEventListener('resize', this.resizeDialog)
    window.addEventListener('resize', this.resizeDialogReturn)
    this.images = await this.returnService.GetAllImages();
  }

  async onFileSelected(event: any) {
    var images = this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].images
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        await this.returnService.InsertImages(reader.result);
        let id_db: any = await this.returnService.GetLastInput()
        this.images.push({ id: id_db.id, base64: id_db.base64 })
        this.imagesUpload.push(id_db.id)
        this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].images.push({ id: images.length, image: id_db.id });
        localStorage.setItem('products', JSON.stringify(this.products));
        resolve(reader.result)
      };
      reader.onerror = error => reject(error);
    });

    for (var file of event.target.files) {
      var imagesTypesAccepted = ['image/png', 'image/jpeg', 'image/webp']
      if (images.length < 6 && imagesTypesAccepted.includes(file.type)) {
        await toBase64(file)
      } else if (!imagesTypesAccepted.includes(file.type)) {
        this.error_upload_format = true
      } else {
        this.error_upload = true
      }
      setTimeout(() => {
        this.error_upload_format = false
        this.error_upload = false
      }, 5000)
    }
  }

  getImageById(id: any) {
    return this.images.find((el: any) => el.id == id)?.base64
  }

  async onSubmit() {
    this.submitted = true;
    var error = false;
    if (this.moreDetailsOption.comment && this.moreDetailsOption.requiredComment && !this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].comment ||
      this.moreDetailsOption.image && this.moreDetailsOption.requiredImage && this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].images.length == 0) {
      error = true;
    }

    if (!error) {
      this.submitted = false;
      this.error_upload = false;
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].exchange = false
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].option = this.moreDetailsOption
      this.productsReturn.push(this.selectedProduct);
      this.selectedProduct.dialogProduct = false;
      this.moreDetails = false;
      if (!this.selectedProduct.edit) {
        this.selectedProduct.totalQuantityReturn++
      }
    }
    localStorage.setItem('products', JSON.stringify(this.products))
  }

  onSubmitExchange() {
    this.submittedSize = true;

    if (this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].variant_id_exc && this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].product_id_exc) {
      this.submittedSize = false;
      this.errorSize = false
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].exchange = true
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].option = null
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].comment = null
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].images = []
      this.productsReturn.push(this.selectedProduct);
      this.selectedProduct.dialogProduct = false;
      this.moreDetails = false;
      if (!this.selectedProduct.edit) {
        this.selectedProduct.totalQuantityReturn++
      }
      localStorage.setItem('products', JSON.stringify(this.products))
    } else {
      this.errorSize = true
    }
  }

  async removeImage(idFile: number) {
    this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].images = this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].images.filter((e: any) => e.image != idFile)
    await this.returnService.DeleteImage(idFile);
    this.images = await this.returnService.GetAllImages();
  }

  selectProduct(product: any) {
    this.submittedSize = false
    this.imagesUpload = []
    this.permissionExchangeProduct = this.permissionExchange && product.exchangeable ? true : false
    if (!this.productsReturn.includes(product)) {
      if (product.return_reasons.length == 0) {
        // this.permissionExchangeProduct = this.permissionExchange && product.exchangeable ? true : false
        localStorage.setItem('permissionExchangeProduct', JSON.stringify(this.permissionExchangeProduct));
        product.actual_reason = 0
        product.totalQuantityReturn = 0
        product.return_reasons = [{ id: 0, exchange: false, option: [], quantityReturn: 1, comment: '', images: [], returnorder: !this.permissionExchangeProduct, refundorder: false }]
      }
      this.selectedProduct.edit = false
      this.selectedProduct = product
      this.selectedProduct.dialogProduct = true
      setTimeout(() => {
        if (!this.permissionExchangeProduct) {
          this.resizeDialogReturn()
        } else {
          this.resizeDialog()
        }
      });
    }
    localStorage.setItem('products', JSON.stringify(this.products))
  }

  resizeDialog() {
    if (document.getElementById("choose-option")) {
      var height = document.getElementById("choose-option")!.offsetHeight - 16 - 16 - 60 - 30
      document.getElementById("choose-option-img")!.style.height = height + "px"
      if (document.getElementById("choose-return-or-refund")) {
        document.getElementById("choose-return-or-refund")!.style.height = height + "px"
      }
    }
  }

  resizeDialogReturn() {
    var height;
    if (document.getElementById("choose-option")) {
      height = document.getElementById("choose-option")!.offsetHeight - 16 - 16 - 60 - 30
      this.heightDialog = height
    }

    if (document.getElementById("choose-option-img")) {
      document.getElementById("choose-option-img")!.style.height = height + "px"
    }

    if (document.getElementById("choose-reason-options")) {
      document.getElementById("choose-reason-options")!.style.height = height + "px"
    } else if (document.getElementById("choose-reason-more-details")) {
      document.getElementById("choose-reason-more-details")!.style.height = height + "px"
    }
  }

  removeProduct(event: any, product: any, index: any) {
    event.stopPropagation()
    product.totalQuantityReturn--
    //product.actual_reason--
    this.productsReturn.find((p: any) => p.sku == product.sku).return_reasons = this.productsReturn.find((p: any) => p.sku == product.sku).return_reasons.filter((r: any) => r.id !== index)
    var i = 0;
    this.productsReturn.find((p: any) => p.sku == product.sku).return_reasons.map(function (return_reason: any) { return_reason.id = i; i++ })

    if (this.productsReturn.find((p: any) => p.sku == product.sku).return_reasons.length == 0) {
      this.productsReturn = this.productsReturn.filter((p: any) => p.sku !== product.sku)
      this.selectedProduct.totalQuantityReturn = 0
      //this.selectedProduct.actual_reason = 0
    }
    localStorage.setItem('products', JSON.stringify(this.products))
  }

  decrementQuantity(product: any, index: any) {
    product.return_reasons[index].quantityReturn--
    product.totalQuantityReturn--
  }

  incrementQuantity(product: any, index: any) {
    product.return_reasons[index].quantityReturn++
    product.totalQuantityReturn++
  }

  editReason(product: any, index: any) {
    product.actual_reason = index;
    this.selectedProduct = product;
    this.selectedProduct.edit = true;
    this.selectedProduct.totalQuantityReturn = product.totalQuantityReturn
    this.selectedProduct.dialogProduct = true;
    setTimeout(() => {
      this.resizeDialogReturn()
    });
  }

  openMoreDetails(option: any) {
    this.moreDetails = true
    this.moreDetailsOption = option
    setTimeout(() => {
      this.resizeDialogReturn()
    });
  }

  previousBlock(block: string) {
    if (block == 'moreDetails') {
      this.moreDetails = false
      setTimeout(() => {
        this.resizeDialogReturn()
      });
    } else if (block == 'returnorder') {
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].returnorder = false;
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].refundorder = false
      setTimeout(() => {
        this.resizeDialog()
      });
    }
    this.submitted = false
  }

  openReturnOption() {
    this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].returnorder = true
    this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].refundorder = false
    setTimeout(() => {
      this.resizeDialogReturn()
    });
  }

  openRefundOption() {
    this.loadingSizesExchange = true
    var id_token = sessionStorage.getItem('id_token')
    this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].refundorder = true
    this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].returnorder = false
    this.returnService.getVariantQuantity(this.selectedProduct.product_id, this.selectedProduct.variant_id, id_token).then(quantity => {
      var totalQuantity = quantity.reduce(getTotal, 0);
      function getTotal(total: any, item: any) {
        return total + item.quantity;
      }
      this.allOutOfStock = totalQuantity > 0 ? false : true
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].variantSizes = quantity
      this.loadingSizesExchange = false
      setTimeout(() => {
        this.resizeDialogReturn()
      });
    })
  }

  chooseVariantProduct(variantSize: any) {
    this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].sizeExchange = variantSize.product_variant_option2.value
    this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].variant_id_exc = variantSize.variant_id
    this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].product_id_exc = variantSize.product_id
  }

  submitRefund() {
    this.openReturnOption()
    var option = this.returnOptions.find((el: any) => el.noQuantity == true)
    this.moreDetailsOption = option
    this.onSubmit()
  }

  previousPage() {
    this.router.navigate(['/menu']);
  }

  nextStep() {
    var nonExchangesProducts = this.products.filter((p: any) => p.return_reasons.some((r: any) => (!r.exchange && (r.option.length > 0 || (r.option && r.option.option)))));
    if (nonExchangesProducts.length == 0) {
      this.products.map((p: any) => p.paymentOptionSelect = null)
      this.router.navigate(['/return/return-review']);
    } else {
      this.router.navigate(['/return/return-resolution']);
    }
    localStorage.setItem('products', JSON.stringify(this.products))
  }

  closeDialog() {
    this.error_upload = false
    this.submitted = false

    this.imagesUpload.map((el: any) => {
      this.images = this.images.filter((e: any) => e.id !== el)
      this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].images = this.selectedProduct.return_reasons[this.selectedProduct.actual_reason].images.filter((e: any) => e.image != el)
      this.returnService.DeleteImage(el)
    })

    var ultimo = this.selectedProduct.return_reasons[this.selectedProduct.return_reasons.length - 1]
    if (!ultimo.exchange && ultimo.option.length == 0) {
      //this.selectedProduct.actual_reason--
      this.selectedProduct.return_reasons.pop();
    }
    this.selectedProduct.dialogProduct = false
    this.moreDetails = false
    localStorage.setItem('products', JSON.stringify(this.products))
  }

  openMoreReason(product: any) {
    this.selectedProduct = product
    this.selectedProduct.edit = false;
    this.selectedProduct.dialogProduct = true
    this.selectedProduct.actual_reason = this.selectedProduct.return_reasons.length
    this.selectedProduct.return_reasons.push({ id: product.actual_reason, exchange: false, option: [], quantityReturn: 1, comment: '', images: [], returnorder: !this.permissionExchangeProduct, refundorder: false })
    setTimeout(() => {
      if (!this.permissionExchangeProduct) {
        this.resizeDialogReturn()
      } else {
        this.resizeDialog()
      }
    });
  }
}

@Component({
  selector: 'label-dialog',
  templateUrl: 'label-dialog.html',
})
export class LabelDialog {

  onYesClick() {
    localStorage.setItem('hasLabel', '1')
  }

  onNoClick() {
    localStorage.setItem('hasLabel', '0')
  }
}