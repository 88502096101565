import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';

import { AppComponent } from './app.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ChangeLanguageComponent } from './pages/change-language/change-language.component';

import { TimelineComponent } from './pages/timeline/timeline.component';
import { ReturnLoginComponent } from './pages/return-login/return-login.component';
import { ReturnMenuComponent } from './pages/return-menu/return-menu.component';
import { RequestReturnComponent, LabelDialog } from './pages/return-request/return-request.component';
import { ResolutionReturnComponent } from './pages/return-resolution/return-resolution.component';
import { ReviewReturnComponent, ReturnAddressDialog } from './pages/return-review/return-review.component';
import { SuccessReturnComponent } from './pages/return-success/return-success.component';
import { ChoosePiece2HandComponent } from './pages/2hand-choosepiece/2hand-choosepiece.component';
import { ConditionReport2HandComponent } from './pages/2hand-conditionreport/2hand-conditionreport.component';
import { Confirm2HandComponent, LoginDialog, AddressDialog } from './pages/2hand-confirm/2hand-confirm.component';
import { Success2HandComponent } from './pages/2hand-success/2hand-success.component';
import { ChoosePieceRepairComponent } from './pages/repair-choosepiece/repair-choosepiece.component';
import { RepairAddressDialog, RepairPersonalInfoDialog, ReviewRepairComponent } from './pages/repair-review/repair-review.component';
import { SuccessRepairComponent } from './pages/repair-success/repair-success.component';
import {NgxPhotoEditorModule} from "ngx-photo-editor";

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SubmissionHistoryComponent } from './pages/submission-history/submission-history.component';
import { SearchProductComponent } from './pages/search-product/search-product.component';
import { SearchProductRepairComponent } from './pages/search-product-repair/search-product-repair.component';
import { RecoverHistoryOrdersComponent } from './pages/recover-history-orders/recover-history-orders.component';
import { ProductQRCodeComponent } from './pages/qrcode-product/qrcode-product.component';
import { RecycleAddressDialog, RecyclePersonalInfoDialog, ReviewRecycleComponent } from './pages/recycle-review/recycle-review.component';
import { ChoosePieceRecycleComponent } from './pages/recycle-choosepiece/recycle-choosepiece.component';
import { SuccessRecycleComponent } from './pages/recycle-success/recycle-success.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SearchProductComponent,
    SearchProductRepairComponent,
    TimelineComponent,
    ReturnLoginComponent,
    ReturnMenuComponent,
    RequestReturnComponent,
    ResolutionReturnComponent,
    ReviewReturnComponent,
    SuccessReturnComponent,
    ChoosePiece2HandComponent,
    ConditionReport2HandComponent,
    Confirm2HandComponent,
    Success2HandComponent,
    ChoosePieceRepairComponent,
    ReviewRepairComponent,
    SuccessRepairComponent,
    ChoosePieceRecycleComponent,
    ReviewRecycleComponent,
    SuccessRecycleComponent,
    LabelDialog,
    LoginDialog,
    AddressDialog,
    RepairPersonalInfoDialog,
    RepairAddressDialog,
    RecyclePersonalInfoDialog,
    RecycleAddressDialog,
    ReturnAddressDialog,
    SubmissionHistoryComponent,
    RecoverHistoryOrdersComponent,
    ProductQRCodeComponent,
    ChangeLanguageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatCardModule,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTabsModule,
    NgxPhotoEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [LoginDialog, AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
