import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { ReturnService } from './service/return.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    appDetails: any = JSON.parse(sessionStorage.getItem('appDetails')!);

    constructor(
        private router: Router,
        private returnService: ReturnService,
        private authService: ReturnService
    ) { };

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
        let isLoggedIn = sessionStorage.getItem('hasLogged')
        
        if (!this.appDetails) {
            await this.returnService.getLoginSession(environment.id_brand).then((appDetails) => {
                this.appDetails = appDetails.brand
                sessionStorage.setItem('appDetails', JSON.stringify(this.appDetails))
            })
        }
        if (isLoggedIn == "true" || isLoggedIn == "true-search" || isLoggedIn == "true-submit-search" || isLoggedIn == "true-submit-product" || window.location.pathname.startsWith("/submit") || window.location.pathname.startsWith("/product")) {
            if ((window.location.pathname == "/submit" && (this.appDetails['2hand'] == 0 || this.appDetails!.activate_submit_sell == 0)) ||
                (window.location.pathname == "/submit-repair" && (this.appDetails['repair'] == 0 || this.appDetails!.activate_submit_repair == 0))) {
                this.router.navigate(['']);
                return false;
            }
            if (isLoggedIn == "true-submit-search" || isLoggedIn == "true-search" || isLoggedIn == "true-submit-product") {
                return true
            }
            return true
        } else {
            this.router.navigate(['']);
            return false
        }
    }
}
