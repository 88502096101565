<div id="sell-success-app" class="h-full flex flex-column justify-between background-color">
    <div class="h-full flex justify-center align-center text-center">
        <div class="flex flex-column align-center return-success">
            <div class="flex flex-column gap-2">
                <div>
                    <mat-icon class="icon-success">check_circle</mat-icon>
                </div>
                <div> 
                    <h1>{{ 'success.success' | translate }}</h1>
                    <div class="extra-text">{{ 'success.listing_submitted' | translate }}</div>
                </div>
                <div class="flex gap-1">
                    <button class="button-btn-secundary" type="submit" (click)="backToHome()">{{ 'success.back_home' | translate }}</button>
                    <a href="https://istomkt.azurewebsites.net/all" target="_blank" class="button-btn flex justify-center align-center" style="text-decoration: none;" type="submit">{{ 'success.go_to_mkt' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>