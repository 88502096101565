import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ReturnService } from 'src/app/service/return.service';

@Component({
  selector: 'app-2hand-success',
  templateUrl: './2hand-success.component.html',
  styleUrls: ['./2hand-success.component.css']
})
export class Success2HandComponent {

  constructor(
    private router: Router,
    private returnService: ReturnService
  ) { }

  backToHome() {
    this.router.navigate(['']);
    localStorage.clear();
    this.returnService.ClearStorage();
  }
}