<div id="choosepiece-sell-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-1 md:w-90-percent main-block">
                <h1 class="lg:hidden">{{title}}</h1>
                <div *ngIf="secondHandProducts.length == 0" class="block-white">
                    <div>{{ 'choose_piece.no_products' | translate }}</div>
                </div>
                <div *ngFor="let product of secondHandProducts" class="w-full cursor-pointer block-white"
                    [class]="{'selected' : product.sku == selectedProduct?.sku}" (click)="selectProduct(product)">
                    <div class="flex align-center justify-between relative h-full gap-1 product-container">
                        <div *ngIf="product?.image_url" class="product-image"
                            [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat', height: '80px'}">
                        </div>
                        <div class="product-details-left">
                            <div class="bold-featured-text">{{ product.title }}</div>
                            <div>{{product.size}} / {{product.color}}</div>
                            <!-- <div>{{product.price}} {{currency}}</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="nonResellable2HProducts.length > 0" class="flex flex-column gap-1 md:w-90-percent main-block">
                <h2>{{ 'choose_piece.no_return_item' | translate }}</h2>
                <div class="flex flex-column gap-1 product disabled block-white">
                    <div *ngFor="let product of nonResellable2HProducts" class="w-full" style="opacity: 0.5;">
                        <div class="flex align-center justify-between relative h-full gap-1 product-container-non">
                            <div *ngIf="product?.image_url" class="product-image"
                                [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat', height: '80px'}">
                            </div>
                            <div class="product-details-left">
                                <div class="bold-featured-text">{{ product.title }}</div>
                                <div>{{product.size}} / {{product.color}}</div>
                                <!-- <div>{{product.price}} {{currency}}</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="product-line"></div>
                    <div class="flex flex-column gap--5">
                        <div class="extra-text">{{ 'choose_piece.no_return_item' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <button class="button-btn md:w-90-percent" type="submit" [disabled]="!selectedProduct" (click)="nextStep()">{{
            'choose_piece.next' | translate }}</button>
    </div>
</div>
<footer></footer>