// shared.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class changelanguageShared {
  private ngOnInitFuncs: Function = Function;
  private divsFunc: Function = Function;

  registerNgOnIni(callbacks: { func: Function, div_: Function }) {
    this.divsFunc=callbacks.div_
    this.ngOnInitFuncs=callbacks.func;
  }

  callNgOnInit() {
    this.divsFunc()
    this.ngOnInitFuncs()
  }
}