<div id="product-repair-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline w-full"></div>
        <div class=" pt-1-25 flex flex-column gap-2 align-center mb-3">
            <!-- <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline> -->
            <div class="flex flex-column gap-1 md:w-90-percent products-return">
                <h1>{{ 'qrcode.title' | translate }}</h1>
                <div *ngIf="errorProduct" class="block-white">
                    <div>{{ 'repair.no_product' | translate }}</div>
                </div>
                <div *ngIf="!errorProduct && product" class="product block-white selected">
                    <div class="flex align-center justify-between relative h-full gap-1 product-container">
                        <div *ngIf="product?.image_url" class="product-image"
                            [style]="{background: 'url(' + product?.image_url + ') center center / cover no-repeat'}">
                        </div>
                        <div class="product-details-left">
                            <div class="bold-featured-text">{{ product?.title }}</div>
                            <div>{{product?.size}} / {{product?.color}}</div>
                        </div>
                    </div>
                    <div class="flex gap--5">
                        <span class="material-symbols-outlined" style="font-size: 20px;">storefront</span>
                        <a class="w-auto flex align-center justify-center"
                            href="{{'https://marketplace.swyco.io/product/' + product.product_id + '?variant=' + product.product_variant_id}}"
                            target="_blank">
                            View similar products
                        </a>
                    </div>
                </div>
                <!-- <div *ngIf="product" class="w-full flex align-center justify-center"
                    style="margin-top: -35px; z-index: 9999;">
                    <a class="w-auto flex align-center justify-center"
                        href="{{'https://marketplace.swyco.io/product/' + product.product_id + '?variant=' + product.product_variant_id}}"
                        target="_blank">
                        <button class="button-btn-small w-auto flex align-center justify-center gap--5">
                            <span class="material-symbols-outlined" style="font-size: 22px;">storefront</span>
                            Discover more
                        </button>
                    </a>
                </div> -->
                <!-- <div class="flex align-center justify-center gap--5">
                    <span class="material-symbols-outlined" style="font-size: 22px;">storefront</span>
                    <a class="w-auto flex align-center justify-center" style="font-size: 12px;"
                        href="{{'https://marketplace.swyco.io/product/' + product.product_id + '?variant=' + product.product_variant_id}}"
                        target="_blank">
                        View similar products
                    </a>
                </div> -->
                <div *ngIf="!errorProduct" class="block-white" style="font-size: 12px;">
                    <h4><b>What's my impact?</b></h4>
                    <div class="flex flex-column gap-1 font-small">
                        <div class="toogle-small-button">
                            <mat-button-toggle-group #toggleGroup="matButtonToggleGroup" name="impact"
                                aria-label="Impact" value="facts">
                                <mat-button-toggle value="facts">FACTS</mat-button-toggle>
                                <mat-button-toggle value="comparison">COMPARISON</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <!-- <div>The positive impact of choosing one piece of this garment</div> -->
                        <div *ngIf="toggleGroup.value == 'facts'" class="flex gap-2 align-center">
                            <div class="flex flex-column gap--5 align-center justify-center w-full">
                                <span class="material-symbols-outlined">emoji_objects</span>
                                <div><b id="energy_value">{{product?.energy}}</b></div>
                                <div class="text-center" style="max-width: 132px;">MJ of energy saved</div>
                            </div>
                            <div class="flex flex-column gap--5 align-center justify-center w-full">
                                <span class="material-symbols-outlined">water_drop</span>
                                <div><b id="water_value">{{product?.water}}</b></div>
                                <div class="text-center" style="max-width: 132px;">L of water saved</div>
                            </div>
                            <div class="flex flex-column gap--5 align-center justify-center w-full">
                                <span class="material-symbols-outlined">foggy</span>
                                <div><b id="co2_value">{{product?.co2}}</b></div>
                                <div class="text-center" style="max-width: 132px;">kg of emissions prevented</div>
                            </div>
                        </div>
                        <div *ngIf="toggleGroup.value == 'comparison'" class="flex gap-2 align-center">
                            <div class="flex flex-column gap--5 align-center justify-center w-full">
                                <span class="material-symbols-outlined">battery_charging_90</span>
                                <div><b id="energy_value">{{((278/38)*product?.energy).toFixed(2)}}</b></div>
                                <div class="text-center" style="max-width: 132px;">powerbanks can be fully charged</div>
                            </div>
                            <div class="flex flex-column gap--5 align-center justify-center w-full">
                                <span class="material-symbols-outlined">water_full</span>
                                <div><b id="water_value">{{product?.glass}}</b></div>
                                <div class="text-center" style="max-width: 132px;">glasses of water can be filled</div>
                            </div>
                            <div class="flex flex-column gap--5 align-center justify-center w-full">
                                <span class="material-symbols-outlined">no_crash</span>
                                <div><b id="co2_value">{{((product?.co2*1000)/114).toFixed(2)}}</b></div>
                                <div class="text-center" style="max-width: 132px;">kms can be traveled by car</div>
                            </div>
                        </div>
                        <div>estimated savings with the extension of this product’s life cycle.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <div class="flex w-full md:w-90-percent gap-1 align-center justify-center" style="width: 610px;">
            <div class="w-full toogle-normal-button">
                <mat-button-toggle-group #toggleGroupx="matButtonToggleGroup" name="xxx" aria-label="xx" class="w-full"
                    [disabled]="errorProduct">
                    <mat-button-toggle value="sell" class="w-full"
                        [disabled]="appDetails['2hand'] == 0 || product['2hand'] == 0"
                        (click)="goTo2Hand()">{{'menu.sell-' + theme |
                        translate }}</mat-button-toggle>
                    <mat-button-toggle value="repair" class="w-full"
                        [disabled]="appDetails.repair == 0 || product.repair == 0"
                        (click)="goToRepair()">{{'menu.repair-' + theme |
                        translate }}</mat-button-toggle>
                    <mat-button-toggle value="recycle" class="w-full" [disabled]="appDetails.recycle == 0"
                        (click)="goToRecycle()">{{'menu.recycle-' + theme
                        |
                        translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
</div>
<footer></footer>