<div id="return-app" role="main" class="overflow-auto"
    [style]="{background: 'url(data:image/png;base64,' + appDetails.background + ') center center / cover no-repeat fixed'}">
    <div *ngIf="loadPage" class="w-full h-full">
        <div class="w-full h-full flex justify-center align-center">
            <div class="relative justify-start load-page">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    <change-language></change-language>
    <div *ngIf="!loadPage" class="home-page flex flex-row md:flex-column w-scren h-auto align-center justify-end">
        <div id="login-block"
            class="md:w-full w-50-percent md:min-h-screen h-full flex flex-column md:relative fixed gap-1 justify-center align-center inset-0">
            <div class="return-login relative flex flex-column align-center overflow-y-auto"
                [class]="{'return-by-search' : appDetails.activate_submit_repair === 1 || appDetails.activate_submit_sell === 1}">
                <div id="logo" class="mb-2">
                    <h1 *ngIf="!appDetails.logo" class="font-25">{{ 'login.no_logo_text' | translate }}</h1>
                    <img *ngIf="appDetails.logo" [src]="'data:image/png;base64,' + appDetails.logo" width="0" height="0"
                        class="visibility">
                </div>
                <div class="error-message justify-center" *ngIf="submitted && errorLogin">
                    <div class="flex">
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    {{messageError}}
                </div>
                <div *ngIf="!searchByProduct; else formSearchByProduct " class="flex flex-column gap-1 w-full">
                    <div class="flex flex-column gap-1">
                        <div class="form-input">
                            <input id="orderNumber" type="text" [(ngModel)]="orderNumber"
                                placeholder="{{ 'login.order_number' | translate }}" (keypress)="onSubmitEnter($event)"
                                style="height: 3rem !important;">
                        </div>
                        <div class="form-input formfield-lowercase">
                            <input id="email" type="email" [(ngModel)]="email"
                                placeholder="{{ 'login.email_address' | translate }}" (keypress)="onSubmitEnter($event)"
                                style="height: 3rem !important;">
                        </div>
                        <button class="button-btn" type="submit" (click)="onSubmit()">
                            <div class="flex gap-1 h-full align-center justify-center relative">
                                <div class="relative justify-start">
                                    <mat-spinner *ngIf="submitted && loadButton"></mat-spinner>{{ 'login.submit_btn' |
                                    translate }}
                                </div>
                            </div>
                        </button>
                        <a class="recoverHistoryLink" (click)="recoverHistoryOrders()">{{'login.history_Email_link' |
                            translate}}</a>
                    </div>
                    <div *ngIf="appDetails.activate_submit_repair === 1 || appDetails.activate_submit_sell === 1"
                        class="w-full flex flex-column gap-1">
                        <div class="flex flex-row align-center gap-1">
                            <div class="divider"></div>
                            <div class="extra-text">{{ 'search_product.or' | translate }}</div>
                            <div class="divider"></div>
                        </div>
                        <button class="button-btn-secundary" type="submit" (click)="searchByProductForm()">
                            <div class="flex gap-1 h-full align-center justify-center relative">
                                <div class="relative justify-start">
                                    <mat-spinner *ngIf="submitted && loadButton"></mat-spinner>{{
                                    'search_product.search_by_product' | translate }}
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                <ng-template #formSearchByProduct>
                    <div class="w-full flex flex-column overflow-y-auto">
                        <div class="previousButtonForm absolute flex justify-between">
                            <div class="w-full cursor-pointer" (click)="searchByProductForm()">
                                <svg width="32" height="32" viewBox="0 0 36 36" stroke="" role="img"
                                    class="r-b0vftf r-mabqd8 r-1yvhtrz">
                                    <g>
                                        <path
                                            d="M0.5 18C0.5 8.05888 8.55888 0 18.5 0C28.4411 0 36.5 8.05888 36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55888 36 0.5 27.9411 0.5 18Z"
                                            fill="#E9EAEB" stroke=""></path>
                                        <path
                                            d="M21.5 11L14.6768 17.8232C14.5791 17.9209 14.5791 18.0791 14.6768 18.1768L21.5 25"
                                            stroke="#273C48" stroke-opacity="0.98" stroke-width="2"
                                            stroke-linecap="round" fill="#E9EAEB">
                                        </path>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="flex flex-column gap-1 w-full overflow-y-auto" style="padding: 0.1rem;">
                            <div class="relative flex w-full form-input">
                                <input id="searchProduct" type="text"
                                    placeholder="{{ 'search_product.search_by' | translate }}"
                                    [(ngModel)]="searchProduct" (keypress)="searchProducts($event)" name="searchProduct"
                                    required maxlength="50">
                                <button class="ml--5 px-1 flex align-center justify-center button-btn"
                                    (click)="searchProducts()"><mat-icon>search_outline</mat-icon></button>
                            </div>
                            <div class="flex flex-column gap--5" style="margin-top: -.5rem;">
                                <div class="extra-text">{{ 'search_product.products_available' | translate }}</div>
                                <div class="error-message-warning extra-text-invalid"
                                    *ngIf="submittedSearch && searchProduct.length <= 2"
                                    style="justify-content: start;">
                                    <div class="flex">
                                        <mat-icon>error_outline</mat-icon>
                                    </div>
                                    {{ 'search_product.3_minimum_digits' | translate }}
                                </div>
                            </div>
                            <div *ngIf="submittedSearch  && searchProduct.length > 2 && !successSubmittedSearch"
                                style="margin-top: 1rem">
                                <div class="loadProduct flex justify-center">
                                    <mat-spinner></mat-spinner>
                                </div>
                            </div>
                            <div *ngIf="successSubmittedSearch && products.length == 0" class="extra-text"
                                style="margin-top: .5rem">
                                {{ 'search_product.no_products' | translate }}
                            </div>
                            <div class="overflow-y-auto simple_scrolbar">
                                <div *ngFor="let product of products"
                                    class="mt-1 w-full cursor-pointer block-white outline-gray-soft-16"
                                    [class]="{'selected' : product.product_id == selectedProduct?.product_id}"
                                    (click)="selectProduct(product)">
                                    <div class="flex align-center relative h-full gap-1 product-container">
                                        <div id="product-image-{{product.sku}}" class="product-image"
                                            [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat', minHeight: '90px', minWidth: '90px'}">
                                        </div>
                                        <div id="product-container-{{product.sku}}" class="product-details-left">
                                            <div class="bold-featured-text">{{ product?.title }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="md:flex hidden flex-column gap--5 align-center cursor-pointer" (click)="howItWorks()">
                <div class="flex justify-center align-center p-1 color-white">{{ 'login.how_it_works' | translate }}
                </div>
                <!--<mat-icon class="arrow-explain bounce color-white">arrow_downward</mat-icon>-->
                <div id="arrow" class="arrow-back previousButton relative flex w-min l-0 justify-between">
                    <div class=" bounce">
                        <svg width="25" height="25" viewBox="0 0 36 36" stroke="" role="img"
                            class="r-b0vftf r-mabqd8 r-1yvhtrz" style="transform: rotate(-90deg)">
                            <g>
                                <path
                                    d="M0.5 18C0.5 8.05888 8.55888 0 18.5 0C28.4411 0 36.5 8.05888 36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55888 36 0.5 27.9411 0.5 18Z"
                                    fill="#FFFF" stroke=""></path>
                                <path
                                    d="M21.5 11L14.6768 17.8232C14.5791 17.9209 14.5791 18.0791 14.6768 18.1768L21.5 25"
                                    stroke="#273C48" stroke-opacity="0.98" stroke-width="2" stroke-linecap="round"
                                    fill="#E9EAEB"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div id="explain-block" class="md:w-full w-50-percent flex justify-end align-end">
            <div class="explains-block w-full flex flex-column justify-center align-center">
                <div class="return-explain flex flex-column gap-1 w-full h-auto flex justify-center align-center">
                    <div *ngIf="permissionReturn" class="block-explain">
                        <div class="title-explain">{{ 'login.returns_exchanges' | translate }}</div>
                        <div class="grid-explain">
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/orderemail.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="block-left-explain flex justify-center align-start">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/shiptruck.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="block-left-explain flex justify-center align-start">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/giftcard.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="text-explain">{{ 'login.order_email' + this.idBrand | translate }}</div>
                            <div></div>
                            <div class="text-explain">{{ 'login.ship_return' | translate }}</div>
                            <div></div>
                            <div class="text-explain">{{ 'login.receive_return' | translate }}</div>
                        </div>
                    </div>
                    <div *ngIf="permissionRepair" class="block-explain">
                        <div class="title-explain">{{ 'login.repairs' | translate }}</div>
                        <div class="grid-explain">
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/orderemail.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="block-left-explain flex justify-center align-start">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/shiptruck.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="block-left-explain flex justify-center align-start">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/repair.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="text-explain">{{ 'login.order_email' + this.idBrand | translate }}</div>
                            <div></div>
                            <div class="text-explain">{{ 'login.ship_repair' | translate }}</div>
                            <div></div>
                            <div class="text-explain">{{ 'login.receive_repair' | translate }}</div>
                        </div>
                    </div>
                    <div *ngIf="permission2Hand" class="block-explain">
                        <div class="title-explain">{{ 'login.resell' | translate }}</div>
                        <div class="grid-explain">
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/orderemail.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="block-left-explain flex justify-center align-start">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/shiptruck.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="block-left-explain flex justify-center align-start">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img [src]="'../../../assets/login/creditcard_' + idBrand + '.svg'"
                                        class="w-full h-full">
                                </div>
                            </div>
                            <div class="text-explain">{{ 'login.order_email' + this.idBrand | translate }}</div>
                            <div></div>
                            <div class="text-explain">{{ 'login.ship_resell' | translate }}</div>
                            <div></div>
                            <div class="text-explain">{{ 'login.receive_resell' | translate }}</div>
                        </div>
                    </div>
                    <div *ngIf="permissionRecycle" class="block-explain">
                        <div class="title-explain">{{ 'login.recycle' | translate }}</div>
                        <div class="grid-explain">
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/orderemail.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="block-left-explain flex justify-center align-start">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img src="../../../assets/login/shiptruck.svg" class="w-full h-full">
                                </div>
                            </div>
                            <div class="block-left-explain flex justify-center align-start">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                            <div class="icon-explain-block">
                                <div class="icon-explain flex justify-center">
                                    <img [src]="'../../../assets/login/recycle.png'" style="width: 70px; height: 70px;">
                                </div>
                            </div>
                            <div class="text-explain">{{ 'login.choose_item' | translate }}</div>
                            <div></div>
                            <div class="text-explain">{{ 'login.prepaid_label' | translate }}</div>
                            <div></div>
                            <div class="text-explain">{{ 'login.positive_impact' | translate }}</div>
                        </div>
                    </div>
                </div>
                <div class="md:flex hidden arrow-up-block cursor-pointer"
                    [class]="{'arrow-visible' : showButton, 'arrow-hidden' : !showButton}" (click)="goToTop()">
                    <div class="arrow-up">
                        <mat-icon class="color-white">arrow_upward</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="openDialog" class="w-full h-full flex align-center justify-center absolute request-return-option">
            <div id="choose-option" class="choose-option" role="dialog" aria-modal="true"
                [ngClass]="{'choose-option-full': !selectedProduct.image_url}">
                <div class="w-full h-full flex gap--5">
                    <div *ngIf="selectedProduct?.image_url" id="choose-option-img" class="choose-option-img md:hidden">
                        <div class="product-img"
                            [style]="{background: 'url(' + selectedProduct.image_url + ') center center / cover no-repeat'}">
                        </div>
                    </div>
                    <div class="w-full choose-option-block options flex flex-column relative"
                        [ngClass]="{'choose-option-block-full': !selectedProduct.image_url}">
                        <div class="choose-option-header justify-end">
                            <div class="cursor-pointer" (click)="closeDialog()">
                                <mat-icon>close</mat-icon>
                            </div>
                        </div>
                        <div id="choose-return-or-refund"
                            class="overflow-y-scroll h-full choose-return-or-refund justify-between">
                            <div class="h-full flex flex-column gap-1">
                                <h2>{{ 'search_product.confirm_size' | translate }}</h2>
                                <div class="w-full flex flex-column gap-1 h-full justify-between">
                                    <div class="w-full flex flex-column gap-1">
                                        <div
                                            class="hidden md:flex align-center justify-start relative h-full gap-1 product-container">
                                            <div id="product-size-image-{{selectedProduct?.sku}}"
                                                class="product-image md:hidden"
                                                [style]="{background: 'url(' + selectedProduct.image_url + ') center center / cover no-repeat', minHeight: '70px'}">
                                            </div>
                                            <div id="product-size-container-{{selectedProduct?.sku}}"
                                                class="product-details-left">
                                                <div class="bold-featured-text">{{ selectedProduct?.title }}</div>
                                                <div *ngIf="selectedColor"><span
                                                        *ngIf="selectedSize">{{selectedSize?.option2_value}}
                                                        /
                                                    </span>{{getColorName(selectedColor)}}</div>
                                                <!-- <div *ngIf="selectedSize">{{selectedSize?.price}} {{currency}}</div> -->
                                            </div>
                                        </div>
                                        <div class="product-details flex flex-column gap--5">
                                            <div>{{ 'search_product.selected_' + selectedProduct.variants[0].option1 |
                                                translate }}:</div>
                                            <div class="product-colors flex gap--5 flex-wrap">
                                                <div *ngFor="let color of colors"
                                                    class="color-item mt--5 cursor-pointer block-white outline-gray-soft-16"
                                                    [class]="{'selected' : color === selectedColor,
                                                    'invalid-field' : submittedSize && !selectedColor}"
                                                    (click)="selectColor(color)">
                                                    <div
                                                        class="flex align-center justify-between relative h-full gap-1 product-container">
                                                        <div class="w-auto border-black">{{getColorName(color)}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="extra-text-invalid" *ngIf="submittedSize && !selectedColor">
                                                {{ 'request.exchange_modal.required_field' | translate }}</div>
                                        </div>
                                        <div *ngIf="!no_option2" class="flex flex-column gap--5">
                                            <div>{{ 'search_product.selected_' + selectedProduct.variants[0].option2 |
                                                translate }}:</div>
                                            <div class="product-sizes flex gap--5 flex-wrap">
                                                <div *ngFor="let size of sizes"
                                                    class="size-item mt--5 cursor-pointer block-white outline-gray-soft-16"
                                                    [class]="{'selected' : size.sku == selectedSize?.sku,
                                                    'invalid-field' : submittedSize && !selectedSize}"
                                                    (click)="selectSize(size)">
                                                    <div
                                                        class="flex align-center justify-between relative h-full gap-1 product-container">
                                                        <div class="w-auto border-black">{{size.option2_value}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="extra-text-invalid" *ngIf="submittedSize && !selectedSize">
                                                {{ 'request.exchange_modal.required_field' | translate }}</div>
                                        </div>
                                    </div>
                                    <button class="button-btn" style="margin-bottom: 30px;" type="submit"
                                        (click)="confirmSize()">{{
                                        'request.return_reason_modal.confirm_btn' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!loadPage && cookieAccept !== 'true'" class="animate cookie-info" style="z-index: 50;">
        <mat-icon class="cookie-info-icon" (click)="acceptCookies()">close</mat-icon>
        <div>
            {{ 'login.coockie_message_1' | translate }} <a href="{{coockie_policy}}" target="_blank"
                style="color: var(--color-primary);"><b>{{ 'login.coockie_statement' | translate }}</b></a> {{
            'login.coockie_message_2' | translate }}
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<footer></footer>