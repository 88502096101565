<div mat-dialog-title class="relative">
    <h1>{{ haveAccount ? translateService.instant('login_modal.sign_in') :
        translateService.instant('login_modal.sign_up') }}</h1>
    <button mat-button mat-dialog-close class="button-input absolute t-0 r-0">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content class="popUp-block">
    <div class="flex flex-column gap-1">
        <div>
            <div id="buttonDivSignin" class="w-full flex justify-center"></div>
            <div *ngIf="error_login_google" class="extra-text-invalid height-24 flex align-center">{{
                'login_modal.error_login' | translate }}</div>
        </div>
        <div class="flex align-center gap-1">
            <div class="line w-full"></div>
            <small>{{ 'login_modal.or' | translate }}</small>
            <div class="line w-full"></div>
        </div>
        <form #form="ngForm" *ngIf="!haveAccount" class="flex flex-column gap--5">
            <div class="flex sm:flex-column flex-row gap--5">
                <div class="relative flex flex-column form-input w-full">
                    <label>{{ 'login_modal.first_name' | translate }}</label>
                    <input id="first_name" type="text" [(ngModel)]="first_name" name="first_name" required
                        maxlength="50" (keypress)="onSubmitEnter($event)"
                        [ngClass]="{'ng-invalid-input' : !first_name && submitted}">
                    <div *ngIf="!first_name && submitted" class="extra-text-invalid height-24 flex align-center">{{
                        'login_modal.required_field' | translate }}</div>
                </div>
                <div class="relative flex flex-column form-input w-full">
                    <label>{{ 'login_modal.last_name' | translate }}</label>
                    <input id="last_name" type="text" [(ngModel)]="last_name" name="last_name" required maxlength="50"
                        (keypress)="onSubmitEnter($event)" [ngClass]="{'ng-invalid-input' : !last_name && submitted}">
                    <div *ngIf="!last_name && submitted" class="extra-text-invalid height-24 flex align-center">{{
                        'login_modal.required_field' | translate }}</div>
                </div>
            </div>
            <div class="relative flex flex-column form-input">
                <label>{{ 'login_modal.email' | translate }}</label>
                <input id="email" type="email" [(ngModel)]="email" name="email" required maxlength="50"
                    (keypress)="onSubmitEnter($event)"
                    [ngClass]="{'ng-invalid-input' : submitted && (!email || (email && !validEmail()) || (email && validEmail() && error_signin))}">
                <div *ngIf="!email && submitted" class="extra-text-invalid height-24 flex align-center">{{
                    'login_modal.required_field' | translate }}</div>
                <div *ngIf="email && !validEmail() && submitted" class="extra-text-invalid height-24 flex align-center">
                    {{ 'login_modal.valid_email' | translate }}</div>
                <div *ngIf="email && validEmail() && error_signin && submitted"
                    class="extra-text-invalid height-24 flex align-center">{{ 'login_modal.account_already_exists' |
                    translate }}</div>
            </div>
            <div class="w-full flex sm:flex-column flex-row gap--5">
                <div class="relative flex flex-column w-full">
                    <div class="form-input relative flex justify-end align-center">
                        <label style="z-index:9999">{{ 'login_modal.password' | translate }}</label>
                        <input id="password" [type]="showPassword ? 'text' : 'password'" [(ngModel)]="password"
                            (keypress)="onSubmitEnter($event)" name="password" required maxlength="50"
                            [ngClass]="{'ng-invalid-input' : submitted && (!password || (password && !validPassword()))}">
                        <div *ngIf="!showPassword" class="flex absolute m--5">
                            <mat-icon (click)="showPassword = true"
                                class="flex justify-center align-center cursor-pointer medium-icon">visibility</mat-icon>
                        </div>
                        <div *ngIf="showPassword" class="flex absolute m--5">
                            <mat-icon (click)="showPassword = false"
                                class="flex justify-center align-center cursor-pointer medium-icon">visibility_off</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="!password && submitted" class="extra-text-invalid height-24 flex align-center">{{
                        'login_modal.required_field' | translate }}</div>
                    <div *ngIf="password && !validPassword() && submitted" class="extra-text-invalid flex align-center">
                        {{ 'login_modal.invalid_password' | translate }}
                        <mat-icon aria-hidden="false" fontIcon="info" style="display: flex !important;"
                            class="flex justify-center align-center cursor-pointer info-icon"
                            [matMenuTriggerFor]="menu">
                        </mat-icon>
                    </div>
                </div>
                <div class="relative flex flex-column w-full">
                    <div class="form-input relative flex justify-end align-center">
                        <label style="z-index:9999">{{ 'login_modal.confirm_password' | translate }}</label>
                        <input id="confirm_password" [type]="showConfirmPassword ? 'text' : 'password'"
                            [(ngModel)]="confirm_password" (keypress)="onSubmitEnter($event)" name="password" required
                            maxlength="50"
                            [ngClass]="{'ng-invalid-input' : submitted && (!confirm_password || (password && confirm_password && confirm_password != password))}">
                        <div *ngIf="!showConfirmPassword" class="flex absolute m--5">
                            <mat-icon (click)="showConfirmPassword = true"
                                class="flex justify-center align-center cursor-pointer medium-icon">visibility</mat-icon>
                        </div>
                        <div *ngIf="showConfirmPassword" class="flex absolute m--5">
                            <mat-icon (click)="showConfirmPassword = false"
                                class="flex justify-center align-center cursor-pointer medium-icon">visibility_off</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="!confirm_password && submitted" class="extra-text-invalid height-24 flex align-center">
                        {{ 'login_modal.required_field' | translate }}</div>
                    <div *ngIf="password && confirm_password && confirm_password != password && submitted"
                        class="extra-text-invalid height-24 flex align-center">{{ 'login_modal.password_doest_match' |
                        translate }}</div>
                </div>
            </div>
            <section>
                <mat-checkbox class="text-small" [(ngModel)]="acceptTerms" name="acceptTerms">{{
                    'login_modal.agree_terms_conditions' | translate }}</mat-checkbox>
                <div *ngIf="!acceptTerms && submitted" class="extra-text-invalid height-24 flex align-center">
                    {{ 'login_modal.required_field' | translate }}</div>
            </section>
            <small class="cursor-pointer text-underline" (click)="haveAccount = true">{{ 'login_modal.have_account' |
                translate }}</small>
        </form>
        <form #form="ngForm" *ngIf="haveAccount" class="flex flex-column gap--5">
            <div class="relative flex flex-column form-input">
                <label>{{ 'login_modal.email' | translate }}</label>
                <input id="email" type="email" [(ngModel)]="email" name="email" required required maxlength="50"
                    (keypress)="onSubmitEnter($event)"
                    [ngClass]="{'ng-invalid-input' : (!email) && submitted || (email && !validEmail() && submitted) || (email && validEmail() && error_login && submitted)}">
                <div *ngIf="!email && submitted" class="extra-text-invalid height-24 flex align-center">{{
                    'login_modal.required_field' | translate }}</div>
                <div *ngIf="email && !validEmail() && submitted" class="extra-text-invalid height-24 flex align-center">
                    {{ 'login_modal.valid_email' | translate }}</div>
            </div>
            <div class="relative flex flex-column">
                <div class="form-input relative flex justify-end align-center">
                    <label style="z-index:9999">{{ 'login_modal.password' | translate }}</label>
                    <input id="password" [type]="showPassword ? 'text' : 'password'" [(ngModel)]="password"
                        (keypress)="onSubmitEnter($event)" name="password" required maxlength="50"
                        [ngClass]="{'ng-invalid-input' : !password && submitted || (password && form.valid && error_login && submitted)}">
                    <div *ngIf="!showPassword" class="flex absolute m--5">
                        <mat-icon (click)="showPassword = true"
                            class="flex justify-center align-center cursor-pointer medium-icon">visibility</mat-icon>
                    </div>
                    <div *ngIf="showPassword" class="flex absolute m--5">
                        <mat-icon (click)="showPassword = false"
                            class="flex justify-center align-center cursor-pointer medium-icon">visibility_off</mat-icon>
                    </div>
                </div>
                <div *ngIf="!password && submitted" class="extra-text-invalid height-24 flex align-center">{{
                    'login_modal.required_field' | translate }}</div>
            </div>
            <div *ngIf="email && validEmail() && error_login && submitted" class="extra-text-invalid">{{
                'login_modal.invalid_credentials' | translate }}</div>
            <div class="extra-text flex align-center gap--5">
                <mat-icon>priority_high</mat-icon>
                <div [innerHTML]="accounts_not_same"></div>
            </div>
            <small class="cursor-pointer text-underline" (click)="haveAccount = false">{{ 'login_modal.sign_up_now' |
                translate }}</small>
        </form>
    </div>
</div>
<div mat-dialog-actions>
    <div class="flex w-full gap-1 justify-center">
        <button mat-button class="button-btn w-full" (click)="logIn()">{{ haveAccount ?
            translateService.instant('login_modal.sign_in') : translateService.instant('login_modal.sign_up')
            }}</button>
    </div>
</div>
<mat-menu #menu="matMenu">
    <div class="text-small flex flex-column">
        <div>{{ 'login_modal.password_rule' | translate }}</div>
        <div class="flex flex-column">
            <div class="flex gap-1">
                <div class="w-50-percent">{{ 'login_modal.lower_letter' | translate }}</div>
                <div class="w-50-percent">{{ 'login_modal.number' | translate }}</div>
            </div>
            <div class="flex gap-1">
                <div class="w-50-percent">{{ 'login_modal.upper_letter' | translate }}</div>
                <div class="w-50-percent">{{ 'login_modal.special_char' | translate }}</div>
            </div>
        </div>
    </div>
</mat-menu>