<h1 mat-dialog-title>{{'address.add_new_address' | translate }}</h1>
<div mat-dialog-content class="popUp-block" style="padding: 20px 24px;">
    <div class="flex flex-column gap-1">
        <form #form="ngForm" class="flex flex-column gap--5">
            <div class="relative flex flex-column w-full form-input">
                <label>{{'address.full_name' | translate }}</label>
                <input id="full_name" type="text" [(ngModel)]="full_name" name="full_name" required maxlength="50"
                    [ngClass]="{'ng-invalid-input' : !full_name && submitted}">
                <div *ngIf="!full_name && submitted" class="extra-text-invalid">{{'address.required_field' |
                    translate }}</div>
            </div>
            <div class="flex sm:flex-column gap--5">
                <div class="relative flex flex-column form-input sm:w-full w-50-percent">
                    <label>{{'address.call_sign' | translate }}</label>
                    <select class="select-block block-form w-50-percent font-inherit" name="selectedCountryCallingCode"
                        [(ngModel)]="selectedCountryCallingCode"
                        [ngClass]="{'ng-invalid-input' : !selectedCountryCallingCode && submitted}">
                        <option [ngValue]="country.cca2" *ngFor="let country of countryCallingCode">
                            {{country.name.common}}
                            {{country.idd.root}}{{country.idd.suffixes[0]}}
                        </option>
                    </select>
                    <div *ngIf="!selectedCountryCallingCode && submitted" class="extra-text-invalid">
                        {{'address.required_field' |
                        translate }}</div>
                </div>
                <div class="relative flex flex-column sm:w-full w-50-percent form-input">
                    <label>{{'address.phone' | translate }}</label>
                    <input id="phone" type="number" [(ngModel)]="phone" name="phone" required maxlength="50"
                        [ngClass]="{'ng-invalid-input' : !phone && submitted}">
                    <div *ngIf="!phone && submitted" class="extra-text-invalid">{{'address.required_field' | translate
                        }}</div>
                </div>
            </div>
            <div class="relative flex flex-column form-input">
                <label>{{'address.address' | translate }}</label>
                <input id="address" type="text" [(ngModel)]="address" name="address" required maxlength="50"
                    [ngClass]="{'ng-invalid-input' : !address && submitted}">
                <div *ngIf="!address && submitted" class="extra-text-invalid">{{'address.required_field' | translate }}
                </div>
                <!-- <div *ngIf="address && address.length > 100 && submitted" class="extra-text-invalid">
                    {{translateService.instant('address.cannot_be_more_than' , {char_num: 100})}}</div> -->
            </div>
            <div class="flex sm:flex-column gap--5">
                <div class="relative flex flex-column form-input sm:w-full w-50-percent">
                    <label>{{'address.address2' | translate }} <small>{{'address.optional' | translate
                            }}</small></label>
                    <input id="address2" type="text" [(ngModel)]="address2" name="address2" maxlength="50">
                    <!-- <div *ngIf="address2 && address2.length > 100 && submitted" class="extra-text-invalid">
                        {{translateService.instant('address.cannot_be_more_than' , {char_num: 100})}}</div> -->
                </div>
                <div class="relative flex flex-column form-input sm:w-full w-50-percent">
                    <label>{{'address.country' | translate }}</label>
                    <select class="select-block block-form w-50-percent font-inherit" name="selectedCountry"
                        [(ngModel)]="selectedCountry" [ngClass]="{'ng-invalid-input' : !selectedCountry && submitted}">
                        <option [ngValue]="country.country_id" *ngFor="let country of countries">
                            {{country.country_name}}</option>
                    </select>
                    <div *ngIf="!selectedCountry && submitted" class="extra-text-invalid">{{'address.required_field' |
                        translate }}</div>
                </div>
            </div>
            <div class="flex sm:flex-column gap--5">
                <div class="relative flex flex-column sm:w-full w-50-percent form-input">
                    <label>{{'address.city' | translate }}</label>
                    <input id="city" type="text" [(ngModel)]="city" name="city" required maxlength="50"
                        [ngClass]="{'ng-invalid-input' : !city && submitted}">
                    <div *ngIf="!city && submitted" class="extra-text-invalid">{{'address.required_field' | translate }}
                    </div>
                </div>
                <div class="relative flex flex-column sm:w-full w-50-percent form-input">
                    <label>{{'address.zip_code' | translate }}</label>
                    <input id="zip" type="text" [(ngModel)]="zip" name="zip" required required maxlength="50"
                        [ngClass]="{'ng-invalid-input' : !zip && submitted}">
                    <div *ngIf="!zip && submitted" class="extra-text-invalid">{{'address.required_field' | translate }}
                    </div>
                </div>
            </div>
        </form>
        <div *ngIf="login_data.Address?.length > 0" class="flex flex-column gap-1">
            <div class="flex align-center gap-1">
                <div class="line w-full"></div>
                <small>{{'address.or' | translate }}</small>
                <div class="line w-full"></div>
            </div>
            <div class="relative flex flex-column form-input w-full">
                <label>{{'address.address' | translate }}</label>
                <select class="select-block block-form w-full font-inherit" [(ngModel)]="selectedAddress">
                    <option [ngValue]="address" *ngFor="let address of login_data.Address">
                        {{address.AddressLine1}}, {{address.City}} {{address.ZIPCode}}</option>
                </select>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="flex w-full gap-1 justify-end">
        <button mat-button class="h-auto min-h-auto button-btn w-min"
            style="background-color: transparent; color: var(--color-primary); border-color: var(--color-primary)"
            (click)="cancelAddress()">{{'address.cancel' | translate }}</button>
        <button mat-button class="h-auto min-h-auto button-btn w-min" (click)="saveAdress()">{{'address.save' |
            translate }}</button>
    </div>
</div>