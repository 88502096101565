import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ReturnService } from 'src/app/service/return.service';
import { MatDialog } from '@angular/material/dialog';
import { Confirm2HandComponent } from '../2hand-confirm/2hand-confirm.component';

import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';

@Component({
  selector: 'app-2hand-conditionreport',
  templateUrl: './2hand-conditionreport.component.html',
  styleUrls: ['./2hand-conditionreport.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class ConditionReport2HandComponent implements OnInit, OnDestroy {

  title = "Tell us about your item";

  steps = ["CHOOSE ITEM", "CONDITION REPORT", "REVIEW"]

  stepID = 1;

  previousRoute = '/sell/sell-choosepiece';

  orderInfo: any = JSON.parse(localStorage.getItem('orderInfo')!);

  secondHandConditions = JSON.parse(localStorage.getItem("secondHandConditions")!)

  selectedProduct: any = JSON.parse(localStorage.getItem('selectedProduct')!);

  currency: any = JSON.parse(localStorage.getItem("currency")!)

  conditionSelected: any = JSON.parse(localStorage.getItem("conditionSelected")!)

  conditionIDSelected: any = JSON.parse(localStorage.getItem("conditionIDSelected")!)

  commentItem: any = JSON.parse(localStorage.getItem("commentItem")!)

  error_upload: boolean = false;

  error_upload_format: boolean = false;

  images: any = [];

  heightImg: any = 80;

  nextStepClick: boolean = false;
  
  loading: boolean = false;

  error_upload_max_images = false;

  image_error_size = false;

  image_minimum = false;

  loadinApplyImage = false;

  dataImage: NgxCroppedEvent | undefined;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    private readonly translateService: TranslateService,
    private ngxPhotoEditorService: NgxPhotoEditorService,
    
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.orderInfo == null && sessionStorage.getItem('hasLogged') == "true-submit-search") {
      this.previousRoute = '/submit'
    } else if (this.orderInfo == null && sessionStorage.getItem('hasLogged') == "true-submit-product") {
      this.previousRoute = '/product/' + this.selectedProduct.sku
    }
    this.translateService.stream('condition_report').subscribe(val => {
      this.title = val.title
      if (!this.orderInfo) {
        this.steps = [val.steps.find_item, val.steps.resolve, val.steps.review]
      } else {
        this.steps = [val.steps.choose_item, val.steps.resolve, val.steps.review]
      }
    });

    this.images = await this.returnService.GetAllImages()
  }

  calculatePercentageStep() {
    return 100 / this.steps.length * (this.stepID + 1) + '%'
  }

  disabledNextStepButton() {
    if (this.conditionSelected && this.selectedProduct.images.length >= 2) {
      return false
    }
    return true
  }

  base64ToFile(dataURI: string, fileName: string): File {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
  
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([ab], { type: 'image/jpeg' });
    return new File([blob], fileName, { type: blob.type });
  }

  createFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => {
      dataTransfer.items.add(file);
    });
    return dataTransfer.files;
  }
 
  async PhotoEditorAddMove(): Promise<void> {
    return new Promise<void>((resolve) => {
      const intervalId = setInterval(() => {
        this.loadinApplyImage = true;
        setTimeout(() => {
          this.loadinApplyImage = true
        }, 1)
        const body = document.body;
        const cropperDragBoxElement: any = body.childNodes[body.childNodes.length - 1];
        if (cropperDragBoxElement != null && cropperDragBoxElement != undefined && cropperDragBoxElement.localName === 'lib-ngx-photo-editor') {
          if (cropperDragBoxElement.childNodes[0].childNodes[0].childNodes[2].childNodes[0].childNodes[2]) {
            const classEditor = cropperDragBoxElement.childNodes[0].childNodes[0].childNodes[2].childNodes[0].childNodes[2].childNodes[1]
            if (classEditor != undefined && classEditor.attributes != undefined && classEditor.attributes != null && classEditor.attributes.length > 0) {
              //get button apply
              const applyButton = cropperDragBoxElement.childNodes[0].childNodes[0].childNodes[3].childNodes[1];
              //add event loading 
              applyButton.addEventListener('click', async (event: any) => {
                this.loadinApplyImage = true;
                setTimeout(() => {
                  this.loadinApplyImage = true;
                }, 1)
              });
              classEditor.attributes[1].nodeValue = 'move';
              classEditor.attributes[1].textContent = 'move';
              classEditor.attributes[1].value = 'move';
              classEditor.classList.remove('cropper-crop')
              classEditor.classList.add('cropper-move')
              setTimeout(() => {
                this.loadinApplyImage = false
              }, 1)
              clearInterval(intervalId);
              resolve();
            }
          }
        }
      }, 100);
    });
  }

  addLoadingAndImage(imageFile: any) {
    this.loadinApplyImage = true
    setTimeout(() => {
      this.loadinApplyImage = true;
    }, 1)

    return imageFile;
  }


  async onFileSelected(event: any, question?: any) {
    this.error_upload_format = false;
    this.image_error_size = false;
    this.error_upload_max_images = false;
    var imagesTypesAccepted = ['image/png', 'image/jpeg', 'image/webp'];
    const fileAux: any[] = [];

    const maxSizeInBytes = 4 * 1024 * 1024;

    for (let index = 0; index < event.target.files.length; index++) {
      if (!imagesTypesAccepted.includes(event.target.files[index].type)) {
        this.error_upload_format = true;
      }
      if (maxSizeInBytes < event.target.files[index].size) {
        this.image_error_size = true;
      }
      if (event.target.files.length + this.images.length > 4) {
        this.error_upload_max_images = true;
      }
      /* if(event.target.files.length+this.images.length<2){
         this.image_minimum=true;
       }*/
      if (this.error_upload_format || this.image_error_size || this.error_upload_max_images) return;

    }

    let i = 0;
    for (const imageFile of event.target.files) {
      try {
        this.PhotoEditorAddMove();
        this.dataImage = await this.ngxPhotoEditorService.open(this.addLoadingAndImage(imageFile), {
          format: 'png',
          autoCrop: false,
          autoCropArea: 1,
          modalMaxWidth: '100%',
          imageQuality: 100,
          imageSmoothingQuality: 'high',
          modalTitle: 'Image Editor',
        }).toPromise();

        if (this.dataImage == undefined || this.dataImage == null) {
          this.loadinApplyImage = false;
          continue;
        }

        fileAux.push(this.base64ToFile(this.dataImage?.base64!, imageFile.name));

      } catch (error) {
        console.error('Error open image editor ', error);
        this.loadinApplyImage = false;
        delete this.dataImage;
      }

      this.loadinApplyImage = false;
      delete this.dataImage;
      i++;
    }

    this.loading = true;
    try {
      event.target.files = this.createFileList(fileAux);

    } catch (error) {
      console.log('error save image in event', error)
      this.loading = false;
    }

    let reader: any = null;
    var images = this.selectedProduct.images
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        await this.returnService.InsertImages(reader.result);
        let id_db: any = await this.returnService.GetLastInput()
        this.images.push({ id: id_db.id, base64: id_db.base64 })
        this.selectedProduct.images.push({ id: images.length, image: id_db.id });
        localStorage.setItem('selectedProduct', JSON.stringify(this.selectedProduct));
        resolve(reader.result)
      };
      reader.onerror = (error: any) => reject(error);
    });

    for (var file of event.target.files) {

      if (images.length < 6 && imagesTypesAccepted.includes(file.type)) {
        await toBase64(file)
      } else if (!imagesTypesAccepted.includes(file.type)) {
        this.error_upload_format = true
      } else {
        this.error_upload = true
      }
      setTimeout(() => {
        this.error_upload_format = false
        this.error_upload = false
      }, 5000)
    }
    reader = null;
    images = null;
    this.error_upload_format = false;
    this.image_error_size = false;
    this.error_upload_max_images = false;
    this.loading = false;
    fileAux.splice(0, fileAux.length)
    event.target.files = this.createFileList(fileAux);
    delete this.dataImage;
  }

  getImageById(id: any) {
    return this.images.find((el: any) => el.id == id)?.base64
  }

  async openImage(image: any, event: any) {
    var selectedImage = this.getImageById(image)
    try {
      this.PhotoEditorAddMove();
      this.dataImage = await this.ngxPhotoEditorService.open(this.addLoadingAndImage(selectedImage), {
        format: 'png',
        autoCrop: false,
        autoCropArea: 1,
        modalMaxWidth: '100%',
        imageQuality: 100,
        imageSmoothingQuality: 'high',
        modalTitle: 'Image Editor',
        scalable: true,
        cropBoxMovable: true,
      }).toPromise();
      if (this.dataImage == undefined || this.dataImage == null) {
        this.loadinApplyImage = false;
        return;
      }
      this.loadinApplyImage = false;
      this.loading = true;
      for (let i = 0; i < this.images.length; i++) {
        if (this.images[i].id == image) {
          this.images[i].base64 = this.dataImage.base64;
        }
      }
      await this.returnService.UpdateImage(image, this.dataImage.base64!);
    } catch (error) {
      console.log(error)
      this.loading = false;
      this.loadinApplyImage = false;
      delete this.dataImage;
    }
    this.loading = false;
    delete this.dataImage;
  }
  
  async removeImage(idFile: number, question?: any) {
    setTimeout(() => {
      this.loading = true;
    }, 1)
    this.selectedProduct.images = this.selectedProduct.images.filter((e: any) => e.image != idFile);
    setTimeout(() => {
      this.loading = false;
    }, 1)
    localStorage.setItem('selectedProduct', JSON.stringify(this.selectedProduct));
    await this.returnService.DeleteImage(idFile);
    this.images = await this.returnService.GetAllImages();
  }


  nextStep() {
    this.nextStepClick = true
    if (!this.disabledNextStepButton() && !this.loading) {
      localStorage.setItem('conditionIDSelected', JSON.stringify(this.conditionIDSelected));
      localStorage.setItem('conditionSelected', JSON.stringify(this.conditionSelected));
      localStorage.setItem('commentItem', JSON.stringify(this.commentItem));
      this.router.navigate(['/sell/sell-confirm']);
    }
  }

  changeConditionSelected() {
    this.conditionSelected = this.secondHandConditions.find((condition: any) => condition.condition_id == this.conditionIDSelected)
  }

  ngOnDestroy() {
    delete this.dataImage;
  }
}


@Component({
  selector: 'image-dialog',
  templateUrl: 'image-dialog.html',
})
export class ImageDialog {

  selectedProduct: any = JSON.parse(localStorage.getItem('selectedProduct')!);

  selectedImage: any = JSON.parse(localStorage.getItem('selectedImage')!);

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    private readonly translateService: TranslateService,
  ) {
  }

  closeDialog() {
    new Confirm2HandComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
  }
}