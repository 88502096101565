import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-2hand-choosepiece',
  templateUrl: './2hand-choosepiece.component.html',
  styleUrls: ['./2hand-choosepiece.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class ChoosePiece2HandComponent implements OnInit {

  title = "What would you like to sell?";

  steps = ["CHOOSE ITEM", "CONDITION REPORT", "REVIEW"]

  stepID = 0;

  previousRoute = '/menu';

  selectedProduct: any = JSON.parse(localStorage.getItem("selectedProduct")!);

  secondHandProducts = JSON.parse(localStorage.getItem("secondHandProducts")!)

  nonResellable2HProducts = JSON.parse(localStorage.getItem("nonResellable2HProducts")!)

  currency: any = JSON.parse(localStorage.getItem("currency")!)

  heightImg: any = 80;

  constructor(
    private router: Router,
    private readonly translateService: TranslateService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.translateService.stream('choose_piece').subscribe(val => {
      this.title = val.title
      this.steps = [val.steps.choose_item, val.steps.resolve, val.steps.review]
    });
  }

  selectProduct(product: any) {
    this.selectedProduct = product
    this.selectedProduct.images = []
    localStorage.setItem('selectedProduct', JSON.stringify(this.selectedProduct))
  }

  nextStep() {
    this.router.navigate(['/sell/sell-conditionreport']);
  }
}

