import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReturnService } from 'src/app/service/return.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-recycle-review',
    templateUrl: './recycle-review.component.html',
    styleUrls: ['./recycle-review.component.css']
})
export class ReviewRecycleComponent implements OnInit {

    orderInfo: any = JSON.parse(localStorage.getItem("orderInfo")!);

    title = "Review your return";

    steps = [""]

    stepID = 1;

    previousRoute = '/recycle/recycle-choosepiece';

    returnDetails: any = JSON.parse(localStorage.getItem("returnDetails")!);

    shipping: any = JSON.parse(localStorage.getItem('shipping')!);

    currency: any = JSON.parse(localStorage.getItem('currency')!);

    productsToRecycle: any = JSON.parse(localStorage.getItem('productsToRecycle')!);

    returnFees: any = JSON.parse(localStorage.getItem('returnFees')!);

    returnFeeSelected: any = JSON.parse(localStorage.getItem('returnFeeSelected')!);

    dataAddress: any = JSON.parse(localStorage.getItem('dataAddress')!);

    countries: any = [];

    emailAddress: any = JSON.parse(localStorage.getItem('emailAddress')!);

    shippingAddress: any = this.shipping;

    emailAux: any = this.emailAddress;

    editEmail: boolean = false;

    editAddres: boolean = false;

    submittedEmail: boolean = false;

    submitted: boolean = false;

    errorSubmitted: boolean = false;

    loadButton: boolean = false;

    errorMessagePayment: any = null;

    heightImg: number = 80;

    loading = false;

    error: any;

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private returnService: ReturnService,
        private readonly translateService: TranslateService
    ) { }

    async ngOnInit(): Promise<void> {
        // load data into steps, translated
        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('recycle_review').subscribe(val => {
            this.title = val.title
            if (!this.orderInfo) {
                this.steps = [val.steps.find_item, val.steps.review]
            } else {
                this.steps = [val.steps.choose_item, val.steps.review]
            }
        });

        this.countries = await this.returnService.getCountries()

        if (!this.returnFeeSelected && this.returnFees) {
            this.returnFeeSelected = this.returnFees[0]
        }
        if (!this.emailAddress && this.returnDetails) {
            this.emailAddress = this.returnDetails.email;
            this.emailAux = this.emailAddress
        }

        if (!this.dataAddress && this.returnDetails && this.shipping) {
            this.dataAddress = {
                first_name: this.returnDetails.first_name,
                last_name: this.returnDetails.last_name,
                full_name: this.returnDetails.first_name + ' ' + this.returnDetails.last_name,
                address1: this.shipping.address,
                address2: this.shipping.address2,
                zip: this.shipping.zipcode,
                city: this.shipping.city,
                country: this.shipping.country,
                country_id: this.countries.find((country: any) => country.country_code == this.shipping.country_code).country_id,
                country_code: this.shipping.country_code,
                phone: this.shipping.phone
            }
        }
    }

    saveEmail(event: any, keypress: boolean) {
        if (((event.key === "Enter" && keypress) || !keypress)) {
            this.submittedEmail = true
        }
        if (this.validEmail() && ((event.key === "Enter" && keypress) || !keypress)) {
            this.emailAddress = this.emailAux
            this.editEmail = false
            localStorage.setItem('emailAddress', JSON.stringify(this.emailAddress))
        }
    }

    validEmail() {
        var validateEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.emailAux)
        if (validateEmail) {
            return true
        }
        return false
    }

    editAddress() {
        this.dialog.open(RecycleAddressDialog, { disableClose: false, width: '600px', maxWidth: '90%' });
    }
    addPersonalInfo() {
        this.dialog.open(RecyclePersonalInfoDialog, { disableClose: false, width: '600px', maxWidth: '90%' });
    }

    async submitRecycle() {
        this.submitted = true;
        this.loadButton = true;
        var recycleLines = []
        for (var product of this.productsToRecycle) {
            var productLine = {
                product_variant_id: product.product_variant_id,
                SKU: product.sku,
                EAN: product.ean,
                quantity: product.quantityRecycle
            }
            recycleLines.push(productLine)
        }
        var recycle: any = {
            order_id: this.returnDetails?.id,
            //order_name: this.returnDetails.ordername,
            currency: this.currency,
            first_name: this.returnDetails?.first_name,
            last_name: this.returnDetails?.last_name,
            address_name: this.dataAddress?.full_name,
            email_address: this.emailAddress,
            address_line1: this.dataAddress?.address1,
            address_line2: this.dataAddress?.address2,
            phone_number: this.dataAddress?.phone,
            zipcode: this.dataAddress?.zip,
            city: this.dataAddress?.city,
            id_country: this.dataAddress?.country_id,
            recycleLines: recycleLines
        }
        if (recycle.order_id) {
            this.returnService.postRecycle(recycle).then(
                data => {
                    this.loadButton = false;
                    this.router.navigate(['/recycle/recycle-success'])
                },
                error => {
                    this.errorSubmitted = true
                    this.loadButton = false
                    setTimeout(async () => {
                        await this.returnService.getLoginSession(this.orderInfo.idBrand).then((appDetails) => {
                            sessionStorage.setItem('appDetails', JSON.stringify(appDetails.brand))
                        })
                    }, 2500);
                }
            )
        } else {
            this.returnService.postRecycleOpenSubmit(recycle).then(
                data => {
                    this.loadButton = false;
                    this.router.navigate(['/recycle/recycle-success'])
                },
                error => {
                    this.errorSubmitted = true
                    this.loadButton = false
                    setTimeout(async () => {
                        await this.returnService.getLoginSession(this.orderInfo.idBrand).then((appDetails) => {
                            sessionStorage.setItem('appDetails', JSON.stringify(appDetails.brand))
                        })
                    }, 2500);
                }
            )
        }
    }
}


@Component({
    selector: 'address-dialog',
    templateUrl: 'address-dialog.html',
})
export class RecycleAddressDialog {

    selectedCountryCallingCode: any = null;

    email: any = null;

    full_name: any = null;

    address: any = null;

    address2: any = "";

    city: any = null;

    zip: any = null;

    phone: any = null;

    submitted: boolean = false;

    selectedAddress: any = null;

    selectedCountry: any = null;

    countries: any = [];

    countryCallingCode: any = [];

    dataAddress = JSON.parse(localStorage.getItem('dataAddress')!);

    login_data: any = JSON.parse(localStorage.getItem('login_data')!);

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private returnService: ReturnService,
        public readonly translateService: TranslateService
    ) { }

    async ngOnInit() {
        this.countries = await this.returnService.getCountriesWithReturnFee()
        this.countryCallingCode = await this.returnService.getCountriesX();
    }

    async saveAddress() {
        this.submitted = true
        if (this.full_name && this.address && this.city && this.zip && this.phone && this.selectedCountry && this.selectedCountryCallingCode) {
            //const country_code = this.countries.find((country:any) => country.country_id === this.selectedCountry).country_code
            const callingCode = this.countryCallingCode.find((country: any) => country.cca2 == this.selectedCountryCallingCode);
            const callingCodePref = callingCode.idd.root + callingCode.idd.suffixes[0];
            this.dataAddress = {
                full_name: this.full_name,
                address1: this.address,
                address2: this.address2,
                zip: this.zip,
                city: this.city,
                country_id: this.selectedCountry,
                country: this.countries.find((country: any) => country.country_id == this.selectedCountry).country_name,
                phone: callingCodePref + this.phone.toString(),
            }
            var returnFeeSelected = this.countries.find((country: any) => country.country_id == this.selectedCountry)
            localStorage.setItem('dataAddress', JSON.stringify(this.dataAddress));
            localStorage.setItem('returnFeeSelected', JSON.stringify(returnFeeSelected));
            new ReviewRecycleComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
            window.location.reload();
        }
    }

    cancelAddress() {
        this.full_name = null
        this.address = null
        this.address2 = null
        this.city = null
        this.zip = null

        new ReviewRecycleComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
    }
}

@Component({
    selector: 'personalInfo-dialog',
    templateUrl: 'personalInfo-dialog.html',
})
export class RecyclePersonalInfoDialog {

    email: any = null;

    first_name: any = null;

    last_name: any = null;

    submitted: boolean = false;

    returnDetails: any = JSON.parse(localStorage.getItem('returnDetails')!);

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private returnService: ReturnService,
        public readonly translateService: TranslateService
    ) { }

    async saveAddress() {
        this.submitted = true
        if (this.email && this.first_name && this.last_name && this.validEmail()) {
            this.returnDetails = {
                first_name: this.first_name,
                last_name: this.last_name
            }

            localStorage.setItem('emailAddress', JSON.stringify(this.email));
            localStorage.setItem('returnDetails', JSON.stringify(this.returnDetails));
            new ReviewRecycleComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
            window.location.reload();
        }
    }

    cancelAddress() {
        this.email = null
        this.first_name = null
        this.last_name = null

        new ReviewRecycleComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
    }

    validEmail() {
        var validateEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.email)
        if (validateEmail) {
            return true
        }
        return false
    }
}

