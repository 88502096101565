<div id="request-return-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-1 md:w-90-percent products-return">
                <h1 class="lg:hidden">{{ 'request.title' | translate }}</h1>
                <div *ngIf="products.length == 0" class="block-white">
                    <div>{{ 'request.no_products' | translate }}</div>
                </div>
                <div *ngFor="let product of returnableProducts" class="product block-white"
                    [class]="{'selected' : productsReturn.includes(product)}" (click)="selectProduct(product)">
                    <div class="flex align-center justify-between relative h-full gap-1 product-container">
                        <div *ngIf="product.image" id="product-image-{{product.line_item_id}}" class="product-image"
                            [style]="{background: 'url(' + product.image + ') center center / cover no-repeat', height: '80px'}">
                        </div>
                        <div class="product-details-left">
                            <div class="bold-featured-text">{{ product.nameAux ? product.nameAux : product.name }}</div>
                            <div>{{product.size}} / {{product.color}}</div>
                            <div>{{product.price}} {{currency}}</div>
                            <div class="md:block hidden">x{{product.quantity}}</div>
                        </div>
                        <div class="product-details-right md:hidden block">
                            <div>x{{product.quantity}}</div>
                        </div>
                    </div>

                    <div *ngIf="productsReturn.includes(product)">
                        <div class="flex flex-column gap--5">
                            <div>
                                <div class="extra-text mb--5">{{ (product.return_reasons.length == 1 ?
                                    'request.return_reason' : 'request.return_reasons') | translate }}</div>
                                <div class="product-line"></div>
                            </div>
                            <div *ngFor="let return_reasons of product.return_reasons" class="flex flex-column gap--5">
                                <div class="flex justify-between align-center gap-1">
                                    <div class="flex flex-column gap--5 return-reason-data cursor-pointer"
                                        (click)="editReason(product, return_reasons.id)">
                                        <div *ngIf="return_reasons.option" class="bold-featured-text">
                                            {{return_reasons.option.option}}</div>
                                        <div *ngIf="return_reasons.exchange" class="bold-featured-text">{{
                                            'request.return_exchange_reason' | translate }}
                                            {{return_reasons.sizeExchange}}</div>
                                    </div>
                                    <div class="flex align-center h-full">
                                        <div class="flex sm:gap-0 gap--8 quantity-block">
                                            <button class="cursor-pointer h-full button-quantity-return"
                                                [disabled]="product.totalQuantityReturn <= 1 || return_reasons.quantityReturn == 1"
                                                (click)="decrementQuantity(product, return_reasons.id)">
                                                <mat-icon
                                                    style="font-size: 16px; width: 16px; height: 16px;">remove</mat-icon>
                                            </button>
                                            <div class="bold-featured-text flex align-center">
                                                {{return_reasons.quantityReturn}}</div>
                                            <button class="cursor-pointer h-full button-quantity-return"
                                                [disabled]="product.totalQuantityReturn >= product.quantity"
                                                (click)="incrementQuantity(product, return_reasons.id)">
                                                <mat-icon
                                                    style="font-size: 16px; width: 16px; height: 16px;">add</mat-icon>
                                            </button>
                                        </div>
                                        <div class="flex align-center cursor-pointer"
                                            (click)="removeProduct($event, product, return_reasons.id)">
                                            <mat-icon>delete</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-column gap--5 return-reason-details"
                                    *ngIf="return_reasons.comment || return_reasons.images.length > 0">
                                    <div>{{return_reasons.comment}}</div>
                                    <div class="flex flex-wrap gap--8">
                                        <div *ngFor="let image of return_reasons.images">
                                            <div class="upload-image"
                                                [style]="{background: 'url(' + getImageById(image.image) + ') center center / cover no-repeat', border: 'none'}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="productsReturn.includes(product) && (product.totalQuantityReturn < product.quantity)"
                        class="absolute flex justify-center align-center w-full btn-more-reason">
                        <mat-icon class="icon-more-reason" (click)="openMoreReason(product)">add</mat-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="nonReturnableProducts.length > 0"
                class="flex flex-column gap-1 md:w-90-percent products-non-returnable">
                <h2>{{ 'request.no_return_item' | translate }}</h2>
                <div class="flex flex-column gap-1 product disabled block-white">
                    <div *ngFor="let product of nonReturnableProducts"
                        class="flex align-center justify-between relative h-full gap-1 product-container"
                        style="opacity: 0.5;">
                        <div *ngIf="product.image" class="product-image"
                            [style]="{background: 'url(' + product.image + ') center center / cover no-repeat', height: '80px'}">
                        </div>
                        <div class="product-details-left">
                            <div class="bold-featured-text">{{product.nameAux ? product.nameAux : product.name}}</div>
                            <div>{{product.size}} / {{product.color}}</div>
                        </div>
                        <div class="product-details-right">
                            <div>x{{product.quantity}}</div>
                        </div>
                    </div>
                    <div class="product-line"></div>
                    <div class="flex flex-column gap--5">
                        <div class="extra-text">{{ 'request.no_policy_item' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <button class="button-btn md:w-90-percent" type="submit" [disabled]="productsReturn?.length==0"
            (click)="nextStep()">{{ 'request.next' | translate }}</button>
    </div>
</div>
<div *ngIf="selectedProduct.dialogProduct"
    class="w-full h-full flex align-center justify-center absolute request-return-option">
    <div id="choose-option" class="choose-option" role="dialog" aria-modal="true"
        [ngClass]="{'choose-option-full': !selectedProduct.image}">
        <div class="flex gap--5">
            <div *ngIf="selectedProduct.image" id="choose-option-img" class="choose-option-img md:hidden">
                <div class="product-img"
                    [style]="{background: 'url(' + selectedProduct.image + ') center center / contain no-repeat'}">
                </div>
            </div>
            <div class="choose-option-block options" [ngClass]="{'choose-option-block-full': !selectedProduct.image}"
                *ngIf="!selectedProduct.return_reasons[selectedProduct.actual_reason].returnorder && !selectedProduct.return_reasons[selectedProduct.actual_reason].refundorder">
                <div class="choose-option-header justify-end">
                    <div class="cursor-pointer" (click)="closeDialog()">
                        <mat-icon>close</mat-icon>
                    </div>
                </div>
                <div id="choose-return-or-refund" class="choose-return-or-refund justify-between">
                    <div class="flex flex-column gap-1">
                        <h2>{{ 'request.exchange_refound_question' | translate }}</h2>
                        <div class="extra-text">{{ 'request.exchange_refound_text' | translate }}</div>
                        <div class="w-full flex gap-1">
                            <button class="large-btn select_reason_button outline-gray-soft-16"
                                [class]="{'selected' : selectedProduct.return_reasons[selectedProduct.actual_reason].exchange}"
                                (click)="openRefundOption()" [disabled]="!permissionExchangeProduct">
                                <div class="flex flex-column gap--5">
                                    <div class="flex align-center justify-center"><img src="assets/delivery-status.png"
                                            width="50"></div>
                                    <div><b>{{ 'request.exchange' | translate }}</b></div>
                                </div>
                            </button>
                            <button class="large-btn select_reason_button outline-gray-soft-16"
                                [class]="{'selected' : !selectedProduct.return_reasons[selectedProduct.actual_reason].exchange && selectedProduct.return_reasons[selectedProduct.actual_reason].option.length > 0}"
                                (click)="openReturnOption()">
                                <div class="flex flex-column gap--5">
                                    <div class="flex align-center justify-center"><img
                                            src="assets/return-on-investment.png" width="50"></div>
                                    <div><b>{{ 'request.storecredit_refound' | translate }}</b></div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!permissionExchangeProduct" class="flex flex-column gap--5">
                        <div class="line"></div>
                        <div class="extra-text">{{ 'request.no_policy_exchange_item' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="choose-option-block more-details"
                [ngClass]="{'choose-option-block-full': !selectedProduct.image}"
                *ngIf="selectedProduct.return_reasons[selectedProduct.actual_reason].returnorder">
                <div *ngIf="!moreDetails">
                    <div class="choose-option-header"
                        [ngClass]="{'justify-end' : !permissionExchange || !selectedProduct.exchangeable, 'justify-between ': permissionExchange && selectedProduct.exchangeable}">
                        <div *ngIf="permissionExchange && selectedProduct.exchangeable" class="cursor-pointer"
                            (click)="previousBlock('returnorder')">
                            <mat-icon class="chevron-left-icon">chevron_left</mat-icon>
                        </div>
                        <div class="cursor-pointer" (click)="closeDialog()">
                            <mat-icon>close</mat-icon>
                        </div>
                    </div>
                    <div id="choose-reason-options" class="choose-reason-options"
                        [ngStyle]="{'height': heightDialog + 'px'}">
                        <h2>{{ 'request.return_reason_modal.question' | translate }}</h2>
                        <div *ngFor="let returnOption of returnOptions"
                            class="select_reason_button outline-gray-soft-16"
                            [class]="{'selected' : selectedProduct.return_reasons[selectedProduct.actual_reason].option?.option == returnOption.option}"
                            (click)="openMoreDetails(returnOption)">
                            <div class="bold-featured-text">{{returnOption.option}}</div>
                            <div class="flex justify-center align-center">
                                <mat-icon style="color: #e0e1e2;">chevron_right</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="moreDetails">
                    <div class="choose-option-header justify-between">
                        <div class="cursor-pointer" (click)="previousBlock('moreDetails')">
                            <mat-icon class="chevron-left-icon">chevron_left</mat-icon>
                        </div>
                        <div class="cursor-pointer" (click)="closeDialog()">
                            <mat-icon>close</mat-icon>
                        </div>
                    </div>
                    <div id="choose-reason-more-details" class="choose-reason-more-details"
                        [ngStyle]="{'height': heightDialog + 'px'}">
                        <h2>{{ 'request.return_reason_modal.title' | translate }}</h2>
                        <div class="h-full">
                            <div class="flex flex-column gap-1 h-full justify-between">
                                <div class="flex flex-column gap-1">
                                    <div class="form-input" *ngIf="moreDetailsOption.comment">
                                        <div class="featured-text">{{ 'request.return_reason_modal.comment' | translate
                                            }} <small *ngIf="moreDetailsOption.requiredComment">*</small>
                                        </div>
                                        <div class="extra-text">{{ 'request.return_reason_modal.Comment_desc' |
                                            translate }}</div>
                                        <div class="w-full" style="padding: 0 1px">
                                            <textarea id="comment" type="text"
                                                [(ngModel)]="selectedProduct.return_reasons[selectedProduct.actual_reason].comment"
                                                maxlength="500" class="outline-gray-8"
                                                [attr.required]="moreDetailsOption.requiredComment"
                                                [ngClass]="{'invalid-field' : submitted && !selectedProduct.comment && moreDetailsOption.requiredComment}"></textarea>
                                        </div>
                                        <div class="extra-text-invalid"
                                            *ngIf="submitted && !selectedProduct.comment && moreDetailsOption.requiredComment">
                                            {{ 'request.return_reason_modal.required_field' | translate }}</div>
                                    </div>
                                    <div class="form-input" *ngIf="moreDetailsOption.image">
                                        <div class="error-message-warning" *ngIf="error_upload">
                                            <div class="flex">
                                                <mat-icon>error_outline</mat-icon>
                                            </div>
                                            {{ 'request.return_reason_modal.image_error' | translate }}
                                        </div>
                                        <div class="error-message-warning" *ngIf="error_upload_format">
                                            <div class="flex">
                                                <mat-icon>error_outline</mat-icon>
                                            </div>
                                            {{ 'request.return_reason_modal.image_error_format' | translate }}
                                        </div>
                                        <div class="featured-text">{{ 'request.return_reason_modal.image' | translate }}
                                            <small *ngIf="moreDetailsOption.requiredImage">*</small>
                                        </div>
                                        <div class="extra-text">{{ 'request.return_reason_modal.image_max_up' |
                                            translate }}</div>
                                        <input id="choose-file" type="file" class="file-input hidden"
                                            (change)="onFileSelected($event)" accept="image/png, image/jpeg, image/webp"
                                            multiple maxRequestLength="100000000">
                                        <div class="flex flex-wrap gap--8">
                                            <div class="upload-image outline-dashed-gray-soft"
                                                [ngClass]="{'invalid-field' : submitted && selectedProduct.return_reasons[selectedProduct.actual_reason].images.length==0 && moreDetailsOption.requiredImage}">
                                                <label for="choose-file"
                                                    class="flex flex-column justify-center align-center cursor-pointer">
                                                    <svg width="16" viewBox="0 0 16 16" stroke="" role="img">
                                                        <g>
                                                            <path
                                                                d="M8.875 13C8.875 13.4832 8.48325 13.875 8 13.875C7.51675 13.875 7.125 13.4832 7.125 13V8.875H3C2.51675 8.875 2.125 8.48325 2.125 8C2.125 7.51675 2.51675 7.125 3 7.125H7.125V3C7.125 2.51675 7.51675 2.125 8 2.125C8.48325 2.125 8.875 2.51675 8.875 3V7.125H13C13.4832 7.125 13.875 7.51675 13.875 8C13.875 8.48325 13.4832 8.875 13 8.875H8.875V13Z"
                                                                fill="#273C4854" stroke-width="1.75"
                                                                stroke-linecap="round" stroke="">
                                                            </path>
                                                        </g>
                                                    </svg>
                                                    <div class="extra-text text-center">{{
                                                        'request.return_reason_modal.max_size' |
                                                        translate }}</div>
                                                </label>
                                            </div>
                                            <div *ngFor="let file of selectedProduct.return_reasons[selectedProduct.actual_reason].images"
                                                class="relative">
                                                <div class="upload-image-icon cursor-pointer"
                                                    (click)="removeImage(file.image)">
                                                    <svg width="24" viewBox="0 0 36 36" stroke="" focusable="true"
                                                        role="img" data-focusable="true" tabindex="0">
                                                        <g>
                                                            <path
                                                                d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z"
                                                                fill="#000000BF" stroke=""></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M12.7071 11.2929C12.3166 10.9024 11.6834 10.9024 11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071L16.5858 18L11.293 23.2928C10.9024 23.6833 10.9024 24.3165 11.293 24.707C11.6835 25.0975 12.3166 25.0975 12.7072 24.707L18 19.4142L23.2928 24.707C23.6833 25.0975 24.3165 25.0975 24.707 24.707C25.0975 24.3165 25.0975 23.6833 24.707 23.2928L19.4142 18L24.7071 12.7071C25.0976 12.3166 25.0976 11.6834 24.7071 11.2929C24.3166 10.9024 23.6834 10.9024 23.2929 11.2929L18 16.5858L12.7071 11.2929Z"
                                                                fill="#FFFFFF" stroke=""></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div class="upload-image"
                                                    [style]="{background: 'url(' + getImageById(file.image) + ') center center / cover no-repeat', height: '103px', border: 'none'}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="extra-text-invalid"
                                            *ngIf="submitted && selectedProduct.return_reasons[selectedProduct.actual_reason].images.length==0 && moreDetailsOption.requiredImage">
                                            {{ 'request.return_reason_modal.required_field' | translate }}</div>
                                    </div>
                                </div>
                                <button class="button-btn" type="submit" (click)="onSubmit()">{{
                                    'request.return_reason_modal.confirm_btn' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="choose-option-block more-details"
                [ngClass]="{'choose-option-block-full': !selectedProduct.image}"
                *ngIf="selectedProduct.return_reasons[selectedProduct.actual_reason].refundorder">
                <div *ngIf="!moreDetails">
                    <div class="choose-option-header justify-between">
                        <div class="cursor-pointer" (click)="previousBlock('returnorder')">
                            <mat-icon class="chevron-left-icon">chevron_left</mat-icon>
                        </div>
                        <div class="cursor-pointer" (click)="closeDialog()">
                            <mat-icon>close</mat-icon>
                        </div>
                    </div>
                    <div id="choose-reason-options" class="choose-reason-options justify-between">
                        <div class="flex flex-column gap-1">
                            <h2>{{ 'request.exchange_modal.question' | translate }}</h2>
                            <div *ngIf="loadingSizesExchange"
                                class="p-5 relative loadProduct flex justify-center h-full">
                                <mat-spinner></mat-spinner>
                            </div>
                            <div *ngIf="!loadingSizesExchange" class="w-full flex flex-column gap-1">
                                <button
                                    *ngFor="let variantSize of selectedProduct.return_reasons[selectedProduct.actual_reason].variantSizes"
                                    style="background-color: transparent;"
                                    class="select_reason_button outline-gray-soft-16"
                                    [class]="{'selected' : selectedProduct.return_reasons[selectedProduct.actual_reason].variant_id_exc == variantSize.variant_id,
                                'invalid-field' : submittedSize && !selectedProduct.return_reasons[selectedProduct.actual_reason].variant_id_exc}"
                                    (click)="chooseVariantProduct(variantSize)" [disabled]="variantSize.quantity == 0">
                                    <div class="w-full flex justify-between">
                                        <div class="flex gap--5 align-center">
                                            <div>{{variantSize.product_variant_option2.value}}</div>
                                            <div *ngIf="variantSize.quantity == 0">{{
                                                'request.exchange_modal.out_of_stock'
                                                | translate }}</div>
                                        </div>
                                        <div *ngIf="variantSize.quantity == 0" class="refund-icon"
                                            (click)="submitRefund()">
                                            <img src="assets/return-on-investment.png" width="20">
                                        </div>
                                    </div>
                                </button>
                                <div class="extra-text-invalid"
                                    *ngIf="submittedSize && !selectedProduct.return_reasons[selectedProduct.actual_reason].sizeExchange">
                                    {{ 'request.exchange_modal.required_field' | translate }}</div>
                            </div>
                        </div>
                        <button *ngIf="!loadingSizesExchange" class="button-btn" type="submit"
                            [disabled]="allOutOfStock" (click)="onSubmitExchange()">{{
                            'request.exchange_modal.confirm_btn' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer></footer>