import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  appDetails: any = JSON.parse(sessionStorage.getItem('appDetails')!)

  orderInfo: any = JSON.parse(localStorage.getItem('orderInfo')!);

  login_data: any = JSON.parse(localStorage.getItem('login_data')!);

  @Input()
  login_div: boolean = false;

  @Input()
  customTitle: string = '';

  numberNotification: number = 0;

  constructor(
    private router: Router,
    public readonly translateService: TranslateService,
    private notificationService: NotificationService
  ) {
    this.notificationService.dataUpdated.subscribe((notification) => {
      this.numberNotification = notification;
    });
  }

  logOut() {
    this.login_data = null
    localStorage.setItem('login_data', JSON.stringify(this.login_data))
    localStorage.setItem('address_data', JSON.stringify(null))
    window.location.reload();
  }

  goLoginPage() {
    this.router.navigate([''])
  }

  goHistorySubmissions() {
    if (this.notificationService.hasData() <= 0) return;
    this.router.navigate(['history/submission-history'])
    this.notificationService.removeNotification();

  }

}
