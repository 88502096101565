import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReturnService } from 'src/app/service/return.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { OptionReturn } from 'src/app/api/optionReturn';
import { Shipping } from 'src/app/api/shipping';
import { ReturnMenuComponent } from '../return-menu/return-menu.component';

@Component({
  selector: 'app-return-login',
  templateUrl: './return-login.component.html',
  styleUrls: ['./return-login.component.css'],
})
@Injectable({
  providedIn: 'root', // just before your class
})
export class ReturnLoginComponent implements OnInit {

  cookieAccept: any = this.cookieService.get('accept-cookie');

  idBrand: any = this.main.idBrand;

  appDetails: any = this.main.appDetails;

  loadPage: boolean = this.main.loadPage;

  returnDetails: any | undefined;

  orderNumber: any | undefined;

  orderNumberAux: any | undefined;

  email: any | undefined;

  messageError: any | undefined;

  errorLogin: boolean = false;

  submitted: boolean = false;

  loadButton: boolean = false;

  products: any[] = [];

  options: OptionReturn[] = [];

  orderInfo: any = [];

  id_token = sessionStorage.getItem('id_token');

  permissionRefund: boolean = true;

  permissionExchange: boolean = false;

  exchangeCountries: any = [];

  exchangeOption: any;

  exchangePayment: any;

  productsReturn: any[] = [];

  shipping: Shipping | undefined;

  selectedPayment: any = null;

  selectPaymentOption: any = null;

  paymentOption: any = [];

  paymentOptionSelect: any = [];

  returnFees: any = [];

  currency: any;

  coockie_policy: any = environment.coockie_policy;

  showButton: boolean = false;

  permissionReturn: boolean = JSON.parse(sessionStorage.getItem('appDetails')!)['returns']

  permission2Hand: boolean = JSON.parse(sessionStorage.getItem('appDetails')!)['2hand']

  permissionRepair: boolean = JSON.parse(sessionStorage.getItem('appDetails')!)['repair']

  permissionRecycle: boolean = JSON.parse(sessionStorage.getItem('appDetails')!)['recycle']

  searchByProduct: boolean = false;

  searchProduct: string = ''

  submittedSearch: boolean = false

  successSubmittedSearch: boolean = false

  productSelected: any = JSON.parse(localStorage.getItem('productSelected')!);

  selectedProduct: any;

  openDialog: boolean = false

  selectedSize: any

  selectedColor: any

  colors: any

  sizes: any = []

  no_option2: boolean = true;

  submittedSize: boolean = false

  constructor(
    private cookieService: CookieService,
    private returnService: ReturnService,
    private route: ActivatedRoute,
    private main: AppComponent,
    private router: Router,
    private readonly translateService: TranslateService,
  ) { }

  async ngOnInit(): Promise<void> {
    localStorage.clear()
    localStorage.setItem('currency', JSON.stringify('EUR'));
    this.returnService.ClearStorage()
    sessionStorage.setItem('hasLogged', JSON.stringify(false))
    this.route.queryParams.subscribe(params => {
      this.email = params['email']
      this.orderNumber = params['ordername']
      if (this.email && this.orderNumber) {
        this.onSubmit()
      }
    });
    window.addEventListener('scroll', this.scroll, true)
  }

  scroll = (): void => {
    var scrollHeight = window.document.getElementById("return-app")?.scrollTop as number
    var screenHeight = document.documentElement.clientHeight as number
    if (scrollHeight > (screenHeight / 2)) {
      this.showButton = true
    } else {
      this.showButton = false
    }
  };

  recoverHistoryOrders(): void {
    this.router.navigate(['/recover-history-orders'])
  }

  onSubmit(): void {
    ReturnMenuComponent.oneTimeNav = false;
    this.submitted = true
    this.loadButton = true
    this.errorLogin = false
    this.messageError = null

    //remove white spaces of orderNumber and email
    this.orderNumberAux = this.orderNumber ? this.orderNumber.trim() : null
    this.email = this.email ? this.email.trim() : null

    //accept orderNumber with # or no
    if (this.orderNumberAux && this.orderNumberAux.charAt(0) == '#') {
      this.orderNumberAux = this.orderNumber.substring(1)
    } else if (this.orderNumberAux && this.orderNumberAux.includes('exc-#')) {
      this.orderNumberAux = this.orderNumber.replace('#', '')
    }

    if (this.email && this.orderNumberAux) {
      this.returnService.getReturnDetails(this.orderNumberAux, this.email, this.id_token).then(
        async (returnDetails) => {
          this.returnDetails = returnDetails
          this.returnDetails.email = this.email.toLowerCase()
          //se não existirem linhas é porque já passaram os 30 dias e aparece sell
          if (typeof returnDetails.order_lines == "undefined") {
            this.init2HandVariable()
            this.permissionRefund = false
            localStorage.setItem('products', JSON.stringify(this.products))
          } else {
            this.initReturnVariable()
            this.permissionRefund = true
          }
          this.router.navigate(['/menu'])

          this.errorLogin = false
          this.messageError = null
          sessionStorage.setItem('hasLogged', JSON.stringify(true))
          localStorage.setItem('returnDetails', JSON.stringify(this.returnDetails))
          localStorage.setItem('permissionRefund', JSON.stringify(this.permissionRefund))
          setTimeout(() => {
            this.loadButton = false
          }, 500);
        },
        (error) => {
          this.loadButton = false
          this.errorLogin = true
          if (error.error.error) {
            this.messageError = error.error.error.error
          } else {
            this.messageError = this.translateService.instant("login.token_expired")
            setTimeout(async () => {
              await this.returnService.getLoginSession(this.appDetails.id_brand).then((appDetails) => {
                this.appDetails = appDetails.brand
                sessionStorage.setItem('appDetails', JSON.stringify(this.appDetails))
              })
            }, 2500);
          }
        }
      );

    } else {
      this.loadButton = false
      this.errorLogin = true
      this.messageError = this.translateService.instant("login.wrong_number_email")
    }
  }

  //submit with enter key on login form
  onSubmitEnter(event: any): void {
    if (event.key === "Enter") {
      this.onSubmit()
    }
  }

  acceptCookies() {
    this.cookieService.set('accept-cookie', 'true')
    this.cookieAccept = this.cookieService.get('accept-cookie')
  }

  fromBKO(): void {
    this.submitted = true;
    this.loadButton = true;
    this.errorLogin = false;
    this.messageError = null;

    this.orderNumberAux = this.orderNumber ? this.orderNumber.trim() : null
    this.email = this.email ? this.email.trim() : null

    if (this.orderNumberAux && this.orderNumberAux.charAt(0) == '#') {
      this.orderNumberAux = this.orderNumber.substring(1)
    }

    if (this.email && this.orderNumberAux) {
      this.returnService.getReturnDetailsBKO(this.orderNumberAux, this.email).then(
        (returnDetails) => {
          this.returnDetails = returnDetails
          this.returnDetails.email = this.email.toLowerCase()
          if (Object.keys(returnDetails).length == 8) {
            this.init2HandVariable()
            this.permissionRefund = false
            localStorage.setItem('products', JSON.stringify(this.products))
          } else {
            this.initReturnVariable()
            this.permissionRefund = true
          }
          this.router.navigate(['/menu'])
          this.errorLogin = false
          this.messageError = null
          sessionStorage.setItem('hasLogged', JSON.stringify(true))
          localStorage.setItem('returnDetails', JSON.stringify(this.returnDetails))
          localStorage.setItem('permissionRefund', JSON.stringify(this.permissionRefund))
          setTimeout(() => {
            this.loadButton = false
          }, 500);
        },
        (error) => {
          this.loadButton = false
          this.errorLogin = true
          if (error.error.error) {
            this.messageError = error.error.error.error
          } else {
            this.messageError = this.translateService.instant("login.token_expired")
          }
        }
      );
    } else {
      this.loadButton = false
      this.errorLogin = true
      this.messageError = this.translateService.instant("login.wrong_number_email")
    }
  }

  async go2Hand() {
    let secondHandProductsAux: any[] = [];
    let secondHandConditions: any[] = [];
    let secondHandFees: any;
    var orderID = JSON.parse(localStorage.getItem('orderInfo')!).id
    await this.returnService.get2HandProducts(orderID).then((details2Hand) => {
      secondHandProductsAux = details2Hand["2hand_products"]
      secondHandConditions = details2Hand["2hand_condictions"]
      secondHandFees = details2Hand["2handfees"]
    })
    var secondHandProducts = secondHandProductsAux.filter((product: any) => product.resellable == true)
    var nonResellable2HProducts = secondHandProductsAux.filter((product: any) => product.resellable == false)
    localStorage.setItem('secondHandProducts', JSON.stringify(secondHandProducts))
    localStorage.setItem('nonResellable2HProducts', JSON.stringify(nonResellable2HProducts))
    localStorage.setItem('secondHandConditions', JSON.stringify(secondHandConditions))
    localStorage.setItem('secondHandFees', JSON.stringify(secondHandFees))
  }

  initReturnVariable() {
    if (localStorage.getItem('hasRun') == null) {
      localStorage.setItem('hasRun', 'true')
      if (this.returnDetails) {
        this.orderInfo = {
          idBrand: this.idBrand,
          id: this.returnDetails.id,
          ordername: this.returnDetails.ordername,
          email: this.returnDetails.email
        }
        this.shipping = this.returnDetails.order_shipping
        this.returnFees = this.returnDetails.return_fees
        this.currency = this.returnDetails.currency
        if (this.appDetails.exchanges == 1) {
          this.exchangeCountries = this.appDetails.exchange_countries
          this.permissionExchange = this.exchangeCountries.find((el: any) => el.country_code == this.returnDetails.order_shipping.country_code) ? true : false
        } else {
          this.permissionExchange = false
        }
        if (this.returnDetails.order_lines) {
          for (var product of this.returnDetails.order_lines) {
            var permissionExchangeProduct = this.permissionExchange && product.exchangeable ? true : false
            this.products.push({
              line_item_id: product.line_item_id,
              product_id: product.product_id,
              variant_id: product.variant_id,
              ean: product.ean,
              sku: product.sku,
              name: product.name,
              nameAux: product.name.substring(0, product.name.lastIndexOf(' - ')),
              price: Number(product.price),
              quantity: product.quantity,
              size: product.option_2.value,
              color: product.option_1.value,
              image: product.image ? product.image : product.image_url,
              image_url: product.image_url ? product.image_url : null,
              returnable: product.returnable,
              exchangeable: product.exchangeable,
              return_reasons: [{
                id: 0, exchange: false, option: [], quantityReturn: 1, comment: '', images: [], returnorder: !permissionExchangeProduct, refundorder: false
              }],
              actual_reason: 0,
              totalQuantityReturn: 0,
              dialogProduct: false,
            })
          }
        }
        if (this.returnDetails.return_options != null)
          for (var return_option of this.returnDetails.return_options) {
            if (!return_option.option.includes('(EXC)')) {
              this.options.push({
                option_id: return_option.option_id,
                option: return_option.option,
                comment: Boolean(return_option.comment),
                requiredComment: Boolean(return_option.requiredComment),
                image: Boolean(return_option.image),
                requiredImage: Boolean(return_option.requiredImage),
                noQuantity: Boolean(return_option.return_noquantity)
              })
            } else {
              this.exchangeOption = return_option.option_id
            }
          }
        if (this.returnDetails.return_payment_options != null)
          for (var paymentOption of this.returnDetails.return_payment_options) {
            if (!Boolean(paymentOption.exchange_flag)) {
              this.paymentOption.push({
                id_return_payment: paymentOption.id_return_payment,
                description: paymentOption.description,
                fee_flag: paymentOption.fee_flag
              })
              if (paymentOption.fee_flag == 0) {
                this.selectPaymentOption = {
                  id_return_payment: paymentOption.id_return_payment,
                  description: paymentOption.description,
                  fee_flag: paymentOption.fee_flag
                }
              }
            } else {
              this.exchangePayment = paymentOption.id_return_payment
            }
          }
        localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
        localStorage.setItem('shipping', JSON.stringify(this.shipping))
        localStorage.setItem('options', JSON.stringify(this.options))
        localStorage.setItem('paymentOptions', JSON.stringify(this.paymentOption))
        localStorage.setItem('selectPaymentOption', JSON.stringify(this.selectPaymentOption))
        localStorage.setItem('returnFees', JSON.stringify(this.returnFees))
        localStorage.setItem('currency', JSON.stringify(this.currency))
        localStorage.setItem('products', JSON.stringify(this.products))
        localStorage.setItem('exchangeOption', JSON.stringify(this.exchangeOption))
        localStorage.setItem('exchangePayment', JSON.stringify(this.exchangePayment))
        localStorage.setItem('permissionExchange', JSON.stringify(this.permissionExchange))
      }
    }
  }

  init2HandVariable() {
    this.orderInfo = {
      idBrand: this.idBrand,
      id: this.returnDetails.id,
      ordername: this.returnDetails.ordername,
      email: this.returnDetails.email
    }
    this.currency = this.returnDetails.currency
    this.shipping = this.returnDetails.order_shipping
    this.returnFees = this.returnDetails.return_fees
    localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
    localStorage.setItem('currency', JSON.stringify(this.currency))
    localStorage.setItem('returnFees', JSON.stringify(this.returnFees))
    localStorage.setItem('shipping', JSON.stringify(this.shipping))
  }

  howItWorks() {
    document.getElementById("explain-block")?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  goToTop() {
    document.getElementById("login-block")?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  searchByProductForm() {
    this.searchByProduct = !this.searchByProduct;
  }

  async searchProducts(event?: any) {
    this.submittedSearch = false
    if (!event || (event && event?.key === "Enter")) {
      this.submittedSearch = true
      this.successSubmittedSearch = false
      this.products = []
      if (this.searchProduct.length > 2) {
        await this.returnService.getAllSearchProducts(this.searchProduct)
          .then((details2Hand) => {
            this.submittedSearch = false
            this.successSubmittedSearch = true
            this.products = details2Hand.products
            localStorage.setItem('secondHandConditions', JSON.stringify(details2Hand.descriptions))
            localStorage.setItem('repairers_list', JSON.stringify(details2Hand.repairs_list))
            localStorage.setItem('secondHandFees', JSON.stringify(details2Hand['2handfees'][0]))
          })
          .catch(error => {
            this.submittedSearch = false
            this.submittedSearch = false
          })
      }
    }
  }

  selectProduct(product: any) {
    this.selectedProduct = product
    this.selectedProduct.images = []
    this.submittedSize = false
    this.selectedColor = null
    this.selectedSize = null
    this.sizes = []
    if (environment.id_brand == 1) {
      this.selectedProduct.colorSizes = this.groupByColorSKU(this.selectedProduct.variants, 'option1_value', 'sku')
    } else {
      this.selectedProduct.colorSizes = this.groupByColor(this.selectedProduct.variants, 'option1_value')
    }
    this.colors = Object.keys(this.selectedProduct.colorSizes)
    localStorage.setItem('selectedProduct', JSON.stringify(this.selectedProduct))
    this.openDialog = true
  }

  groupByColorSKU(input: any, key1: any, key: any) {
    return input?.reduce((acc: any, currentValue: any) => {
      let groupKey = { key: currentValue[key], color: currentValue[key1] };
      groupKey.key = groupKey.key.slice(0, 14)
      if (!acc[groupKey.key + '-' + groupKey.color]) {
        acc[groupKey.key + '-' + groupKey.color] = [];
      }
      acc[groupKey.key + '-' + groupKey.color].push(currentValue);
      return acc;
    }, {});
  };

  groupByColor(input: any, key: any) {
    return input?.reduce((acc: any, currentValue: any) => {
      let groupKey = currentValue[key];
      if (!acc['-' + groupKey]) {
        acc['-' + groupKey] = [];
      }
      acc['-' + groupKey].push(currentValue);
      return acc;
    }, {});
  };

  getColorName(color: any) {
    return color.slice(color.lastIndexOf('-') + 1)
  }

  selectSize(size: any) {
    this.submittedSize = false
    this.selectedSize = size
  }

  selectColor(color: any) {
    this.submittedSize = false
    this.selectedColor = color
    this.selectedSize = null
    this.sizes = this.selectedProduct.colorSizes[color]
    this.selectedProduct.image_url = this.sizes[0].image_url
    if (this.sizes[0].option2 !== null) {
      this.no_option2 = false
    }
  }

  confirmSize() {
    this.submittedSize = true
    if (this.selectedColor && ((!this.no_option2 && this.selectedSize) || this.no_option2)) {
      if (this.no_option2) {
        this.selectedSize = this.selectedProduct.colorSizes[this.selectedColor][0]
      }
      this.selectedProduct.color = this.getColorName(this.selectedColor)
      this.selectedProduct.sku = this.selectedSize.sku
      this.selectedProduct.size = this.selectedSize.option2_value
      this.selectedProduct.price = this.selectedSize.price
      this.selectedProduct.ean = this.selectedSize.ean
      this.selectedProduct.product_variant_id = this.selectedSize.product_variant_id
      this.selectedProduct.totalQuantityRepair = 1
      this.selectedProduct.name = this.selectedProduct.title
      this.selectedProduct.option_1 = { value: this.selectedProduct.color }
      this.selectedProduct.option_2 = { value: this.selectedProduct.size }
      this.selectedProduct.quantity = 1
      this.selectedProduct.quantityRecycle = 1
      localStorage.setItem("selectedProduct", JSON.stringify(this.selectedProduct))
      localStorage.setItem('secondHandProducts', JSON.stringify([this.selectedProduct]))
      localStorage.setItem('nonResellable2HProducts', JSON.stringify([]))
      localStorage.setItem("repairProducts", JSON.stringify([this.selectedProduct]))
      localStorage.setItem("productsToRepairSKU", JSON.stringify([this.selectedProduct.sku]))
      localStorage.setItem('productsToRepair', JSON.stringify([this.selectedProduct]))
      localStorage.setItem('recycleProducts', JSON.stringify([this.selectedProduct]))
      localStorage.setItem('productsToRecycle', JSON.stringify([this.selectedProduct]))
      localStorage.setItem('productsToRecycleID', JSON.stringify([this.selectedProduct.product_variant_id]))
      sessionStorage.setItem('hasLogged', 'true-search');
      this.router.navigate(['/menu'])
      this.openDialog = false
      this.submittedSize = false
    }
  }

  closeDialog() {
    this.no_option2 = true
    this.openDialog = false
  }
}

