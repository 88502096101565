import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { ReturnLoginComponent } from './pages/return-login/return-login.component';
import { ReturnMenuComponent } from './pages/return-menu/return-menu.component';
import { RequestReturnComponent } from './pages/return-request/return-request.component';
import { ResolutionReturnComponent } from './pages/return-resolution/return-resolution.component';
import { ReviewReturnComponent } from './pages/return-review/return-review.component';
import { SuccessReturnComponent } from './pages/return-success/return-success.component';
import { ChoosePiece2HandComponent } from './pages/2hand-choosepiece/2hand-choosepiece.component';
import { ConditionReport2HandComponent } from './pages/2hand-conditionreport/2hand-conditionreport.component';
import { Confirm2HandComponent } from './pages/2hand-confirm/2hand-confirm.component';
import { Success2HandComponent } from './pages/2hand-success/2hand-success.component';
import { ChoosePieceRepairComponent } from './pages/repair-choosepiece/repair-choosepiece.component';
import { ReviewRepairComponent } from './pages/repair-review/repair-review.component';
import { SuccessRepairComponent } from './pages/repair-success/repair-success.component';
import { SubmissionHistoryComponent } from './pages/submission-history/submission-history.component';
import { SearchProductComponent } from './pages/search-product/search-product.component';
import { SearchProductRepairComponent } from './pages/search-product-repair/search-product-repair.component';
import { RecoverHistoryOrdersComponent } from './pages/recover-history-orders/recover-history-orders.component';
import { ProductQRCodeComponent } from './pages/qrcode-product/qrcode-product.component';
import { ChoosePieceRecycleComponent } from './pages/recycle-choosepiece/recycle-choosepiece.component';
import { ReviewRecycleComponent } from './pages/recycle-review/recycle-review.component';
import { SuccessRecycleComponent } from './pages/recycle-success/recycle-success.component';

const routes: Routes = [
  {
    path: '', component: AppComponent,
    children: [
      { path: '', component: ReturnLoginComponent, },
      { path: 'submit', component: SearchProductComponent, canActivate: [AuthGuard] },
      { path: 'submit-repair', component: SearchProductRepairComponent, canActivate: [AuthGuard] },
      { path: 'menu', component: ReturnMenuComponent, canActivate: [AuthGuard] },
      {path: 'recover-history-orders', component: RecoverHistoryOrdersComponent},
      { path: 'return/return-request', component: RequestReturnComponent, canActivate: [AuthGuard] },
      { path: 'return/return-resolution', component: ResolutionReturnComponent, canActivate: [AuthGuard] },
      { path: 'return/return-review', component: ReviewReturnComponent, canActivate: [AuthGuard] },
      { path: 'return/return-success', component: SuccessReturnComponent, canActivate: [AuthGuard] },
      { path: 'sell/sell-choosepiece', component: ChoosePiece2HandComponent, canActivate: [AuthGuard] },
      { path: 'sell/sell-conditionreport', component: ConditionReport2HandComponent, canActivate: [AuthGuard] },
      { path: 'sell/sell-confirm', component: Confirm2HandComponent, canActivate: [AuthGuard] },
      { path: 'sell/sell-success', component: Success2HandComponent, canActivate: [AuthGuard] },
      { path: 'repair/repair-choosepiece', component: ChoosePieceRepairComponent, canActivate: [AuthGuard] },
      { path: 'repair/repair-review', component: ReviewRepairComponent, canActivate: [AuthGuard] },
      { path: 'repair/repair-success', component: SuccessRepairComponent, canActivate: [AuthGuard] },
      { path: 'recycle/recycle-choosepiece', component: ChoosePieceRecycleComponent, canActivate: [AuthGuard] },
      { path: 'recycle/recycle-review', component: ReviewRecycleComponent, canActivate: [AuthGuard] },
      { path: 'recycle/recycle-success', component: SuccessRecycleComponent, canActivate: [AuthGuard] },
      { path: 'product/:id', component: ProductQRCodeComponent, canActivate: [AuthGuard] },
      { path: 'history/submission-history', component: SubmissionHistoryComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
