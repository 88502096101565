<div id="return-app" role="main" class="overflow-auto"
    [style]="{background: 'url(data:image/png;base64,' + appDetails.background + ') center center / cover no-repeat fixed'}">
    <div *ngIf="loadPage" class="w-full h-full">
        <div class="w-full h-full flex justify-center align-center">
            <div class="relative justify-start load-page">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    <change-language></change-language>
    <div *ngIf="!loadPage" class="home-page flex flex-row md:flex-column w-full h-auto min-h-screen">
        <div id="login-block"
            class="md:w-full w-50-percent min-h-screen flex flex-column gap-1 justify-center align-center">
            <div id="arrow" class="arrow-back previousButton relative flex w-min l-0 justify-between">
                <div class="w-full cursor-pointer absolute" (click)="previousPage()">
                    <svg width="40" height="40" viewBox="0 0 36 36" stroke="" role="img"
                        class="r-b0vftf r-mabqd8 r-1yvhtrz">
                        <g>
                            <path
                                d="M0.5 18C0.5 8.05888 8.55888 0 18.5 0C28.4411 0 36.5 8.05888 36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55888 36 0.5 27.9411 0.5 18Z"
                                fill="#FFFF" stroke=""></path>
                            <path d="M21.5 11L14.6768 17.8232C14.5791 17.9209 14.5791 18.0791 14.6768 18.1768L21.5 25"
                                stroke="#273C48" stroke-opacity="0.98" stroke-width="2" stroke-linecap="round"
                                fill="#E9EAEB"></path>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="return-login flex flex-column align-center overflow-y-scroll">
                <div class="mb-2">
                    <h1 *ngIf="!appDetails.logo" class="font-25">{{ 'login.no_logo_text' | translate }}</h1>
                    <img *ngIf="appDetails.logo" id="logo" [src]="'data:image/png;base64,' + appDetails.logo">
                </div>
                <div class="error-message justify-center" *ngIf="errorGetEmail">
                    <div class="flex">
                        <mat-icon>error_outline</mat-icon>
                    </div>
                    {{messageError}}
                </div>
                <div class="w-full">
                    <div *ngIf="!successRecoverPassword" class="flex flex-column gap-1">
                        <div class="form-input formfield-lowercase">
                            <input id="email" type="email" [(ngModel)]="email"
                                placeholder="{{ 'login.email_address' | translate }}" (keypress)="onSubmitEnter($event)"
                                style="height: 3rem !important;">
                        </div>
                        <button class="button-btn" type="submit" (click)="onSubmit()">
                            <div class="flex gap-1 h-full align-center justify-center relative">
                                <div class="relative justify-start">
                                    <mat-spinner *ngIf="submitted && loadButton"></mat-spinner>{{
                                    'login.recover_orders_btn' |
                                    translate }}
                                </div>
                            </div>
                        </button>
                    </div>
                    <div *ngIf="successRecoverPassword"
                        class="flex flex-column justify-center align-center text-center gap-1">
                        <span>{{ 'login.success_recover_order_history' | translate }}</span>
                        <button class="button-btn-secundary align-center justify-center" (click)="previousPage()">
                            {{ 'login.back_login' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="md:flex hidden flex-column gap--5 align-center cursor-pointer" (click)="previousPage()">
                <div class="flex justify-center align-center p-1 color-white">{{ 'login.go_back_home_Page' | translate
                    }}</div>
                <mat-icon class="arrow-explain color-white">arrow_circle_left</mat-icon>
            </div>
        </div>
        <div id="explain-block"
            class="explains-block md:w-full w-50-percent min-h-screen flex flex-column justify-center align-center">
            <div class="return-explain flex flex-column gap-1 w-full h-auto flex justify-center align-center">
                <div *ngIf="permissionReturn" class="block-explain">
                    <div class="title-explain">{{ 'login.returns_exchanges' | translate }}</div>
                    <div class="grid-explain">
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img src="../../../assets/login/orderemail.svg" class="w-full h-full">
                            </div>
                        </div>
                        <div class="block-left-explain flex justify-center align-start">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img src="../../../assets/login/shiptruck.svg" class="w-full h-full">
                            </div>
                        </div>
                        <div class="block-left-explain flex justify-center align-start">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img src="../../../assets/login/giftcard.svg" class="w-full h-full">
                            </div>
                        </div>
                        <div class="text-explain">{{ 'login.order_email' + this.idBrand | translate }}</div>
                        <div></div>
                        <div class="text-explain">{{ 'login.ship_return' | translate }}</div>
                        <div></div>
                        <div class="text-explain">{{ 'login.receive_return' | translate }}</div>
                    </div>
                </div>
                <div *ngIf="permissionRepair" class="block-explain">
                    <div class="title-explain">{{ 'login.repairs' | translate }}</div>
                    <div class="grid-explain">
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img src="../../../assets/login/orderemail.svg" class="w-full h-full">
                            </div>
                        </div>
                        <div class="block-left-explain flex justify-center align-start">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img src="../../../assets/login/shiptruck.svg" class="w-full h-full">
                            </div>
                        </div>
                        <div class="block-left-explain flex justify-center align-start">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img src="../../../assets/login/repair.svg" class="w-full h-full">
                            </div>
                        </div>
                        <div class="text-explain">{{ 'login.order_email' + this.idBrand | translate }}</div>
                        <div></div>
                        <div class="text-explain">{{ 'login.ship_repair' | translate }}</div>
                        <div></div>
                        <div class="text-explain">{{ 'login.receive_repair' | translate }}</div>
                    </div>
                </div>
                <div *ngIf="permission2Hand" class="block-explain">
                    <div class="title-explain">{{ 'login.resell' | translate }}</div>
                    <div class="grid-explain">
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img src="../../../assets/login/orderemail.svg" class="w-full h-full">
                            </div>
                        </div>
                        <div class="block-left-explain flex justify-center align-start">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img src="../../../assets/login/shiptruck.svg" class="w-full h-full">
                            </div>
                        </div>
                        <div class="block-left-explain flex justify-center align-start">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                        <div class="icon-explain-block">
                            <div class="icon-explain flex justify-center">
                                <img [src]="'../../../assets/login/creditcard_' + idBrand + '.svg'"
                                    class="w-full h-full">
                            </div>
                        </div>
                        <div class="text-explain">{{ 'login.order_email' + this.idBrand | translate }}</div>
                        <div></div>
                        <div class="text-explain">{{ 'login.ship_resell' | translate }}</div>
                        <div></div>
                        <div class="text-explain">{{ 'login.receive_resell' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="md:flex hidden arrow-up-block cursor-pointer"
                [class]="{'arrow-visible' : showButton, 'arrow-hidden' : !showButton}" (click)="goToTop()">
                <div class="arrow-up">
                    <mat-icon class="color-white">arrow_upward</mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!loadPage && cookieAccept !== 'true'" class="animate cookie-info" style="z-index: 50;">
        <mat-icon class="cookie-info-icon" (click)="acceptCookies()">close</mat-icon>
        <div>
            {{ 'login.coockie_message_1' | translate }} <a href="{{coockie_policy}}" target="_blank"
                style="color: var(--color-primary);"><b>{{ 'login.coockie_statement' | translate }}</b></a> {{
            'login.coockie_message_2' | translate }}
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<footer></footer>