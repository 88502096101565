import { Component, Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { changelanguageShared } from '../change-language/change-languageShared.service'

@Component({
  selector: 'change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.css'],
})
@Injectable({
  providedIn: 'root',
})
export class ChangeLanguageComponent implements OnInit {

  countries = [
    { name: 'United Kingdom', code: 'gb', codeAux: 'en' },
    { name: 'Portugal', code: 'pt' },
  ];

  selectedCountry: any;

  constructor(
    private readonly translateService: TranslateService,
    private changelanguageShared: changelanguageShared
  ) { }
  
  ngOnInit() {
    try {
      const currentTranslation = this.translateService.currentLang;
      this.selectedCountry = this.countries.find((country: any) => country.code === currentTranslation)!;
    } catch (error) {
      console.log(error)
    }
  }

  selectCountry(c: any) {
    try {
      this.selectedCountry = this.countries.find((country: any) => country.code === c.code)!;
      this.translateService.use(this.selectedCountry.code);
      this.changelanguageShared.callNgOnInit();
    } catch (error) {
      console.log(error)
    }
  }
}

