<div class="lang-header absolute w-full flex flex-row justify-center gap-2 align-center">
    <div class="lang-block flex flex-row justify-end align-center">
        <div>
            <img src="assets/flags/flag_placeholder.png" [class]="'icon-flag flag flag-' + selectedCountry.code">
        </div>
        <div *ngFor="let country of countries" (click)="selectCountry(country)"
            class="flag-block h-min flex align-center justify-center cursor-pointer">
            <span class="code-flag" [class]="{'selected' : selectedCountry.code == country.code}">{{ country.codeAux ?
                country.codeAux : country.code
                }}</span>
        </div>
    </div>
</div>