import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReturnService } from 'src/app/service/return.service';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-qrcode-product',
  templateUrl: './qrcode-product.component.html',
  styleUrls: ['./qrcode-product.component.css']
})
export class ProductQRCodeComponent implements OnInit {

  orderInfo: any = JSON.parse(localStorage.getItem('orderInfo')!);

  title = "What would you like to repair?";

  steps = [""]

  stepID = !this.orderInfo ? 1 : 0;

  previousRoute = '/menu';

  appDetails: any = this.main.appDetails;

  currency: any = JSON.parse(localStorage.getItem('currency')!);

  conditions2Hand: any = null;

  heightImg: number = 80;

  product_id: any;

  nextOption: any;

  product: any = JSON.parse(localStorage.getItem('qrcode_product')!);

  login_data: any = JSON.parse(localStorage.getItem('login_data')!);

  repairersList: any = JSON.parse(localStorage.getItem('repairers_list')!);

  theme = environment.theme;

  errorProduct: boolean = false;

  constructor(
    private main: AppComponent,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
  ) { }

  async ngOnInit(): Promise<void> {
    localStorage.setItem('currency', JSON.stringify('EUR'));
    this.orderInfo = null
    localStorage.setItem('orderInfo', JSON.stringify(null))!
    sessionStorage.setItem('hasLogged', 'true-submit-product');

    this.route.params.subscribe(
      (params) => {
        this.product_id = params['id']
      }
    )

    this.returnService.getProductSKU(this.product_id).then(data => {
      if (data.product.length == 0) this.errorProduct = true
      this.product = data.product[0]
      this.product.images = []
      this.product.servicesAvailable = data.services
      this.product.repairServices = [{ id: 0, idService: data.services[0].id_service, description: data.services[0].description, price: data.services[0].price, quantityRepair: 1, editComment: false, comment: "", commentEditable: "", images: [] }]
      this.conditions2Hand = data.conditions
      this.login_data = data.marketplace_login.Customer
      this.repairersList = data.repairers_list
      localStorage.setItem('qrcode_product', JSON.stringify(this.product))
      localStorage.setItem('repairers_list', JSON.stringify(this.repairersList))
    })
  }

  goTo2Hand() {
    var address_data = null
    if (this.login_data?.Address?.length > 0) {
      var address_data = this.login_data.Address.find((address: any) => address.DefaultAddress == 1) == undefined ? this.login_data.Address[0] : this.login_data.Address.find((address: any) => address.DefaultAddress == 1)
    }
    localStorage.setItem('login_data', JSON.stringify(this.login_data))
    localStorage.setItem('address_data', JSON.stringify(address_data))
    localStorage.setItem("secondHandConditions", JSON.stringify(this.conditions2Hand))
    localStorage.setItem('selectedProduct', JSON.stringify(this.product))
    this.router.navigate(['/sell/sell-conditionreport'])
  }

  goToRepair() {
    localStorage.setItem("selectedProduct", JSON.stringify(this.product))
    localStorage.setItem("repairProducts", JSON.stringify([this.product]))
    localStorage.setItem("productsToRepair", JSON.stringify([this.product]))
    localStorage.setItem("productsToRepairSKU", JSON.stringify([this.product.sku]))
    this.router.navigate(['/repair/repair-choosepiece']);
  }

  goToRecycle() {
    this.product.name = this.product.title
    this.product.option_1 = { value: this.product.color }
    this.product.option_2 = { value: this.product.size }
    this.product.quantity = 1
    this.product.quantityRecycle = 1
    localStorage.setItem("recycleProducts", JSON.stringify([this.product]))
    localStorage.setItem("productsToRecycle", JSON.stringify([this.product]))
    localStorage.setItem("productsToRecycleID", JSON.stringify([this.product.product_variant_id]))
    this.router.navigate(['/recycle/recycle-choosepiece']);
  }
}