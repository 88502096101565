<h1 mat-dialog-title>{{'personalInfo.add_personal_info' | translate }}</h1>
<div mat-dialog-content class="popUp-block" style="padding: 20px 24px;">
    <div class="flex flex-column gap-1">
        <form #form="ngForm" class="flex flex-column gap--5">
            <div class="flex sm:flex-column gap--5">
                <div class="relative flex flex-column w-full form-input">
                    <label>{{'personalInfo.first_name' | translate }}</label>
                    <input id="first_name" type="text" [(ngModel)]="first_name" name="full_name" required maxlength="50"
                        [ngClass]="{'ng-invalid-input' : !first_name && submitted}">
                    <div *ngIf="!first_name && submitted" class="extra-text-invalid">{{'personalInfo.required_field' |
                        translate }}</div>
                </div>
                <div class="relative flex flex-column w-full form-input">
                    <label>{{'personalInfo.last_name' | translate }}</label>
                    <input id="last_name" type="text" [(ngModel)]="last_name" name="last_name" required maxlength="50"
                        [ngClass]="{'ng-invalid-input' : !last_name && submitted}">
                    <div *ngIf="!last_name && submitted" class="extra-text-invalid">{{'personalInfo.required_field' |
                        translate }}</div>
                </div>
            </div>
            <div class="relative flex flex-column w-full form-input">
                <label>{{'personalInfo.email' | translate }}</label>
                <input id="email" type="text" [(ngModel)]="email" name="email" required maxlength="50"
                    [ngClass]="{'ng-invalid-input' : submitted && (!email || (email && !validEmail()))}">
                <div *ngIf="!email && submitted" class="extra-text-invalid">{{'personalInfo.required_field' |
                    translate }}</div>
                <div *ngIf="email && !validEmail() && submitted" class="extra-text-invalid height-24 flex align-center">
                    {{
                    'repair_review.valid_email' |
                    translate }}</div>
            </div>
        </form>

    </div>
</div>
<div mat-dialog-actions>
    <div class="flex w-full gap-1 justify-end">
        <button mat-button class="h-auto min-h-auto button-btn w-min"
            style="background-color: transparent; color: var(--color-primary); border-color: var(--color-primary)"
            (click)="cancelAddress()">{{'address.cancel' | translate }}</button>
        <button mat-button class="h-auto min-h-auto button-btn w-min" (click)="saveAddress()">{{'address.save' |
            translate }}</button>
    </div>
</div>