<div id="choosepiece-repair-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-1 md:w-90-percent products-return">
                <h1 class="lg:hidden">{{ 'recycle.title' | translate }}</h1>
                <div *ngIf="recycleProducts?.length == 0" class="block-white">
                    <div>{{ 'recycle.no_products' | translate }}</div>
                </div>
                <div *ngFor="let product of recycleProducts" class="product block-white"
                    [class]="{'selected' : productsToRecycleID?.includes(product.product_variant_id)}"
                    (click)="selectProduct(product)">
                    <div class="flex align-center justify-between relative h-full gap-1 product-container">
                        <div *ngIf="product?.image_url" class="product-image" style="min-height: 80px;"
                            [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat'}">
                        </div>
                        <div class="product-details-left">
                            <div class="bold-featured-text">{{ product.name }}</div>
                            <div>{{product.option_2.value}} / {{product.option_1.value}}</div>
                            <div *ngIf="this.orderInfo !== null">x{{product.quantity}}</div>
                        </div>
                    </div>
                    <div *ngIf="productsToRecycleID?.includes(product.product_variant_id)"
                        class="flex flex-column gap--5">
                        <div class="flex flex-column gap--5">
                            <div>
                                <div class="extra-text mb--5">{{ 'recycle.quantity_recycle' | translate }}</div>
                                <div class="product-line"></div>
                            </div>
                            <div class="flex flex-column gap--5 popUp-block">
                                <div class="flex w-full justify-between align-center sm:gap-0 gap-1">
                                    <div class="flex w-full justify-between align-center h-full">
                                        <div class="flex sm:gap-0 gap--8 quantity-block">
                                            <button class="cursor-pointer h-full button-quantity-return"
                                                [disabled]="product.quantityRecycle == 1"
                                                (click)="decrementQuantity(product)">
                                                <mat-icon
                                                    style="font-size: 16px; width: 16px; height: 16px;">remove</mat-icon>
                                            </button>
                                            <div class="bold-featured-text flex align-center">
                                                {{product.quantityRecycle}}</div>
                                            <button class="cursor-pointer h-full button-quantity-return"
                                                [disabled]="product.quantityRecycle == product.quantity"
                                                (click)="incrementQuantity(product)">
                                                <mat-icon
                                                    style="font-size: 16px; width: 16px; height: 16px;">add</mat-icon>
                                            </button>
                                        </div>
                                        <div class="flex align-center cursor-pointer"
                                            (click)="removeProduct($event, product)">
                                            <mat-icon>delete</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <button class="button-btn md:w-90-percent" type="submit" [disabled]="productsToRecycleID?.length == 0"
            (click)="nextStep()">{{ 'recycle.next' | translate }}</button>
    </div>
</div>
<footer></footer>