<div id="return-resolution-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-1 md:w-90-percent main-block return-resolution">
                <h1 class="lg:hidden"> {{ 'resolution.title' | translate }}</h1>
                <div *ngFor="let payment of paymentOption"
                    class="w-full flex cursor-pointer justify-between resolve block-white align-center"
                    [class]="{'selected' : selectPaymentOption.id_return_payment === payment.id_return_payment }"
                    (click)="selectPayment(payment)">
                    <div class="w-full flex flex-column gap--5">
                        <div class="bold-featured-text">{{payment.description}}</div>
                        <div *ngIf="payment.fee_flag == 0" class="flex align-center gap--5">
                            <div class="extra-text"> {{ 'resolution.free' | translate }}</div>
                        </div>
                        <div *ngIf="payment.fee_flag == 1 && returnFees[0]" class="flex align-center gap--5">
                            <div class="extra-text">{{ 'resolution.maybe_fee' | translate }}</div>
                            <mat-icon aria-hidden="false" fontIcon="info" class="info-icon cursor-pointer"
                                [matMenuTriggerFor]="menu" #trigger="matMenuTrigger" (mouseover)="trigger.openMenu()">
                            </mat-icon>
                            <mat-menu #menu="matMenu">
                                <div class="text-small flex flex-column align-center gap--5"
                                    (mouseleave)="trigger.closeMenu()">
                                    {{ 'resolution.fee_from' | translate }} {{ returnFees[0].country_name + ': ' +
                                    returnFees[0].value + ' ' + currency}}
                                </div>
                            </mat-menu>
                        </div>
                        <div class="popUp-block"
                            *ngIf="returnDetails.IBAN && payment.fee_flag == 1 && selectPaymentOption.id_return_payment === payment.id_return_payment">
                            <form #form="ngForm">
                                <div class="relative flex flex-column form-input">
                                    <label>IBAN</label>
                                    <input id="IBAN" type="IBAN" [(ngModel)]="IBAN" name="IBAN" required
                                        style="text-transform: uppercase;">
                                    <div *ngIf="!IBAN && submitted"
                                        class="extra-text-invalid height-24 flex align-center">{{
                                        'resolution.required_field' | translate }}</div>
                                    <div *ngIf="IBAN && (IBAN.length > 34 || !checkValidIBAN()) && submitted"
                                        class="extra-text-invalid height-24 flex align-center">{{
                                        'resolution.invalid_IBAN' | translate }}</div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <button class="button-btn md:w-90-percent" type="submit" [disabled]="selectPaymentOption==null"
            (click)="nextStep()">{{ 'resolution.next' | translate }}</button>
    </div>
</div>
<footer></footer>