import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  dataUpdated = new EventEmitter<number>();
  isRemoveNot:boolean=false;

  updateData(data: number) {
    this.dataUpdated.emit(data);
  }

  removeNotification(){
    this.dataUpdated.emit(0);
    this.isRemoveNot=true;
  }

  hasData():number{
    return  JSON.parse(localStorage.getItem('allSubmissions')!).length;
  }


  getDataUpdatedValue(): number  {
    const ola= JSON.parse(localStorage.getItem('not')!);
    return ola;
  }
}
