<div id="review-repair-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-2 md:w-90-percent review-return">
                <div>
                    <h1 class="lg:hidden">{{ 'repair_review.title' | translate }}</h1>
                    <div class="flex justify-between w-full gap-2 md:flex-column">
                        <div class="flex flex-column gap-1 md:w-full w-60-percent">
                            <div class="flex flex-column gap--5 return-items">
                                <h2>{{ 'repair_review.repair_items' | translate }}</h2>
                                <div *ngFor="let product of productsToRepair" class="flex flex-column gap-1 block-white">
                                    <div class="flex align-center relative h-full gap-1 product-container">
                                        <div *ngIf="product?.image_url" class="product-image" style="height: 80px;"
                                            [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat', height: '80px'}">
                                        </div>
                                        <div class="product-details-left">
                                            <div class="bold-featured-text">{{product.title}}</div>
                                            <div>{{product.size}} / {{product.color}}</div>
                                            <div *ngIf="product.quantity" class="md:block hidden">x{{product.quantity}}
                                            </div>
                                        </div>
                                        <div *ngIf="product.quantity" class="product-details-right md:hidden block">
                                            <div>x{{product.quantity}}</div>
                                        </div>
                                    </div>
                                    <div class="flex flex-column gap--5">
                                        <div>
                                            <div class="extra-text mb--5">{{ 'repair.repair_services' | translate }}
                                            </div>
                                            <div class="line"></div>
                                        </div>
                                        <div *ngFor="let repair_service of product.repairServices"
                                            class="flex flex-column gap--5">
                                            <div class="flex justify-between align-center">
                                                <div>
                                                    <div *ngIf="repair_service.idService" class="bold-featured-text">
                                                        {{teste(product, repair_service)}}</div>
                                                </div>
                                                <div>
                                                    x{{repair_service.quantityRepair}}
                                                </div>
                                            </div>
                                            <div *ngIf="repair_service.comment || repair_service.images.length > 0"
                                                class="flex flex-column gap--5 return-reason-details">
                                                <div>{{repair_service.comment}}</div>
                                                <div class="flex flex-wrap gap--8">
                                                    <div *ngFor="let image of repair_service.images">
                                                        <div class="upload-image"
                                                            [style]="{background: 'url(' + getImageById(image.image) + ') center center / cover no-repeat', border: 'none'}">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-column gap--5">
                                <h2>{{ 'repair_review.personal_information' | translate }}</h2>
                                <div *ngIf="returnDetails" class="block-white flex flex-column gap--5">
                                    <div>{{returnDetails?.first_name}} {{returnDetails?.last_name}}</div>
                                    <div class="flex justify-between gap--5">
                                        <div *ngIf="!editEmail">{{emailAddress}}</div>
                                        <form #form="ngForm" *ngIf="editEmail" class="w-full flex flex-column">
                                            <div class="flex sm:flex-column gap--5">
                                                <div class="relative flex flex-column w-full form-input">
                                                    <input id="emailAddress" type="text" [(ngModel)]="emailAux" required
                                                        (keypress)="saveEmail($event, true)" name="emailAddress"
                                                        required>
                                                    <div *ngIf="!validEmail() && submittedEmail"
                                                        class="extra-text-invalid height-24 flex align-center">{{
                                                        'repair_review.valid_email' |
                                                        translate }}</div>
                                                </div>
                                            </div>
                                        </form>
                                        <a *ngIf="!editEmail && !paymentActive" class="flex align-center extra-text"
                                            style="max-height: 3rem; color: var(--color-primary)" (click)="editEmail = true">{{
                                            'repair_review.edit' |
                                            translate }}</a>
                                        <a *ngIf="editEmail" class="flex align-center extra-text"
                                            style="max-height: 3rem; color: var(--color-primary)" (click)="saveEmail($event, false)">{{
                                            'repair_review.save' |
                                            translate }}</a>
                                    </div>
                                </div>
                                <div *ngIf="!returnDetails" class="block-white flex flex-column gap-1">
                                    <a *ngIf="!paymentActive" class="flex extra-text" style="color: var(--color-primary)"
                                        [class]="{'error-message' : submitted && errorSubmitted}"
                                        (click)="addPersonalInfo()">
                                        {{'repair_review.add_personal_info' | translate }}</a>
                                    <div class="error-message-warning" style="justify-content: start;">
                                        <div class="flex">
                                            <mat-icon>error_outline</mat-icon>
                                        </div>
                                        {{'repair_review.provide_personal_info' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-column gap--5">
                                <h2>{{ 'repair_review.delivery_address' | translate }}</h2>
                                <div *ngIf="dataAddress" class="block-white flex justify-between">
                                    <div>
                                        <div>{{dataAddress?.full_name}}</div>
                                        <div>{{dataAddress?.address1}}</div>
                                        <div>{{dataAddress?.address2}}</div>
                                        <div>{{dataAddress?.city}} {{dataAddress?.zip}}</div>
                                        <div>{{dataAddress?.country}}</div>
                                    </div>
                                    <a *ngIf="!paymentActive" class="flex align-center extra-text" style="color: var(--color-primary)"
                                        (click)="editAddress()">{{ 'repair_review.edit' | translate }}</a>
                                </div>
                                <div *ngIf="!dataAddress" class="block-white flex flex-column gap-1">
                                    <a *ngIf="!paymentActive" class="flex extra-text" style="color: var(--color-primary)"
                                        [class]="{'error-message' : submitted && errorSubmitted}"
                                        (click)="editAddress()">
                                        {{'repair_review.add_address' | translate }}</a>
                                    <div class="error-message-warning" style="justify-content: start;">
                                        <div class="flex">
                                            <mat-icon>error_outline</mat-icon>
                                        </div>
                                        {{'repair_review.provide_location' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-column gap--5">
                                <h2>{{ 'repair_review.choose_repaired' | translate }}</h2>
                                <div id="map" style="height: 350px;"></div>
                            </div>
                        </div>
                        <div class="flex flex-column gap-1 md:w-full w-40-percent">
                            <div class="flex flex-column gap--5 w-full">
                                <h2>{{ 'repair_review.summary' | translate }}</h2>
                                <div class="flex flex-column gap-1 block-white">
                                    <div class="flex justify-between">
                                        <div>{{ 'repair_review.repair_credits' | translate }} ({{totalQuantityRepair}})
                                        </div>
                                        <div *ngIf="summary?.service_total; else noServiceTotal">
                                            ({{summary?.service_total}}
                                            {{currency}})
                                        </div>
                                        <ng-template #noServiceTotal>(- {{currency}})</ng-template>
                                    </div>
                                    <div class="flex justify-between">
                                        <div>{{ 'repair_review.shipping' | translate }}</div>
                                        <div *ngIf="summary?.shipping_total; else noShipping">
                                            ({{summary?.shipping_total}}
                                            {{currency}})
                                        </div>
                                        <ng-template #noShipping>(- {{currency}})</ng-template>
                                    </div>
                                    <div *ngIf="summary?.discount_total > 0" class="flex justify-between">
                                        <div>{{ 'repair_review.discount' | translate }}</div>
                                        <div>(-{{summary?.discount_total}} {{currency}})</div>
                                    </div>
                                    <div class="line"></div>
                                    <div class="flex justify-between">
                                        <div class="bold-featured-text">{{ 'repair_review.total_repair' | translate }}
                                        </div>
                                        <div *ngIf="summary?.total; else noTotal" class="bold-featured-text">
                                            {{summary?.total}} {{currency}}</div>
                                        <ng-template #noTotal>(- {{currency}})</ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-column w-full">
                                <div class="flex flex-column gap--5 block-white">
                                    <div class="flex flex-column">
                                        <form #form="ngForm" class="flex gap--5" style="align-items: end;">
                                            <div class="relative flex flex-column w-full form-input">
                                                <label class="mb--5">{{'repair_review.discount_code' | translate
                                                    }}</label>
                                                <input id="discountCode" type="text" [(ngModel)]="discountCode"
                                                    name="discountCode" required maxlength="50"
                                                    [ngClass]="{'ng-invalid-input' : (!discountCode && submittedDiscount) || (discountCode && submittedDiscount && errorSubmittedDiscount)}">
                                            </div>
                                            <button (click)="applyDiscountCode()"
                                                [disabled]="!selectedRepairPoint?.id_repairer"
                                                class="button-btn">Apply</button>
                                        </form>
                                        <div *ngIf="!discountCode && submittedDiscount" class="extra-text-invalid">
                                            {{'repair_review.invalid_discount_code' |
                                            translate }}</div>
                                        <div *ngIf="discountCode && submittedDiscount && errorSubmittedDiscount"
                                            class="extra-text-invalid">
                                            {{'repair_review.discount_not_valid' |
                                            translate }}</div>
                                    </div>
                                    <div *ngIf="summary?.discount" class="flex gap--5 discount-tag extra-text">
                                        {{summary?.discount}}<mat-icon
                                            style="font-size: 15px; width: auto; height: auto; cursor: pointer;"
                                            (click)="removeDiscountCode()">clear</mat-icon></div>
                                </div>
                            </div>
                            <div *ngIf="!token" class="w-full">
                                <div class="error-message justify-center" *ngIf="submitted && errorSubmitted">
                                    <div class="flex">
                                        <mat-icon>error_outline</mat-icon>
                                    </div>
                                    {{ 'repair_review.submit_error' | translate }}
                                </div>
                                <div class="error-message justify-center"
                                    *ngIf="submitted && !selectedRepairPoint?.id_repairer">
                                    <div class="flex">
                                        <mat-icon>error_outline</mat-icon>
                                    </div>
                                    {{ 'repair_review.submit_error_repaired' | translate }}
                                </div>
                                <button class="w-full button-btn" type="submit" (click)="submitRepair()"
                                    [disabled]="!dataAddress || !returnDetails">
                                    <div class="flex h-100 relative gap-1 align-center justify-center">
                                        <div class="relative justify-start">
                                            <mat-spinner *ngIf="loadButton"></mat-spinner>{{
                                            'repair_review.proceed_to_payment' | translate }}
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div class="flex flex-column gap--5 w-full opacity-0"
                                [ngClass]="{'opacity-100' : paymentActive}">
                                <div class="flex flex-column gap-1" [ngClass]="{'hidden' : !paymentActive}">
                                    <form #checkout="ngForm" (ngSubmit)="onSubmit()"
                                        class="checkout flex flex-column gap-1">
                                        <div class="flex flex-column gap-1 block-white selected">
                                            <label for="card-info"><b>{{ 'repair_review.pay_bank_card' | translate
                                                    }}</b></label>
                                            <div id="card-info" #cardInfo></div>

                                            <div id="card-errors" class="extra-text" role="alert" *ngIf="error">{{ error
                                                }}</div>
                                        </div>
                                        <div class="w-full">
                                            <div class="error-message justify-center"
                                                *ngIf="submitted && errorSubmittedPayment">
                                                <div class="flex">
                                                    <mat-icon>error_outline</mat-icon>
                                                </div>
                                                <div *ngIf="errorMessagePayment">{{errorMessagePayment}}</div>
                                                <div *ngIf="!errorMessagePayment">{{
                                                    'repair_review.submit_error_payment'
                                                    | translate }}</div>
                                            </div>
                                            <button class="w-full button-btn" type="submit">
                                                <div class="flex h-100 relative gap-1 align-center justify-center">
                                                    <div class="relative justify-start">
                                                        <mat-spinner *ngIf="loadButton"></mat-spinner>{{
                                                        'repair_review.pay' | translate }}
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer></footer>