import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ReturnService } from 'src/app/service/return.service';

import { AppComponent } from 'src/app/app.component';
import { NotificationService } from 'src/app/service/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

import { changelanguageShared } from '../change-language/change-languageShared.service'

@Component({
  selector: 'app-return-menu',
  templateUrl: './return-menu.component.html',
  styleUrls: ['./return-menu.component.css'],
})
@Injectable({
  providedIn: 'root', // just before your class
})
export class ReturnMenuComponent implements OnInit {

  returnDetails: any = JSON.parse(localStorage.getItem('returnDetails')!);

  appDetails: any = this.main.appDetails;

  permissionRefund: any = JSON.parse(localStorage.getItem('permissionRefund')!);

  selectedProduct: any = JSON.parse(localStorage.getItem("selectedProduct")!);

  secondHandProducts: any = JSON.parse(localStorage.getItem('secondHandProducts')!);

  nonResellable2HProducts: any = JSON.parse(localStorage.getItem('nonResellable2HProducts')!);

  secondHandConditions: any = JSON.parse(localStorage.getItem('secondHandConditions')!);

  secondHandFees: any = JSON.parse(localStorage.getItem('secondHandFees')!);

  repairProducts: any = JSON.parse(localStorage.getItem('repairProducts')!);

  repairServices: any = JSON.parse(localStorage.getItem('repairServices')!);

  repairersList: any = JSON.parse(localStorage.getItem('repairers_list')!);

  recycleProducts: any = JSON.parse(localStorage.getItem('recycleProducts')!);

  permission2Hand: boolean = false;

  permissionRepair: boolean = false;

  permissionRecycle: boolean = false;

  secondHandCountries: any = [];

  divs: any[] = []

  selectedDiv: any = null;

  allSubmissions: any;

  notificationNumber: number = 0;

  static oneTimeNav: boolean = false;

  resetDivs() {
    this.divs = [];
  }

  registerNgOnInit() {
    this.changelanguageShared.registerNgOnIni({
      func: () => this.ngOnInit(),
      div_: () => this.resetDivs()
    });
  }

  constructor(
    private main: AppComponent,
    private returnService: ReturnService,
    private router: Router,
    private notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private changelanguageShared: changelanguageShared
  ) {
  }

  data_count: number = 20;

  //iniciar as submissões
  async initSubmissions() {
    this.notificationService.isRemoveNot = false;
    if (sessionStorage.getItem('hasLogged') == "true") {
      this.allSubmissions = {
        "Return requests": this.getSubmissionsGroupByType(this.returnDetails.returnsSub, "idSubmission"),
        "Sell requests": this.getSubmissionsGroupByType(this.returnDetails.secondHandChildsSub, "idSubmission"),
        "Repair requests": this.getSubmissionsGroupByType(this.returnDetails.repairsSub, "idSubmission")
      }
      this.notificationNumber = this.returnDetails.returnsSub.length + this.returnDetails.secondHandChildsSub.length + this.returnDetails.repairsSub.length

      if ((typeof this.returnDetails.order_lines == 'undefined' || this.returnDetails.order_lines?.length == 0) && this.returnDetails.repair_lines_length == 0 && this.returnDetails.secondHand_lines_length == 0 && !ReturnMenuComponent.oneTimeNav) {
        this.router.navigate(['history/submission-history'])
        this.notificationService.removeNotification();
        ReturnMenuComponent.oneTimeNav = !ReturnMenuComponent.oneTimeNav;
      }
    }
    if (!this.notificationService.isRemoveNot)
      this.notificationService.updateData(this.notificationNumber);

    localStorage.setItem('allSubmissions', JSON.stringify(this.allSubmissions));
    localStorage.setItem('not', JSON.stringify(this.notificationNumber));

  }

  getSubmissionsGroupByType(arr: any, key: any) {
    return [...arr.reduce((acc: any, o: any) =>
      acc.set(o[key], (acc.get(o[key]) || []).concat(o))
      , new Map).values()];
  }

  async ngOnInit(): Promise<void> {
    if (this.permissionRefund && this.appDetails["returns"] == 1) {
      await this.translateService.get(['menu.return-' + environment.theme, 'menu.text-return'])
        .subscribe(translations => {
          this.divs.push({
            id: 0, image: 'assets/brand' + environment.id_brand + '/menu-banner-1.png', title: translations['menu.return-' + environment.theme],
            text: translations['menu.text-return']
          })
        });
    }
    if (this.appDetails["2hand"] == 1 && (sessionStorage.getItem('hasLogged') !== "true-search" || (sessionStorage.getItem('hasLogged') === "true-search" && this.selectedProduct["2hand"] == 1))) {
      await this.translateService.get(['menu.sell-' + environment.theme, 'menu.text-sell'], { url: this.appDetails['2hand_link'], secondhand: this.appDetails['2hand_link'].split("://")[1] })
        .subscribe(translations => {
          this.divs.push({
            id: 1, image: 'assets/brand' + environment.id_brand + '/menu-banner-2.png', title: translations['menu.sell-' + environment.theme],
            text: translations['menu.text-sell']
          })
        });
      this.secondHandCountries = this.appDetails["2hand_countries"]
      this.permission2Hand = this.secondHandCountries.find((el: any) => el.country_code == this.returnDetails?.order_shipping?.country_code) ? true : false
    }

    if (this.appDetails.repair == 1 && (sessionStorage.getItem('hasLogged') !== "true-search" || (sessionStorage.getItem('hasLogged') === "true-search" && this.selectedProduct.repair == 1))) {
      this.permissionRepair = true
      await this.translateService.get(['menu.repair-' + environment.theme, 'menu.text-repair'])
        .subscribe(translations => {
          this.divs.push({
            id: 2, image: 'assets/brand' + environment.id_brand + '/menu-banner-3.png', title: translations['menu.repair-' + environment.theme],
            text: translations['menu.text-repair']
          })
        });
    }

    if (this.appDetails.recycle == 1 && (sessionStorage.getItem('hasLogged') !== "true-search" || (sessionStorage.getItem('hasLogged') === "true-search"))) {
      await this.translateService.get(['menu.recycle-' + environment.theme, 'menu.text-recycle'])
        .subscribe(translations => {
          this.divs.push({
            id: 3, image: 'assets/brand' + environment.id_brand + '/menu-banner-4.png', title: translations['menu.recycle-' + environment.theme],
            text: translations['menu.text-recycle']
          })
        });
    }

    setTimeout(() => {
      this.divs.map((div) => {
        var p = document.createElement("p");
        p.innerHTML = div.text;
        document.getElementsByClassName('card-div-' + div.id)[0]?.appendChild(p)
        var element = document.getElementsByClassName('card-div-' + div.id)[0]
        element?.addEventListener('click', function (event: Event) {
          event.stopPropagation()
        });
      })
    })

    if (this.appDetails["2hand"] == 0 && this.appDetails.repair == 0) {
      this.permission2Hand = false
      this.permissionRepair = false
      this.goReturn()
    }

    await this.initSubmissions();

    if (sessionStorage.getItem('hasLogged') == "false") {
      sessionStorage.setItem('hasLogged', JSON.stringify(true))
    }
    sessionStorage.setItem('permission2Hand', JSON.stringify(this.permission2Hand))
    sessionStorage.setItem('permissionRepair', JSON.stringify(this.permissionRepair))

    this.resizeWindow()
    window.addEventListener('resize', this.resizeWindow)
    this.registerNgOnInit();
  }

  resizeWindow() {
    var height = document.body.offsetHeight - 80 - 30
    if (document.getElementById('menu')) {
      document.getElementById('menu')!.style.height = height + "px"
    }
  }

  goReturn() {
    this.router.navigate(['/return/return-request'])
  }

  async go2Hand() {
    if (!this.secondHandProducts) {
      var orderID = JSON.parse(localStorage.getItem('orderInfo')!)?.id
      await this.returnService.get2HandProducts(orderID).then((details2Hand) => {
        var secondHandProducts = details2Hand["2hand_products"]
        this.secondHandConditions = details2Hand["2hand_condictions"]
        this.secondHandFees = details2Hand["2handfees"]

        this.secondHandProducts = secondHandProducts.filter((product: any) => product.resellable == true)
        this.nonResellable2HProducts = secondHandProducts.filter((product: any) => product.resellable == false)
      })

      localStorage.setItem('secondHandProducts', JSON.stringify(this.secondHandProducts))
      localStorage.setItem('nonResellable2HProducts', JSON.stringify(this.nonResellable2HProducts))
      localStorage.setItem('secondHandConditions', JSON.stringify(this.secondHandConditions))
      localStorage.setItem('secondHandFees', JSON.stringify(this.secondHandFees[0]))
    }
    this.router.navigate(['/sell/sell-choosepiece'])
  }

  async goRepair() {
    if (!this.repairProducts) {
      var orderID = JSON.parse(localStorage.getItem('orderInfo')!)?.id
      await this.returnService.getProductsRepair(orderID).then((data) => {
        this.repairProducts = data.products
        this.repairServices = data.services
        this.repairersList = data.repairers_list
      })
      var productsToRepair = JSON.parse(localStorage.getItem('productsToRepair')!)
      if (productsToRepair?.length > 0) {
        productsToRepair.map((product: any) => {
          this.repairProducts.find((p: any) => p.sku == product.sku).repairServices = product.repairServices
          this.repairProducts.find((p: any) => p.sku == product.sku).totalQuantityRepair = product.totalQuantityRepair
        })
      }
      localStorage.setItem('repairProducts', JSON.stringify(this.repairProducts))
      localStorage.setItem('repairServices', JSON.stringify(this.repairServices))
      localStorage.setItem('repairers_list', JSON.stringify(this.repairersList))
    }
    if (sessionStorage.getItem('hasLogged') == "true-search") {
      if (!this.selectedProduct.repairServices || this.selectedProduct.repairServices.length == 0) {
        this.selectedProduct.repairServices = [{ id: 0, idService: this.selectedProduct.servicesAvailable[0]?.id_service, description: this.selectedProduct.servicesAvailable[0].description, price: this.selectedProduct.servicesAvailable[0].price, quantityRepair: 1, editComment: false, comment: "", commentEditable: "", images: [] }]
      }
      localStorage.setItem("selectedProduct", JSON.stringify(this.selectedProduct))
      localStorage.setItem("repairProducts", JSON.stringify([this.selectedProduct]))
      localStorage.setItem("productsToRepair", JSON.stringify([this.selectedProduct]))
      localStorage.setItem("productsToRepairSKU", JSON.stringify([this.selectedProduct.sku]))
      localStorage.setItem("repairServices", JSON.stringify(this.selectedProduct.servicesAvailable))
    }
    this.router.navigate(['/repair/repair-choosepiece'])
  }

  async goRecycle() {
    if (!this.recycleProducts) {
      this.recycleProducts = []
      this.returnDetails?.order_lines.map((line: any) => {
        line.name = line.name.substring(0, line.name.lastIndexOf(' - '))
        this.recycleProducts.push(line)
      })
      localStorage.setItem('recycleProducts', JSON.stringify(this.recycleProducts))
    }
    this.router.navigate(['/recycle/recycle-choosepiece'])
  }

  openSelected(div: any) {
    this.translateService.get(['menu.return-' + environment.theme, 'menu.sell-' + environment.theme, 'menu.repair-' + environment.theme, 'menu.recycle-' + environment.theme])
      .subscribe(translations => {
        if (div.title == translations['menu.return-' + environment.theme]) {
          this.goReturn()
        } else if (div.title == translations['menu.sell-' + environment.theme]) {
          this.go2Hand()
        } else if (div.title == translations['menu.repair-' + environment.theme]) {
          this.goRepair()
        } else if (div.title == translations['menu.recycle-' + environment.theme]) {
          this.goRecycle()
        }
      });
  }

  previousPage() {
    this.router.navigate(['']);
  }
}

export interface Submissions {
  titleSubmission?: string;
  //title - nome do produto
  title: string;
  price: number;
  IDStatus: number;
  image_url: string;
  size: string;
  color: string;
  currency: string;
  statusDescription: string;
  date: string;
  // idSubmission é como se fosse um id return
  idSubmission: number;
  sameIDSub?: Submissions[];
  typeSubmission?: string;
  option_1_key?: string;
  option_2_key?: string;
  option_3_key?: string;
  option_3_value?: string;
}