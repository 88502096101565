<div class=" flex flex-column justify-between background-color overflow-y-scroll  " style="height: calc(100% - 80px)">
    <div class="w-full overflow-y-scroll" style="margin-bottom: 30px;">
        <header></header>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3 ">
            <timeline *ngIf="!checkTimeline()" [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps'
                [stepID]='stepID' class="w-full flex justify-center"></timeline>
            <div *ngIf="checkTimeline()" class="w-full flex justify-center">
                <div class="md:w-90-percent w-85-percent">
                    <h1>Order history</h1>
                </div>
            </div>
            <div class="flex flex-column gap-1 md:w-90-percent main-block ">
                <div *ngIf="allSubmissions != undefined" class="flex flex-column gap-1">
                    <div *ngFor="let item of allSubmissions | keyvalue">
                        <div *ngIf="item.value.length > 0">
                            <div class="bold-featured-text p--5">{{item.key}}:</div>
                            <div class="w-full block-white flex flex-column gap-2">
                                <div *ngFor="let order of item.value">
                                    <div class="flex justify-between gap-1 mb--5">
                                        <div class="flex flex-column">
                                            <div class="bold-featured-text" style="text-align: left;">
                                                {{' #' + order[0].idSubmission}}</div>
                                            <div class="extra-text">{{getDateOnFormatString(order[0].date)}}</div>
                                        </div>
                                        <div id="status"
                                            [class]="order[0].titleSubmission === 'Repair request' && order[0].IDStatus === 2 ? 'return-badge statusR-' + order[0].IDStatus : 'return-badge status-' + order[0].IDStatus">
                                            {{order[0].statusDescription}}
                                        </div>
                                    </div>
                                    <div class="flex flex-column">
                                        <div *ngFor="let product of order"
                                            class="mt-1 w-full block-white outline-gray-soft-16">
                                            <div
                                                class="flex align-center justify-between relative h-full gap-1 product-container">
                                                <div id="product-image-{{product.sku}}" class="product-image"
                                                    [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat', minHeight: '90px'}">
                                                </div>
                                                <div id="product-container-{{product.sku}}"
                                                    class="product-details-left">
                                                    <div class="bold-featured-text">{{ product.title }}</div>
                                                    <div>{{product.size}} / {{product.color}}</div>
                                                    <div>{{product.price}} {{product.currency}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer></footer>