import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/service/notification.service';
import { ReturnService } from 'src/app/service/return.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-product-repair',
  templateUrl: './search-product-repair.component.html',
  styleUrls: ['./search-product-repair.component.css']
})
export class SearchProductRepairComponent implements OnInit {

  title = "What would you like to repair?"

  steps = ["FIND ITEM", "CONDITION REPORT", "REVIEW"]

  stepID = 0

  previousRoute = '/menu'

  orderInfo: any = JSON.parse(localStorage.getItem('orderInfo')!)

  secondHandConditions = JSON.parse(localStorage.getItem("secondHandConditions")!)

  selectedProduct: any

  products: any = []

  currency: any = JSON.parse(localStorage.getItem("currency")!)

  heightImg: any = 80

  searchProduct: string = ''

  submittedSearch: boolean = false

  successSubmittedSearch: boolean = false

  openDialog: boolean = false

  selectedSize: any

  selectedColor: any

  colors: any

  sizes: any = []

  no_option2: boolean = true;

  submittedSize: boolean = false

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    private readonly translateService: TranslateService,
    private notificationService: NotificationService
  ) { }


  async ngOnInit(): Promise<void> {
    localStorage.setItem('currency', JSON.stringify('EUR'));
    sessionStorage.setItem('hasLogged', 'true-submit-search');

    this.notificationService.updateData(JSON.parse(localStorage.getItem('not')!));

    this.translateService.stream('repair').subscribe(val => {
      this.title = val.title
      if (!this.orderInfo) {
        this.steps = [val.steps.find_item, val.steps.choose_service_repair, val.steps.review, val.steps.payment]
      } else {
        this.steps = [val.steps.choose_item, val.steps.review, val.steps.payment]
      }
    });

    this.resizeWindow()
    window.addEventListener('resize', this.resizeWindow)
  }

  resizeWindow() {
    if (document.getElementsByClassName("product-container")[0]) {
      var products = JSON.parse(localStorage.getItem('secondHandProducts_search')!)
      for (var product of products) {
        if (document.getElementById("product-container-" + product?.sku)) {
          this.heightImg = document.getElementById("product-container-" + product?.sku)!.clientHeight
          document.getElementById("product-image-" + product?.sku)!.style.height = this.heightImg + 'px'
        }
      }
    }
    if (document.getElementsByClassName("product-container")[1]) {
      var selectedProduct = JSON.parse(localStorage.getItem("selectedProduct")!)
      if (document.getElementById("product-size-container-" + selectedProduct?.sku)) {
        this.heightImg = document.getElementById("product-size-container-" + selectedProduct?.sku)!.clientHeight
        document.getElementById("product-size-image-" + selectedProduct?.sku)!.style.height = this.heightImg + 'px'
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (document.getElementsByClassName("product-container")[0]) {
        var products = JSON.parse(localStorage.getItem('secondHandProducts_search')!)
        for (var product of products) {
          if (document.getElementById("product-container-" + product?.sku)) {
            this.heightImg = document.getElementById("product-container-" + product?.sku)!.clientHeight
            document.getElementById("product-image-" + product?.sku)!.style.height = this.heightImg + 'px'
          }
        }
      }
    });
  }

  async searchProducts(event?: any) {
    if (!event || (event && event?.key === "Enter")) {
      this.submittedSearch = true
      this.successSubmittedSearch = false
      this.products = []
      if (this.searchProduct.length > 2) {
        await this.returnService.getRepairSearchProducts(this.searchProduct).then((detailsRepair) => {
          this.successSubmittedSearch = true
          this.products = detailsRepair.products
          this.secondHandConditions = detailsRepair.descriptions
          localStorage.setItem('secondHandProducts_search', JSON.stringify(this.products))
          localStorage.setItem('secondHandConditions', JSON.stringify(this.secondHandConditions))
          localStorage.setItem('repairers_list', JSON.stringify(detailsRepair.repairs_list))
          this.ngAfterViewInit()
          this.submittedSearch = false
        })
      }
    }
  }

  selectProduct(product: any) {
    this.selectedProduct = product
    this.selectedProduct.images = []
    this.submittedSize = false
    this.selectedColor = null
    this.selectedSize = null
    this.sizes = []
    if (environment.id_brand == 1) {
      this.selectedProduct.colorSizes = this.groupByColorSKU(this.selectedProduct.variants, 'option1_value', 'sku')
    } else {
      this.selectedProduct.colorSizes = this.groupByColor(this.selectedProduct.variants, 'option1_value')
    }
    this.colors = Object.keys(this.selectedProduct.colorSizes)

    localStorage.setItem('selectedProduct', JSON.stringify(this.selectedProduct))
    this.openDialog = true
    setTimeout(() => {
      if (document.getElementsByClassName("product-container")[1]) {
        var selectedProduct = JSON.parse(localStorage.getItem("selectedProduct")!)
        if (document.getElementById("product-size-container-" + selectedProduct?.sku)) {
          this.heightImg = document.getElementById("product-size-container-" + selectedProduct?.sku)!.clientHeight
          document.getElementById("product-size-image-" + selectedProduct?.sku)!.style.height = this.heightImg + 'px'
        }
      }
    })
  }

  groupByColorSKU(input: any, key1: any, key: any) {
    return input?.reduce((acc: any, currentValue: any) => {
      let groupKey = { key: currentValue[key], color: currentValue[key1] };
      groupKey.key = groupKey.key.slice(0, 14)
      if (!acc[groupKey.key + '-' + groupKey.color]) {
        acc[groupKey.key + '-' + groupKey.color] = [];
      }
      acc[groupKey.key + '-' + groupKey.color].push(currentValue);
      return acc;
    }, {});
  };

  groupByColor(input: any, key: any) {
    return input?.reduce((acc: any, currentValue: any) => {
      let groupKey = currentValue[key];
      if (!acc['-' + groupKey]) {
        acc['-' + groupKey] = [];
      }
      acc['-' + groupKey].push(currentValue);
      return acc;
    }, {});
  };

  getColorName(color: any) {
    return color.slice(color.lastIndexOf('-') + 1)
  }

  selectSize(size: any) {
    this.submittedSize = false
    this.selectedSize = size
  }

  selectColor(color: any) {
    this.submittedSize = false
    this.selectedColor = color
    this.selectedSize = null
    this.sizes = this.selectedProduct.colorSizes[color]
    this.selectedProduct.image_url = this.sizes[0].image_url
    if (this.sizes[0].option2 !== null) {
      this.no_option2 = false
    }
  }

  async confirmSize() {
    this.submittedSize = true
    if (this.selectedColor && ((!this.no_option2 && this.selectedSize) || this.no_option2)) {
      if (this.no_option2) {
        this.selectedSize = this.selectedProduct.colorSizes[this.selectedColor][0]
      }
      this.selectedProduct.color = this.getColorName(this.selectedColor)
      this.selectedProduct.sku = this.selectedSize.sku
      this.selectedProduct.ean = this.selectedSize.ean
      this.selectedProduct.product_variant_id = this.selectedSize.product_variant_id
      this.selectedProduct.size = this.selectedSize.option2_value
      this.selectedProduct.price = this.selectedSize.price
      this.selectedProduct.totalQuantityRepair = 1

      if (!this.selectedProduct.repairServices || this.selectedProduct.repairServices.length == 0) {
        this.selectedProduct.repairServices = [{ id: 0, idService: this.selectedProduct.servicesAvailable[0]?.id_service, description: this.selectedProduct.servicesAvailable[0].description, price: this.selectedProduct.servicesAvailable[0].price, quantityRepair: 1, editComment: false, comment: "", commentEditable: "", images: [] }]
      }

      localStorage.setItem("selectedProduct", JSON.stringify(this.selectedProduct))
      localStorage.setItem("repairProducts", JSON.stringify([this.selectedProduct]))
      localStorage.setItem("productsToRepair", JSON.stringify([this.selectedProduct]))
      localStorage.setItem('productsToRepairSKU', JSON.stringify([this.selectedProduct.sku]))
      localStorage.setItem("repairServices", JSON.stringify(this.selectedProduct.servicesAvailable))
      this.router.navigate(['/repair/repair-choosepiece'])
      this.openDialog = false
      this.submittedSize = false
    }
  }

  closeDialog() {
    this.no_option2 = true
    this.openDialog = false
  }

  nextStep() {
    if (this.selectedProduct.sku) {
      this.router.navigate(['/repair/repair-choosepiece'])
    } else {
      this.openDialog = true
      setTimeout(() => {
        if (document.getElementsByClassName("product-container")[1]) {
          var selectedProduct = JSON.parse(localStorage.getItem("selectedProduct")!)
          if (document.getElementById("product-size-container-" + selectedProduct?.sku)) {
            this.heightImg = document.getElementById("product-size-container-" + selectedProduct?.sku)!.clientHeight
            document.getElementById("product-size-image-" + selectedProduct?.sku)!.style.height = this.heightImg + 'px'
          }
        }
      })
    }
  }
}