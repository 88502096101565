import { Router } from '@angular/router';
import { Component, Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';

import { TranslateService } from '@ngx-translate/core';
import { ReturnService } from 'src/app/service/return.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-2hand-confirm',
  templateUrl: './2hand-confirm.component.html',
  styleUrls: ['./2hand-confirm.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class Confirm2HandComponent implements OnInit {

  login_div: boolean = true;

  title = "Review your listing";

  steps = ["CHOOSE ITEM", "CONDITION REPORT", "REVIEW"]

  stepID = 2;

  previousRoute = '/sell/sell-conditionreport';

  orderInfo: any = JSON.parse(localStorage.getItem('orderInfo')!);

  returnDetails = JSON.parse(localStorage.getItem("returnDetails")!);

  secondHandProducts = JSON.parse(localStorage.getItem("secondHandProducts")!);

  selectedProduct: any = JSON.parse(localStorage.getItem('selectedProduct')!);

  currency: any = JSON.parse(localStorage.getItem("currency")!);

  conditionSelected: any = JSON.parse(localStorage.getItem("conditionSelected")!);

  secondHandFees: any = JSON.parse(localStorage.getItem("secondHandFees")!);

  commentItem: any = JSON.parse(localStorage.getItem("commentItem")!);

  suggestedPrice: number = 0;

  oldPrice: number = 0;

  editPrice: boolean = false;

  price: number = JSON.parse(localStorage.getItem('price')!);

  login_data: any = JSON.parse(localStorage.getItem('login_data')!);

  address_data: any = JSON.parse(localStorage.getItem('address_data')!);

  heightImg: any = 80;

  images: any = [];

  submitted: boolean = false;

  errorSubmitted: boolean = false;

  submittedList: boolean = JSON.parse(localStorage.getItem('submittedList')!);

  price_processing_fee: any = JSON.parse(localStorage.getItem('price_processing_fee')!);

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    public readonly translateService: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    this.translateService.stream('confirm').subscribe(val => {
      this.title = val.title
      if (!this.orderInfo) {
        this.steps = [val.steps.find_item, val.steps.resolve, val.steps.review]
      } else {
        this.steps = [val.steps.choose_item, val.steps.resolve, val.steps.review]
      }
    });

    this.suggestedPrice = Math.round(this.selectedProduct.price * this.conditionSelected.percentage_price / 100)
    if (!this.price) {
      this.price = this.suggestedPrice
    }
    if (this.secondHandFees.processing_percentage) {
      this.selectedProduct.price_processing_fee = Number((Number(this.selectedProduct.price) - Number(this.selectedProduct.price) * Number(this.secondHandFees.processing_percentage) / 100)).toFixed(2)
      this.price_processing_fee = Number((this.price - this.price * Number(this.secondHandFees.processing_percentage) / 100)).toFixed(2)
    } else {
      this.selectedProduct.price_processing_fee = Number((Number(this.selectedProduct.price) - Number(this.secondHandFees.processing_value)).toFixed(2))
      this.price_processing_fee = Number((this.price - Number(this.secondHandFees.processing_value)).toFixed(2))
    }
    if (this.secondHandFees['2hand_processing_fee'] == 0) {
      this.submitted = true
    }
    this.images = await this.returnService.GetAllImages();
  }

  openImage(image: any) {
    var selectedImage = this.getImageById(image)
    localStorage.setItem('selectedImage', JSON.stringify(selectedImage))
    this.dialog.open(ImageDialog, { disableClose: false, height: '90%', maxWidth: '90%' });
  }

  address() {
    this.dialog.open(AddressDialog, { disableClose: false, width: '600px', maxWidth: '90%' });
  }

  logout() {
    this.login_data = null
    this.address_data = null
    localStorage.setItem('login_data', JSON.stringify(this.login_data))
    localStorage.setItem('address_data', JSON.stringify(this.address_data))
    window.location.reload();
  }

  calculatePercentageStep() {
    return 100 / this.steps.length * (this.stepID + 1) + '%'
  }

  getImageById(id: any) {
    return this.images.find((el: any) => el.id == id)?.base64
  }

  setPrice() {
    this.oldPrice = this.price
    this.editPrice = true
  }

  saveSetPrice(event?: any) {
    if ((event && event.key === "Enter") || !event) {
      this.submitted = true
      var min = this.calculateMin()
      if (this.price >= min && this.price <= Number(this.selectedProduct.price)) {
        this.editPrice = false
        localStorage.setItem('price', JSON.stringify(this.price))
      }
    }
  }

  changeYourPrice() {
    this.submitted = true
    if (this.secondHandFees.processing_percentage) {
      this.price_processing_fee = Number((this.price - this.price * Number(this.secondHandFees.processing_percentage) / 100).toFixed(2))
    } else {
      this.price_processing_fee = Number((this.price - Number(this.secondHandFees.processing_value)).toFixed(2))
    }
    localStorage.setItem('price', JSON.stringify(this.price))
    localStorage.setItem('price_processing_fee', JSON.stringify(this.price_processing_fee))
  }

  changePriceBuyerPay() {
    this.submitted = true
    if (this.secondHandFees.processing_percentage) {
      this.price = Number((this.price_processing_fee * 100 / (100 - Number(this.secondHandFees.processing_percentage))).toFixed(2))
    } else {
      this.price = Number(((this.price_processing_fee + Number(this.secondHandFees.processing_value))).toFixed(2))
    }
    localStorage.setItem('price', JSON.stringify(this.price))
    localStorage.setItem('price_processing_fee', JSON.stringify(this.price_processing_fee))
  }

  calculateMin() {
    return this.selectedProduct.price * 20 / 100
  }
  calculateMinPricePF() {
    if (this.secondHandFees.processing_percentage) {
      return (this.selectedProduct.price - this.selectedProduct.price * Number(this.secondHandFees.processing_percentage) / 100) * 20 / 100
    } else {
      return (this.selectedProduct.price * 20 / 100) - Number(this.secondHandFees.processing_value)
    }
  }

  cancelSetPrice() {
    this.submitted = false
    this.price = this.oldPrice
    this.editPrice = false
  }

  listItEnable() {
    if (this.price > this.selectedProduct.price || this.price_processing_fee > this.selectedProduct.price_processing_fee ||
      this.price_processing_fee < this.calculateMinPricePF() || this.price < this.calculateMin()) {
      return true
    }
    return false
  }

  async listIt() {
    this.login_data = JSON.parse(localStorage.getItem('login_data')!);
    if (this.login_data == null) {
      this.dialog.open(LoginDialog, { disableClose: false, width: '600px', maxWidth: '90%' });
    } else if (this.login_data != null && this.address_data == null) {
      this.dialog.open(AddressDialog, { disableClose: false, width: '600px', maxWidth: '90%' });
    } else if (this.login_data != null && this.address_data != null) {
      this.submittedList = true
      var photos: any = []
      this.selectedProduct.images.map((i: any, index: any) => {
        photos.push({ position: index, blob: (this.images.find((el: any) => el.id == i.image).base64).split(',').pop() })
      })
      var listing = {
        order_name: this.returnDetails?.ordername,
        condition_id: this.conditionSelected.condition_id,
        customer_id: this.login_data.CustomerID,
        price: this.price,
        AddressID: this.address_data.AddressID,
        sku: this.selectedProduct.sku,
        comment: this.commentItem,
        photos: photos,
        sample_collection: 0
      }
      await this.returnService.postListing(listing).then(
        (data) => {
          this.router.navigate(['/sell/sell-success'])
          this.submittedList = false
        },
        (error) => {
          this.errorSubmitted = true
          this.submittedList = false
        }
      )
    }
  }
}


@Component({
  selector: 'login-dialog',
  templateUrl: 'login-dialog.html',
})
export class LoginDialog implements OnInit {

  accounts_not_same: any = environment.accounts_not_same;

  currency: any = JSON.parse(localStorage.getItem("currency")!);

  showPassword: boolean = false;

  showConfirmPassword: boolean = false;

  first_name: any = null;

  last_name: any = null;

  email: any = null;

  password: any = null;

  confirm_password: any = null;

  haveAccount: boolean = true;

  clientId = environment.clientId

  data: any;

  acceptTerms: boolean = false;

  isLogged: boolean = false;

  submitted: boolean = false;

  error_login: boolean = false;

  error_signin: boolean = false;

  error_login_google: boolean = false;

  submittedList: boolean = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    public readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // @ts-ignore
    window.google.accounts.id.initialize({
      client_id: this.clientId,
      callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    // @ts-ignore
    window.google.accounts.id.renderButton(
      // @ts-ignore
      document.getElementById("buttonDivSignin"),
      { theme: "outline", size: "large", width: "100%", text: "signin_with" }
    );

    // @ts-ignore
    window.google.accounts.id.prompt((notification: PromptMomentNotification) => { });
  }

  async handleCredentialResponse(response: CredentialResponse) {
    var data = {
      code: response.credential,
      id_brand: environment.id_brand.toString()
    }
    await this.returnService.customerLoginWithGoogle(data).then(
      (data) => {
        var login_data = data.Customer
        var address_data = null
        if (data.Customer.Address?.length > 0) {
          var address_data = data.Customer.Address.find((address: any) => address.DefaultAddress == 1) == undefined ? data.Customer.Address[0] : data.Customer.Address.find((address: any) => address.DefaultAddress == 1)
        }
        new Confirm2HandComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
        localStorage.setItem('login_data', JSON.stringify(login_data))
        localStorage.setItem('address_data', JSON.stringify(address_data))
        window.location.reload();
      },
      (error) => {
        this.error_login_google = true
      }
    )
  }

  async logIn() {
    this.submitted = true
    if (this.haveAccount) {
      if (this.validEmail() && this.password) {
        var dataLogin = {
          email: this.email,
          password: this.password,
          id_brand: Number(environment.id_brand)
        }

        await this.returnService.customerLogin(dataLogin).then(
          (data) => {
            var login_data = data.Customer
            var address_data = null
            if (data.Customer.Address.length > 0) {
              var address_data = data.Customer.Address.find((address: any) => address.DefaultAddress == 1) == undefined ? data.Customer.Address[0] : data.Customer.Address.find((address: any) => address.DefaultAddress == 1)
            }
            localStorage.setItem('login_data', JSON.stringify(login_data))
            localStorage.setItem('address_data', JSON.stringify(address_data))
            new Confirm2HandComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
            this.isLogged = true
            window.location.reload()
          },
          (error) => {
            this.error_login = true
          }
        )
      }
    } else {
      if (this.acceptTerms && this.first_name && this.last_name && this.validEmail() && this.password && this.confirm_password && this.validPassword() && this.password == this.confirm_password) {
        var dataSignin = {
          photo: "",
          EmailAddress: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password,
          currency: this.currency,
          id_brand: Number(environment.id_brand)
        }
        await this.returnService.customerSignin(dataSignin).then(
          (data) => {
            var login_data = data.Customer
            login_data.initial = data.Customer.FirstName.substring(0, 1)
            new Confirm2HandComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
            localStorage.setItem('login_data', JSON.stringify(login_data))
            window.location.reload();
          },
          (error) => {
            this.error_signin = true
          }
        )
      }
    }
  }

  onSubmitEnter(event: any): void {
    if (event.key === "Enter") {
      this.logIn()
    }
  }

  validEmail() {
    var validateEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.email)
    if (validateEmail) {
      return true
    }
    return false
  }

  validPassword() {
    var validatePassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.:;?@[\\\]_`{|}~])[A-Za-z\d!"#$%&'()*+,-.:;?@[\\\]_`{|}~]{8,30}$/.test(this.password)
    if (validatePassword) {
      return true
    }
    return false
  }
}


@Component({
  selector: 'address-dialog',
  templateUrl: 'address-dialog.html',
})
export class AddressDialog {

  full_name: any = null;

  address: any = null;

  address2: any = null;

  city: any = null;

  zip: any = null;

  phone: any = null;

  submitted: boolean = false;

  selectedAddress: any = null;

  selectedCountry: any = null;

  selectedCountryCallingCode: any = null;

  countries: any = [];

  countryCallingCode: any = [];

  login_data: any = JSON.parse(localStorage.getItem('login_data')!);

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    public readonly translateService: TranslateService
  ) { }

  async ngOnInit() {
    this.countries = await this.returnService.getCountriesWith2HandFee(); // await this.returnService.getCountries()
    this.countryCallingCode = await this.returnService.getCountriesX()
  }
  changeSelected() {
    this.selectedCountryCallingCode = this.countryCallingCode.find((c: any) => c == this.selectedCountryCallingCode).cca2
  }

  async saveAdress() {
    this.submitted = true
    if (this.full_name && this.address && this.city && this.zip && this.phone && this.selectedCountryCallingCode && this.selectedCountry) {
      var callingCode = this.countryCallingCode.find((country: any) => country.cca2 == this.selectedCountryCallingCode)
      var callingCodePref = callingCode.idd.root + callingCode.idd.suffixes[0]
      var dataAddress = {
        CustomerID: this.login_data.CustomerID,
        address: [
          {
            first_name: this.full_name.split(' ')[0],
            last_name: this.full_name.split(' ').pop(),
            address1: this.address,
            address2: this.address2,
            zip: this.zip,
            city: this.city,
            country_id: this.selectedCountry,
            phone: callingCodePref + this.phone.toString(),
            default: this.login_data.Address == null || this.login_data.Address?.length == 0 ? true : false
          }
        ]
      }

      await this.returnService.addAddress(dataAddress).then(
        (data) => {
          var address_data = {
            AddressID: data.data[0].AddressID,
            FirstName: dataAddress.address[0].first_name,
            LastName: dataAddress.address[0].last_name,
            AddressLine1: dataAddress.address[0].address1,
            AddressLine2: dataAddress.address[0].address2,
            ZIPCode: dataAddress.address[0].zip,
            City: dataAddress.address[0].city,
            PhoneNumber: dataAddress.address[0].phone,
            DefaultAddress: Number(dataAddress.address[0].default),
          }
          if (!this.login_data.Address) {
            this.login_data.Address = [address_data]

          } else {
            this.login_data.Address.push(address_data)
          }
          new Confirm2HandComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
          localStorage.setItem('address_data', JSON.stringify(address_data))
          localStorage.setItem('login_data', JSON.stringify(this.login_data))
          window.location.reload();
        },
        (error) => {

        }
      )
    } else if (this.selectedAddress) {
      new Confirm2HandComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
      localStorage.setItem('address_data', JSON.stringify(this.selectedAddress))
      window.location.reload();
    }
  }

  cancelAddress() {
    this.full_name = null
    this.address = null
    this.address2 = null
    this.city = null
    this.zip = null

    new Confirm2HandComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
  }
}


@Component({
  selector: 'image-dialog',
  templateUrl: 'image-dialog.html',
})
export class ImageDialog {

  selectedProduct: any = JSON.parse(localStorage.getItem('selectedProduct')!);

  selectedImage: any = JSON.parse(localStorage.getItem('selectedImage')!);

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    private readonly translateService: TranslateService
  ) {
  }

  closeDialog() {
    new Confirm2HandComponent(this.router, this.dialog, this.returnService, this.translateService).dialog.closeAll()
  }
}

