import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReturnService } from 'src/app/service/return.service';

@Component({
  selector: 'app-repair-success',
  templateUrl: './repair-success.component.html',
  styleUrls: ['./repair-success.component.css']
})
export class SuccessRepairComponent {

  ordername: any = JSON.parse(localStorage.getItem('orderInfo')!)?.ordername;

  constructor(
    private router: Router,
    private returnService: ReturnService,
    public readonly translateService: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    if (!this.ordername) {
      this.ordername = ''
    }
  }

  backToHome() {
    this.router.navigate(['']);
    localStorage.clear();
    this.returnService.ClearStorage();
  }
}