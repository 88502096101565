<div class="md:w-90-percent lg:block hidden w-85-percent">
    <div class="flex">
        <div class="previousButton relative flex w-min l-0 justify-between">
            <div class="w-full cursor-pointer absolute" (click)="previousPage()">
                <svg width="32" height="32" viewBox="0 0 36 36" stroke="" role="img"
                    class="r-b0vftf r-mabqd8 r-1yvhtrz">
                    <g>
                        <path
                            d="M0.5 18C0.5 8.05888 8.55888 0 18.5 0C28.4411 0 36.5 8.05888 36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55888 36 0.5 27.9411 0.5 18Z"
                            fill="#E9EAEB" stroke=""></path>
                        <path d="M21.5 11L14.6768 17.8232C14.5791 17.9209 14.5791 18.0791 14.6768 18.1768L21.5 25"
                            stroke="#273C48" stroke-opacity="0.98" stroke-width="2" stroke-linecap="round"
                            fill="#E9EAEB"></path>
                    </g>
                </svg>
            </div>
        </div>
        <div class="w-full ml-2">
            <h1>{{steps[stepID]}}</h1>
        </div>
    </div>
</div>
<div class="lg:hidden flex mb--1-25 w-85-percent">
    <div class="previousButton relative flex w-min l-0 justify-between">
        <div class="w-full cursor-pointer absolute" (click)="previousPage()">
            <svg width="32" height="32" viewBox="0 0 36 36" stroke="" role="img" class="r-b0vftf r-mabqd8 r-1yvhtrz">
                <g>
                    <path
                        d="M0.5 18C0.5 8.05888 8.55888 0 18.5 0C28.4411 0 36.5 8.05888 36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55888 36 0.5 27.9411 0.5 18Z"
                        fill="#E9EAEB" stroke=""></path>
                    <path d="M21.5 11L14.6768 17.8232C14.5791 17.9209 14.5791 18.0791 14.6768 18.1768L21.5 25"
                        stroke="#273C48" stroke-opacity="0.98" stroke-width="2" stroke-linecap="round" fill="#E9EAEB">
                    </path>
                </g>
            </svg>
        </div>
    </div>
    <div class="flex w-full align-start justify-center">
        <div *ngIf="steps.length == 0" class="w-full ml-2">
            <h1>{{customTitle}}</h1>
        </div>
        <div *ngIf="steps.length > 0" class="relative flex timeline-container">
            <ng-container *ngFor="let step of steps; index as i">
                <div class="relative flex justify-center align-center item"
                    [class]="{'active': stepID == i, 'before-active': stepID > i}">
                    <div class="icon"></div>
                    <div class=" flex text gap--8">
                        <div class="item-text">{{step}}</div>
                        <div class="item-arrow hidden"><mat-icon>keyboard_arrow_right</mat-icon></div>
                    </div>
                </div>
                <div *ngIf="(steps.length - 1) !== i" class="separator" [class]="{'before-active': stepID > i}"></div>
            </ng-container>
        </div>
    </div>
</div>