<div mat-dialog-actions>
    <div class="w-min absolute upload-image-icon cursor-pointer" (click)="closeDialog()"
        style="top: -10px; right: -10px;">
        <svg width="24" viewBox="0 0 36 36" stroke="" focusable="true" role="img" data-focusable="true" tabindex="0">
            <g>
                <path
                    d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z"
                    fill="#000000BF" stroke=""></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.7071 11.2929C12.3166 10.9024 11.6834 10.9024 11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071L16.5858 18L11.293 23.2928C10.9024 23.6833 10.9024 24.3165 11.293 24.707C11.6835 25.0975 12.3166 25.0975 12.7072 24.707L18 19.4142L23.2928 24.707C23.6833 25.0975 24.3165 25.0975 24.707 24.707C25.0975 24.3165 25.0975 23.6833 24.707 23.2928L19.4142 18L24.7071 12.7071C25.0976 12.3166 25.0976 11.6834 24.7071 11.2929C24.3166 10.9024 23.6834 10.9024 23.2929 11.2929L18 16.5858L12.7071 11.2929Z"
                    fill="#FFFFFF" stroke=""></path>
            </g>
        </svg>
    </div>
</div>
<div mat-dialog-content class="h-full">
    <div class="flex flex-column gap-1 h-full">
        <img class="sm:obj-cover h-full" [src]="selectedImage">
    </div>
</div>