<div id="choosepiece-repair-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <header [customTitle]='steps[stepID]'></header>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-1 md:w-90-percent products-return">
                <h1 class="lg:hidden">{{ 'repair.title' | translate }}</h1>
                <div *ngIf="repairProducts?.length == 0" class="block-white">
                    <div>{{ 'repair.no_products' | translate }}</div>
                </div>
                <div *ngFor="let product of repairProducts" class="product block-white"
                    [class]="{'selected' : productsToRepairSKU?.includes(product.sku)}"
                    (click)="selectProduct(product)">
                    <div class="flex align-center justify-between relative h-full gap-1 product-container">
                        <div *ngIf="product?.image_url" class="product-image" style="height: 80px;"
                            [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat'}">
                        </div>
                        <div class="product-details-left">
                            <div class="bold-featured-text">{{ product.title }}</div>
                            <div>{{product.size}} / {{product.color}}</div>
                            <!-- <div>{{product.price}} {{currency}}</div> -->
                            <div *ngIf="this.orderInfo !== null">x{{product.quantity}}</div>
                        </div>
                    </div>

                    <div *ngIf="productsToRepairSKU?.includes(product.sku)" class="flex flex-column gap--5">
                        <div class="flex flex-column gap--5">
                            <div>
                                <div class="extra-text mb--5">{{ 'repair.repair_services' | translate }}</div>
                                <div class="product-line"></div>
                            </div>
                            <div *ngFor="let repair_service of product?.repairServices; let i = index"
                                class="flex flex-column gap--5 popUp-block">
                                <div class="flex justify-between align-center sm:gap-0 gap-1">
                                    <div class="w-full flex flex-column gap--5 return-reason-data cursor-pointer">
                                        <select required class="select-block w-full font-inherit bold-featured-text"
                                            [(ngModel)]="repair_service.idService" (change)="changeService(product, repair_service, i)">
                                            <option [ngValue]="service.id_service"
                                                *ngFor="let service of getArrayServices(product, repair_service.idService)">
                                                {{service.description}} - {{service.price}} {{currency}}</option>
                                        </select>
                                    </div>
                                    <div class="flex align-center h-full">
                                        <div class="flex sm:gap-0 gap--8 quantity-block">
                                            <!-- [disabled]="product.totalQuantityRepair <= 1 || repair_service.quantityRepair == 1" -->
                                            <button class="cursor-pointer h-full button-quantity-return"
                                                [disabled]="repair_service.quantityRepair == 1"
                                                (click)="decrementQuantity(product, repair_service.id)">
                                                <mat-icon
                                                    style="font-size: 16px; width: 16px; height: 16px;">remove</mat-icon>
                                            </button>
                                            <div class="bold-featured-text flex align-center">
                                                {{repair_service.quantityRepair}}</div>
                                            <!-- [disabled]="product.totalQuantityRepair >= product.quantity" -->
                                            <button class="cursor-pointer h-full button-quantity-return"
                                                (click)="incrementQuantity(product, repair_service.id)">
                                                <mat-icon
                                                    style="font-size: 16px; width: 16px; height: 16px;">add</mat-icon>
                                            </button>
                                        </div>
                                        <div class="flex align-center cursor-pointer"
                                            (click)="removeProduct($event, product, i)">
                                            <mat-icon>delete</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <input id="choose-file" type="file" class="file-input hidden"
                                        (change)="onFileSelected($event)" accept="image/png, image/jpeg, image/webp"
                                        multiple maxRequestLength="100000000">
                                    <div class="error-message-warning" *ngIf="error_upload"
                                        style="justify-content: start;">
                                        <div class="flex">
                                            <mat-icon>error_outline</mat-icon>
                                        </div>
                                        {{ 'request.return_reason_modal.image_error' | translate }}
                                    </div>
                                    <div class="error-message-warning" *ngIf="error_upload_format"
                                        style="justify-content: start;">
                                        <div class="flex">
                                            <mat-icon>error_outline</mat-icon>
                                        </div>
                                        {{ 'request.return_reason_modal.image_error_format' | translate }}
                                    </div>
                                    <div *ngIf="!repair_service.editComment && !repair_service.comment && repair_service.images.length == 0"
                                        class="flex flex-row gap--5">
                                        <a class="extra-text" style="color: var(--color-primary)"
                                            (click)="openEditComment(product, repair_service)">{{ 'repair.add_comment' |
                                            translate}}</a>
                                        <label class="extra-text" for="choose-file" style="color: var(--color-primary)"
                                            (click)="selectedProduct(product, i)"><a>{{ 'repair.add_photo' |
                                                translate}}</a></label>
                                    </div>
                                    <div *ngIf="repair_service.editComment" class="flex flex-column">
                                        <div class="flex sm:flex-column gap--5">
                                            <div class="relative flex flex-column w-full form-input">
                                                <label class="flex flex-row gap--5">{{ 'repair.comment' | translate}}
                                                    <div class="extra-text">{{ 'repair.enter_to_save' | translate}}
                                                    </div>
                                                </label>
                                                <input id="comment" type="text"
                                                    [(ngModel)]="repair_service.commentEditable" name="comment" required
                                                    (keypress)="saveComment($event, product, repair_service)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-column gap--5 return-reason-details"
                                        *ngIf="repair_service.comment || repair_service.images.length > 0">
                                        <div>{{repair_service.comment}}</div>
                                        <div class="flex flex-wrap gap--8">
                                            <div *ngFor="let image of repair_service.images"
                                                class="image-block relative">
                                                <div class="upload-image-icon cursor-pointer"
                                                    (click)="removeImage(product, i, image.image)">
                                                    <svg width="20" viewBox="0 0 36 36" stroke="" focusable="true"
                                                        role="img" data-focusable="true" tabindex="0">
                                                        <g>
                                                            <path
                                                                d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z"
                                                                fill="#000000BF" stroke=""></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M12.7071 11.2929C12.3166 10.9024 11.6834 10.9024 11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071L16.5858 18L11.293 23.2928C10.9024 23.6833 10.9024 24.3165 11.293 24.707C11.6835 25.0975 12.3166 25.0975 12.7072 24.707L18 19.4142L23.2928 24.707C23.6833 25.0975 24.3165 25.0975 24.707 24.707C25.0975 24.3165 25.0975 23.6833 24.707 23.2928L19.4142 18L24.7071 12.7071C25.0976 12.3166 25.0976 11.6834 24.7071 11.2929C24.3166 10.9024 23.6834 10.9024 23.2929 11.2929L18 16.5858L12.7071 11.2929Z"
                                                                fill="#FFFFFF" stroke=""></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div class="upload-image"
                                                    [style]="{background: 'url(' + getImageById(image.image) + ') center center / cover no-repeat', border: 'none'}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex flex-row gap--5">
                                            <a class="extra-text" style="color: var(--color-primary)"
                                                (click)="openEditComment(product, repair_service)">
                                                <div *ngIf="repair_service.comment">Edit comment</div>
                                                <div *ngIf="!repair_service.comment">Add comment</div>
                                            </a>
                                            <label *ngIf="repair_service.images.length < 6" class="extra-text"
                                                for="choose-file" style="color: var(--color-primary)"
                                                (click)="selectedProduct(product, i)"><a>Add
                                                    photo</a></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- (product.totalQuantityRepair < product.quantity) -->
                    <div *ngIf="productsToRepairSKU?.includes(product.sku) && product.servicesAvailable > product.repairServices"
                        class="absolute flex justify-center align-center w-full btn-more-reason">
                        <mat-icon class="icon-more-reason" (click)="openMoreReason(product)">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <button class="button-btn md:w-90-percent" type="submit"
            [disabled]="productsToRepair?.length==0 || checkValidProductToRepair()" (click)="nextStep()">{{
            'repair.next' | translate }}</button>
    </div>
</div>
<footer></footer>