import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent {

  @Input() customTitle: string = '';

  @Input() steps: any = [];

  @Input()  stepID: any;

  @Input()  previousRoute: any;

  constructor(
    private router: Router
  ) { }

  check(i:any){
    if(this.steps.length -1  == i){
      return false
    }
    return true
  }

  previousPage() {
    this.router.navigate([this.previousRoute]);
  }
}
