<div id="confirm-sell-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]' [login_div]='login_div'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div *ngIf="selectedProduct" class="flex flex-column gap-1 md:w-90-percent main-block">
                <h1 class="lg:hidden">{{title}}</h1>
                <div class="w-full flex lg:flex-column flex-row gap-1">
                    <div class="md:w-full block-white flex flex-column gap-1" [ngClass]="{'w-70-percent ': login_data}">
                        <div
                            class="flex align-start justify-between relative h-full gap-1 product-container md:flex-column">
                            <div class="flex gap-1 w-full">
                                <div *ngIf="selectedProduct?.image_url" class="product-image"
                                    [style]="{background: 'url(' + selectedProduct.image_url + ') center center / cover no-repeat', height: '80px'}">
                                </div>
                                <div class="h-min product-details-left">
                                    <div class="bold-featured-text">{{ selectedProduct.title }}</div>
                                    <div><span *ngIf="selectedProduct.size != null">{{selectedProduct.size}} /
                                        </span>{{selectedProduct.color}}</div>
                                    <div style="text-decoration: line-through">{{selectedProduct.price}} {{currency}}
                                    </div>
                                </div>
                            </div>
                            <div class="product-details-right flex flex-column justify-center">
                                <div class="flex justify-center p--5 border white-space-nowrap md:w-full">
                                    <b>{{conditionSelected.description}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="flex lg:flex-column gap-1 font-family"
                            [class]="{'flex-column': login_data && selectedProduct.images.length > 1 && commentItem != '' && commentItem}">
                            <div class="flex flex-column gap--5 lg:w-full">
                                <div>
                                    <div class="extra-text mb--5">{{ 'confirm.set_price' | translate }}</div>
                                    <div class="line"></div>
                                </div>
                                <div *ngIf="secondHandFees['2hand_processing_fee'] == 1" class="flex flex-column gap-1">
                                    <div>{{translateService.instant('confirm.price_text' , {price:
                                        selectedProduct.price, currency: currency, description:
                                        conditionSelected.description, suggestedPrice: suggestedPrice, percentage_price:
                                        conditionSelected.percentage_price })}}
                                    </div>
                                    <div class="flex flex-column">
                                        <div class="flex gap-1 align-center">
                                            <div *ngIf="!editPrice" class="font-20"><b>{{price}} {{currency}}</b></div>
                                            <mat-form-field *ngIf="editPrice" appearance="outline"
                                                class="font-20 font-bold font-family"
                                                [ngClass]="{'error' : submitted && (!price || price > selectedProduct.price || price < calculateMin())}">
                                                <input matInput type="number" step="1" [min]="calculateMin()"
                                                    (keypress)="saveSetPrice($event)" [max]="selectedProduct.price"
                                                    name="price" [(ngModel)]="price">
                                                <span matPrefix>{{currency}}</span>
                                                <button matSuffix mat-icon-button aria-label="Clear"
                                                    class="button-input" (click)="saveSetPrice()">
                                                    <mat-icon>done</mat-icon>
                                                </button>
                                                <button matSuffix mat-icon-button aria-label="Clear"
                                                    class="button-input" (click)="cancelSetPrice()">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <button *ngIf="!editPrice" (click)="setPrice()"
                                                class="button-btn-small flex align-center gap--5"><mat-icon
                                                    class="icon-small" style="color: white;">create</mat-icon><span
                                                    class="extra-text" style="color: white;">Edit price</span>
                                            </button>
                                        </div>
                                        <div *ngIf="!price && submitted; else elseif1" class="extra-text-invalid">{{
                                            'confirm.required_field' | translate }}</div>
                                        <ng-template #elseif1>
                                            <div *ngIf="price > selectedProduct.price && submitted; else elseif2"
                                                class="extra-text-invalid">
                                                {{ 'confirm.cannot_be_more_than' | translate }}
                                                {{selectedProduct.price}} {{currency}}</div>
                                        </ng-template>
                                        <ng-template #elseif2>
                                            <div *ngIf="price < calculateMin() && submitted" class="extra-text-invalid">
                                                {{ 'confirm.cannot_be_less_than' | translate }} {{calculateMin()}}
                                                {{currency}}
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div *ngIf="secondHandFees['2hand_processing_fee'] == 0" class="flex flex-column gap-1">
                                    <div>{{translateService.instant('confirm.price_text' , {price:
                                        selectedProduct.price, currency: currency, description:
                                        conditionSelected.description, suggestedPrice: suggestedPrice, percentage_price:
                                        conditionSelected.percentage_price })}}
                                    </div>
                                    <div class="flex flex-column">
                                        <div class="flex flex-wrap align-center gap-2 row-gap-1">
                                            <div class="flex flex-column">
                                                <div class="mb--5">You receive</div>
                                                <mat-form-field appearance="outline"
                                                    class="font-20 font-bold font-family"
                                                    [ngClass]="{'error' : submitted && (!price_processing_fee || price_processing_fee > selectedProduct.price_processing_fee || price_processing_fee < calculateMinPricePF())}">
                                                    <input matInput type="number" step="1" [min]="calculateMinPricePF()"
                                                        (keypress)="saveSetPrice($event)" (change)="changePriceBuyerPay()"
                                                        [max]="selectedProduct.price_processing_fee" name="price"
                                                        [(ngModel)]="price_processing_fee">
                                                    <span matPrefix>{{currency}}</span>
                                                </mat-form-field>
                                            </div>
                                            <div class="flex flex-column">
                                                <div class="flex gap--5 mb--5">Buyer pays
                                                    <mat-icon aria-hidden="false" fontIcon="help" class="cursor-pointer"
                                                        [matMenuTriggerFor]="menu">
                                                    </mat-icon>
                                                    <mat-menu #menu="matMenu">
                                                        <div class="text-small flex flex-column align-center gap--5 ">
                                                            {{translateService.instant('confirm.buyer_pays_content' ,
                                                            {price:
                                                            this.secondHandFees.processing_percentage ?
                                                            this.secondHandFees.processing_percentage + '%' :
                                                            this.secondHandFees.processing_value + 'EUR '})}}
                                                        </div>
                                                    </mat-menu>
                                                </div>
                                                <mat-form-field appearance="outline"
                                                    class="font-20 font-bold font-family"
                                                    [ngClass]="{'error' : submitted && (!price || price > selectedProduct.price || price < calculateMin())}">
                                                    <input matInput type="number" step="1" [min]="calculateMin()"
                                                        (keypress)="saveSetPrice($event)"
                                                        (change)="changeYourPrice()" [max]="selectedProduct.price"
                                                        name="price" [(ngModel)]="price">
                                                    <span matPrefix>{{currency}}</span>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div *ngIf="!price && submitted; else elseif1" class="extra-text-invalid">{{
                                            'confirm.required_field' | translate }}</div>
                                        <ng-template #elseif1>
                                            <div *ngIf="(price > selectedProduct.price || price_processing_fee > selectedProduct.price_processing_fee) && submitted; else elseif2"
                                                class="extra-text-invalid">
                                                {{ 'confirm.cannot_be_more_than' | translate }}
                                                {{selectedProduct.price}} {{currency}}</div>
                                        </ng-template>
                                        <ng-template #elseif2>
                                            <div *ngIf="(price < calculateMin() || price_processing_fee < calculateMinPricePF()) && submitted"
                                                class="extra-text-invalid">
                                                {{ 'confirm.cannot_be_less_than' | translate }} {{calculateMin()}}
                                                {{currency}}
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="flex lg:flex-column gap-1">
                                <div class="flex flex-column gap--5 lg:w-full lg:w-max-full">
                                    <div>
                                        <div class="extra-text mb--5">{{ 'confirm.your_photos' | translate }}</div>
                                        <div class="line"></div>
                                    </div>
                                    <div class="grid grid-4-columns lg:flex lg:flex-wrap gap--5">
                                        <div *ngFor="let image of selectedProduct.images"
                                            class="flex flex-column gap--5">
                                            <div class="flex flex-wrap gap--8">
                                                <div class="upload-image cursor-pointer"
                                                    (click)="openImage(image.image)"
                                                    [style]="{background: 'url(' + getImageById(image.image) + ') center center / cover no-repeat', border: 'none'}">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="commentItem != '' && commentItem"
                                    class="flex flex-column gap--5 lg:w-full lg:w-max-full w-full"
                                    style="min-width: 100px;">
                                    <div>
                                        <div class="extra-text mb--5">{{ 'confirm.your_comment' | translate }}</div>
                                        <div class="line"></div>
                                    </div>
                                    <div>
                                        {{commentItem}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="login_data" class="flex flex-column lg:w-full w-30-percent h-auto">
                        <h2 class="lg:flex hidden">{{ 'confirm.address' | translate }}</h2>
                        <div class="h-full block-white flex flex-column gap-1">
                            <div class="h-full w-full flex flex-column justify-between gap--5 font-family">
                                <div *ngIf="address_data" class="flex lg:flex-row lg:justify-between gap-1 flex-column">
                                    <div>
                                        <div>
                                            {{address_data?.FirstName}} {{address_data?.LastName}}
                                        </div>
                                        <div>
                                            {{address_data?.AddressLine1}}
                                        </div>
                                        <div>
                                            {{address_data?.City}}, {{address_data?.ZIPCode}}
                                        </div>
                                        <div *ngIf="address_data?.DefaultAddress == 1"
                                            class="extra-text align-center hidden lg:flex mt--5">
                                            {{ 'confirm.default' | translate }}
                                        </div>
                                    </div>
                                    <div class="flex lg:align-center align-start justify-between">
                                        <a (click)="address()">
                                            {{ 'confirm.change_address' | translate }}
                                        </a>
                                        <div *ngIf="address_data?.DefaultAddress == 1"
                                            class="extra-text flex align-center lg:hidden">
                                            {{ 'confirm.default' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!address_data">
                                    <div class="flex lg:align-center align-start justify-between">
                                        <a (click)="address()">
                                            {{ 'confirm.add_new_address' | translate }}
                                        </a>
                                    </div>
                                </div>
                                <div class="flex gap-1 lg:hidden">
                                    <div class="flex w-full h-min">
                                        <div class="flex gap--5 justify-center align-center">
                                            <img *ngIf="login_data.picture" src="{{login_data.picture}}" width="35"
                                                height="35" class="border-radius-50-percent">
                                            <div *ngIf="!login_data.picture" class="icon-user">{{login_data.initial}}
                                            </div>
                                            <div>
                                                <div>{{login_data.FirstName}} {{login_data.LastName}}</div>
                                                <div class="extra-text">{{login_data.Email}}</div>
                                                <a class="extra-text" (click)="logout()">
                                                    {{ 'confirm.log_out' | translate }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-column align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <div class="justify-center error-message justify-center" *ngIf="errorSubmitted">
            <div class="flex">
                <mat-icon>error_outline</mat-icon>
            </div>
            {{ 'review.submit_error' | translate }}
        </div>
        <button class="button-btn md:w-90-percent" type="submit" (click)="listIt()"
            [disabled]="submittedList || listItEnable()">
            <div class="flex h-100 relative gap-1 align-center justify-center">
                <div class="relative justify-start">
                    <mat-spinner *ngIf="submittedList"></mat-spinner>
                    {{'confirm.list_it' | translate}}
                </div>
            </div>
        </button>
    </div>
</div>
<footer></footer>