import { Component } from '@angular/core';

import { initJsStore, ReturnService } from './service/return.service';
import { environment } from 'src/environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { ReviewRepairComponent } from './pages/repair-review/repair-review.component';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = "Returns";

  appDetails: any = JSON.parse(sessionStorage.getItem('appDetails')!);

  id_token: any;

  loadButton: boolean = false;

  loadPage: boolean = false;

  idBrand: any;

  repairersList: any;

  selectedRepairPoint: any = JSON.parse(localStorage.getItem('selected_repairer_point')!);

  constructor(
    private returnService: ReturnService,
    public translate: TranslateService
  ) {
    translate.addLangs(['gb', 'pt']);
    translate.setDefaultLang('gb');
    translate.use(environment.language);
  }

  loadTheme() {
    const head = document.getElementsByTagName('head')[0];
    const themeSrc = document.getElementById('client-theme') as HTMLLinkElement;

    if (themeSrc) {
      themeSrc.href = environment.theme + '.css'
    } else {
      const style = document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = environment.theme + '.css'
      head.appendChild(style)
    }
  }

  async initMap() {
    const loader = new Loader({
      apiKey: "AIzaSyDLEKhbx5lz1WFzrVkEGmNKuUbwJI1ga0o",
      version: "weekly"
    });

    loader.load().then(async () => {
      const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
      const center: google.maps.LatLngLiteral = { lat: 41.5387600, lng: -8.6150500 }
      let map = new Map(document.getElementById("map") as HTMLElement, {
        center: center,
        zoom: 8,
      });
      // const center: google.maps.LatLngLiteral = { lat: 41.5387600, lng: -8.6150500 };
      // let map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      //   center,
      //   zoom: 1
      // });

      var selectedIWindow: any = null;
      var selectedMarker: any = null;

      this.repairersList = JSON.parse(localStorage.getItem('repairers_list')!)
      this.repairersList.map((repairPoint: any) => {
        const markerCenter = { lat: Number(repairPoint?.latitude), lng: Number(repairPoint?.longitude) }
        const marker = new google.maps.Marker({
          position: markerCenter,
          map,
          title: repairPoint.repairer_name,
          clickable: true,
          icon: repairPoint.id_repairer == this.selectedRepairPoint?.id_repairer ? { url: "../assets/map/marker.png", scaledSize: new google.maps.Size(38, 38) } : { url: "../assets/map/marker-selected.png" }
        })
        const contentString =
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          '<h3 id="firstHeading" class="firstHeading">' + repairPoint.repairer_name + '</h3>' +
          '<div id="bodyContent">' +
          "<p>" + repairPoint.address + "</p>" +
          "<p>" + repairPoint.zipcode + " - " + repairPoint.city + "</p>" +
          "<p>" + repairPoint.country_name + "</p>" +
          "</div>" +
          "</div>";

        const infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        var dataAddress = JSON.parse(localStorage.getItem('dataAddress')!);
        if (dataAddress && !this.selectedRepairPoint?.id_repairer) {
          var pointer = this.repairersList.find((repairPoint: any) => repairPoint.id_country == dataAddress.country_id)
          if (pointer) {
            var pointerCenter = { lat: Number(pointer?.latitude), lng: Number(pointer?.longitude) }
            map.setCenter(pointerCenter)
            map.setZoom(5)
          }
        }

        if (repairPoint.id_repairer == this.selectedRepairPoint?.id_repairer) {
          selectedMarker = marker;
          selectedIWindow = infowindow;
          infowindow.open(map, marker);
          map.setCenter(markerCenter)
          map.setZoom(8)
        }

        marker.addListener("click", () => {
          if (selectedIWindow) selectedIWindow.close()
          if (selectedMarker) selectedMarker.setIcon({ url: "../assets/map/marker-selected.png" })
          marker.setIcon({ url: "../assets/map/marker.png", scaledSize: new google.maps.Size(38, 38) })
          infowindow.open(map, marker);
          this.selectedRepairPoint = repairPoint
          localStorage.setItem('selected_repairer_point', JSON.stringify(this.selectedRepairPoint))
          selectedIWindow = infowindow
          selectedMarker = marker
          map.setCenter(markerCenter)
          map.setZoom(8)
          window.dispatchEvent(new Event("newRepairerPoint"));
        });
      });
    })
  }

  async ngOnInit() {
    this.idBrand = environment.id_brand
    this.loadTheme()
    sessionStorage.setItem('idBrand', JSON.stringify(this.idBrand))
    this.id_token = new URLSearchParams(window.location.search).get('id_token');
    sessionStorage.setItem('id_token', this.id_token)

    //if it's the first time the app is run
    if (sessionStorage.getItem('firstRun') === null || sessionStorage.getItem('firstRun') === 'true') {
      this.loadPage = true
      await this.returnService.getLoginSession(this.idBrand).then((appDetails) => {
        this.loadPage = false
        this.appDetails = appDetails.brand
        sessionStorage.setItem('firstRun', 'false')
        sessionStorage.setItem('appDetails', JSON.stringify(this.appDetails))
      })
    } else {
      this.loadPage = false
      if (!this.appDetails) {
        this.appDetails = JSON.parse('appDetails')
      }
      sessionStorage.setItem('firstRun', 'false')
    }
    var font;
    if (!this.appDetails.font_cdn) {
      font = this.appDetails.free_font_cdn
    } else {
      font = this.appDetails.font_cdn
    }

    var font_format = font.substring(font.lastIndexOf('.') + 1)

    //change favicon, font and color of app
    document.querySelector('#appFavicon')!.setAttribute('href', this.appDetails.favicon_image)
    var newStyle = document.createElement('style')
    newStyle.appendChild(document.createTextNode("\
  @font-face {\
      font-family: myFont;\
      src: url('" + font + "') format('" + font_format + "');\
  }\
  "));
    document.head.appendChild(newStyle)
    document.documentElement.style.setProperty('--color-primary', this.appDetails.color)

    try {
      await initJsStore();
    } catch (error: any) {
      alert(error.message);
    }
  }
}
