import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ReturnService } from 'src/app/service/return.service';

@Component({
  selector: 'app-return-resolution',
  templateUrl: './return-resolution.component.html',
  styleUrls: ['./return-resolution.component.css']
})
export class ResolutionReturnComponent implements OnInit {

  title = "How can we resolve it?";

  steps = [""]

  stepID = 1;

  previousRoute = '/return/return-request';

  returnDetails: any = JSON.parse(localStorage.getItem('returnDetails')!);

  selectPaymentOption: any = JSON.parse(localStorage.getItem('selectPaymentOption')!);

  paymentOption: any = JSON.parse(localStorage.getItem('paymentOptions')!);

  paymentOptionSelect: any = JSON.parse(localStorage.getItem('paymentOptionSelect')!);

  returnFees: any = JSON.parse(localStorage.getItem('returnFees')!);

  currency: any = JSON.parse(localStorage.getItem('currency')!);

  countries: any;

  IBAN: any;

  submitted: boolean = false;

  heightImg: number = 80;

  constructor(
    private router: Router,
    private readonly translateService: TranslateService,
    private returnService: ReturnService
  ) { }

  async ngOnInit(): Promise<void> {

    // load data into steps, translated
    await this.translateService.get('dummyTranslation').toPromise().then();

    this.translateService.stream('resolution').subscribe(val => {
      this.title = val.title
      this.steps = [val.steps.choose_item, val.steps.resolve, val.steps.review]
    });

    if (this.paymentOption.length == 0) {
      this.paymentOption = JSON.parse(localStorage.getItem('paymentOptions')!)
      this.returnFees = JSON.parse(localStorage.getItem('returnFees')!)
    }
    this.countries = await this.returnService.getCountries()
  }

  async nextStep() {
    this.submitted = true
    if (this.selectPaymentOption.fee_flag == 1 && this.returnDetails.IBAN) {
      if (this.IBAN && this.IBAN.length <= 34 && this.checkValidIBAN()) {
        localStorage.setItem('IBAN', JSON.stringify(this.IBAN.toUpperCase()))
        this.router.navigate(['/return/return-review']);
      }
    } else {
      localStorage.setItem('IBAN', JSON.stringify(null))
      this.router.navigate(['/return/return-review']);
    }
    localStorage.setItem('selectPaymentOption', JSON.stringify(this.selectPaymentOption))
    localStorage.setItem('paymentOptionSelect', JSON.stringify(this.paymentOptionSelect))
  }

  checkValidIBAN() {
    var countriesCode = this.countries.map((c: any) => c.country_code)
    var IBANCode = this.IBAN.substring(0, 2).toUpperCase()

    return countriesCode.includes(IBANCode)
  }

  selectPayment(paymentOption: any) {
    this.selectPaymentOption = paymentOption
  }

  stopEventClick(event: any) {
    event.stopPropagation()
  }

}
