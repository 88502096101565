<div *ngIf="loadinApplyImage" style="z-index: 10000000; background: #a8a8a8b5;;"
class=" relative loadProduct flex justify-center h-full">
<mat-spinner style="top: 50% !important; left: 0px !important;"></mat-spinner>
</div>

<div id="condition-sell-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div *ngIf="selectedProduct" class="flex flex-column gap-1 md:w-90-percent main-block">
                <h1 class="lg:hidden">{{title}}</h1>
                <div class="flex gap-2 justify-between lg:flex-column">
                    <div class="flex flex-column gap-1 lg:w-full w-50-percent">
                        <div>
                            <h2>{{ 'condition_report.your_item' | translate }}</h2>
                            <div class="w-full block-white">
                                <div class="flex align-center justify-between relative h-full gap-1 product-container">
                                    <div *ngIf="selectedProduct?.image_url" class="product-image"
                                        [style]="{background: 'url(' + selectedProduct.image_url + ') center center / cover no-repeat', height: '80px'}">
                                    </div>
                                    <div class="product-details-left">
                                        <div class="bold-featured-text">{{ selectedProduct.title }}</div>
                                        <div><span *ngIf="selectedProduct.size != null">{{selectedProduct.size}} /
                                            </span>{{selectedProduct.color}}</div>
                                        <!-- <div>{{selectedProduct.price}} {{currency}}</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="relative lg:block-white">
                            <div>
                                <div class="mb--5">{{ 'condition_report.upload_photo' | translate }}</div>
                                <div class="error-message-warning" *ngIf="error_upload" style="justify-content: start;">
                                    <div class="flex">
                                        <mat-icon>error_outline</mat-icon>
                                    </div>
                                    {{ 'request.return_reason_modal.image_error' | translate }}
                                </div>
                                <div class="error-message-warning" *ngIf="error_upload_format"
                                    style="justify-content: start;">
                                    <div class="flex">
                                        <mat-icon>error_outline</mat-icon>
                                    </div>
                                    {{ 'request.return_reason_modal.image_error_format' | translate }}
                                </div>
                                <input id="choose-file" type="file" class="hidden" (change)="onFileSelected($event) "
                                    accept="image/png, image/jpeg, image/webp" multiple maxRequestLength="100000000">
                                <div class="flex flex-wrap gap--8">
                                    <div *ngIf="selectedProduct.images.length < 4" class="upload-image">
                                        <label for="choose-file"
                                            class="flex flex-column justify-center align-center cursor-pointer">
                                            <svg width="16" viewBox="0 0 16 16" stroke="" role="img">
                                                <g>
                                                    <path
                                                        d="M8.875 13C8.875 13.4832 8.48325 13.875 8 13.875C7.51675 13.875 7.125 13.4832 7.125 13V8.875H3C2.51675 8.875 2.125 8.48325 2.125 8C2.125 7.51675 2.51675 7.125 3 7.125H7.125V3C7.125 2.51675 7.51675 2.125 8 2.125C8.48325 2.125 8.875 2.51675 8.875 3V7.125H13C13.4832 7.125 13.875 7.51675 13.875 8C13.875 8.48325 13.4832 8.875 13 8.875H8.875V13Z"
                                                        fill="#273C4854" stroke-width="1.75" stroke-linecap="round"
                                                        stroke="">
                                                    </path>
                                                </g>
                                            </svg>
                                            <div class="extra-text text-center">{{ 'condition_report.max_size' |
                                                translate }}: 4MB
                                            </div>
                                        </label>
                                    </div>
                                    <div *ngIf="loading" style="z-index: 10000000; top: 60%; margin-left: calc(50% + 65px); position: absolute;"
                                                class=" relative loadProduct flex justify-center h-full">
                                                <mat-spinner></mat-spinner>
                                    </div>
                                    <div *ngFor="let image of selectedProduct.images" class="relative">
                                        <div class="upload-image-icon cursor-pointer" 
                                        (click)="loading = true; removeImage(image.image)">
                                            <svg width="24" viewBox="0 0 36 36" stroke="" focusable="true" role="img"
                                                data-focusable="true" tabindex="0">
                                                <g>
                                                    <path
                                                        d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z"
                                                        fill="#000000BF" stroke=""></path>
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7071 11.2929C12.3166 10.9024 11.6834 10.9024 11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071L16.5858 18L11.293 23.2928C10.9024 23.6833 10.9024 24.3165 11.293 24.707C11.6835 25.0975 12.3166 25.0975 12.7072 24.707L18 19.4142L23.2928 24.707C23.6833 25.0975 24.3165 25.0975 24.707 24.707C25.0975 24.3165 25.0975 23.6833 24.707 23.2928L19.4142 18L24.7071 12.7071C25.0976 12.3166 25.0976 11.6834 24.7071 11.2929C24.3166 10.9024 23.6834 10.9024 23.2929 11.2929L18 16.5858L12.7071 11.2929Z"
                                                        fill="#FFFFFF" stroke=""></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="upload-image cursor-pointer" (click)="openImage(image.image, $event)"
                                            [style]="{background: 'url(' + getImageById(image.image) + ') center center / cover no-repeat', height: '103px', border: 'none'}">
                                        </div>
                                    </div>
                                </div>
                                <div class="extra-text-invalid height-24 flex align-center" *ngIf="image_error_size" style="justify-content: start;">
                                 
                                    {{ 'condition_report.image_error_size' | translate }}
                                </div>
                                <div class="extra-text-invalid height-24 flex align-center" *ngIf="image_minimum" style="justify-content: start;">
                                  
                                    {{ 'condition_report.upload_photo' | translate }}
                                </div>
                                <div class="extra-text-invalid height-24 flex align-center" *ngIf="error_upload_max_images" style="justify-content: start;">
                                   
                                    {{ 'condition_report.image_max' | translate }}
                                </div>
                                <div *ngIf="nextStepClick && selectedProduct.images.length < 2"
                                    class="extra-text-invalid height-24 flex align-center">Please upload at least two
                                    photos</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-column gap-1 lg:w-full w-50-percent block-white">
                        <div>
                            <div class="mb--5">{{ 'condition_report.condition_item' | translate }}</div>
                            <div class="flex w-full align-center gap--5">
                                <select class="select-block w-full font-inherit" [(ngModel)]="conditionIDSelected"
                                    (change)="changeConditionSelected()">
                                    <option [ngValue]="condition.condition_id"
                                        *ngFor="let condition of secondHandConditions">
                                        {{condition.description}}</option>
                                </select>
                                <!-- #trigger="matMenuTrigger" (mouseenter)="trigger.openMenu()" -->
                                <mat-icon aria-hidden="false" fontIcon="info" class="cursor-pointer"
                                    [matMenuTriggerFor]="menu">
                                </mat-icon>
                                <mat-menu #menu="matMenu">
                                    <div class="text-small flex flex-column align-center gap--5 ">
                                        <div style="font-size: 14px;"><b>{{ 'condition_report.condition_guide' |
                                                translate }}</b></div>
                                        <div class="w-full flex flex-column align-center text-center">
                                            <div><b>NEW WITH TAGS</b></div>
                                            <div>{{ 'condition_report.new_with_tag_condition' | translate }}</div>
                                        </div>
                                        <div class="w-full flex flex-column align-center text-center">
                                            <div><b>EXCELLENT</b></div>
                                            <div>{{ 'condition_report.excellent_condition' | translate }}</div>
                                        </div>
                                        <div class="w-full flex flex-column align-center text-center">
                                            <div><b>VERY GOOD</b></div>
                                            <div>{{ 'condition_report.very_good_condition' | translate }}</div>
                                        </div>
                                        <div class="w-full flex flex-column align-center text-center">
                                            <div><b>GOOD</b></div>
                                            <div>{{ 'condition_report.good_condition' | translate }}</div>
                                        </div>
                                        <div class="w-full flex flex-column align-center text-center">
                                            <div><b>MODERATE</b></div>
                                            <div>{{ 'condition_report.moderate_condition' | translate }}</div>
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                            <div *ngIf="nextStepClick && !conditionSelected"
                                class="extra-text-invalid height-24 flex align-center">Choose
                                condition of your item</div>
                        </div>
                        <div class="form-input relative">
                            <div class="extra-text absolute p--5">{{ 'condition_report.comment' | translate }}</div>
                            <textarea id="comment" type="text" maxlength="500" [(ngModel)]="commentItem"
                                class="outline-gray-8 pt-1-5"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-column gap--5 align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <button class="button-btn md:w-90-percent" type="submit" (click)="nextStep()">{{ 'condition_report.next' |
            translate }}</button>
    </div>
</div>
<footer></footer>