<div id="find-sell-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-1 md:w-90-percent main-block">
                <h1 class="lg:hidden">{{title}}</h1>
                <div class="w-full block-white">
                    <div class="relative flex w-full form-input">
                        <input id="searchProduct" type="text" placeholder="{{ 'search_product.search_by' | translate }}"
                            [(ngModel)]="searchProduct" (keypress)="searchProducts($event)" name="searchProduct"
                            required maxlength="50">
                        <button class="ml-1 px-1 button-btn" (click)="searchProducts()">{{ 'search_product.search' |
                            translate }}</button>
                    </div>
                    <div class="error-message-warning extra-text-invalid"
                        *ngIf="submittedSearch && searchProduct.length <= 2"
                        style="margin-top: .5rem; justify-content: start;">
                        <div class="flex">
                            <mat-icon>error_outline</mat-icon>
                        </div>
                        {{ 'search_product.3_minimum_digits' | translate }}
                    </div>
                    <div *ngIf="submittedSearch  && searchProduct.length > 2 && !successSubmittedSearch"
                        style="margin-top: 1rem">
                        <div class="loadProduct flex justify-center">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                    <div *ngIf="successSubmittedSearch && products.length == 0" class="extra-text"
                        style="margin-top: .5rem">
                        {{ 'search_product.no_products' | translate }}
                    </div>
                    <div *ngFor="let product of products"
                        class="mt-1 w-full cursor-pointer block-white outline-gray-soft-16"
                        [class]="{'selected' : product.product_id == selectedProduct?.product_id}"
                        (click)="selectProduct(product)">
                        <div class="flex align-center justify-between relative h-full gap-1 product-container">
                            <div id="product-image-{{product.sku}}" class="product-image"
                                [style]="{background: 'url(' + product.image_url + ') center center / cover no-repeat', minHeight: '90px'}">
                            </div>
                            <div id="product-container-{{product.sku}}" class="product-details-left">
                                <div class="bold-featured-text">{{ product?.title }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="openDialog" class="w-full h-full flex align-center justify-center absolute request-return-option">
        <div id="choose-option" class="choose-option" role="dialog" aria-modal="true"
            [ngClass]="{'choose-option-full': !selectedProduct.image_url}">
            <div class="h-full flex gap--5">
                <div *ngIf="selectedProduct?.image_url" id="choose-option-img" class="choose-option-img md:hidden">
                    <div class="product-img"
                        [style]="{background: 'url(' + selectedProduct.image_url + ') center center / contain no-repeat'}">
                    </div>
                </div>
                <div class="choose-option-block options flex flex-column relative"
                    [ngClass]="{'choose-option-block-full': !selectedProduct.image_url}">
                    <div class="choose-option-header justify-end">
                        <div class="cursor-pointer" (click)="closeDialog()">
                            <mat-icon>close</mat-icon>
                        </div>
                    </div>
                    <div id="choose-return-or-refund"
                        class="overflow-y-scroll h-full choose-return-or-refund justify-between">
                        <div class="h-full flex flex-column gap-1">
                            <h2>{{ 'search_product.confirm_size' | translate }}</h2>
                            <div class="w-full flex flex-column gap-1 h-full justify-between">
                                <div class="w-full flex flex-column gap-1">
                                    <div
                                        class="hidden md:flex align-center justify-between relative h-full gap-1 product-container">
                                        <div id="product-size-image-{{selectedProduct?.sku}}" class="product-image"
                                            [style]="{background: 'url(' + selectedProduct.image_url + ') center center / cover no-repeat', minHeight: '70px'}">
                                        </div>
                                        <div id="product-size-container-{{selectedProduct?.sku}}"
                                            class="product-details-left">
                                            <div class="bold-featured-text">{{ selectedProduct?.title }}</div>
                                            <div *ngIf="selectedColor"><span *ngIf="selectedSize">{{selectedSize?.size}}
                                                    /
                                                </span>{{getColorName(selectedColor)}}</div>
                                            <div *ngIf="selectedSize">{{selectedSize?.price}} {{currency}}</div>
                                        </div>
                                    </div>
                                    <div class="flex flex-column gap--5">
                                        <div>{{ 'search_product.selected_' + selectedProduct.variants[0].option1 |
                                            translate }}:</div>
                                        <div class="flex gap--5 flex-wrap">
                                            <div *ngFor="let color of colors"
                                                class="mt--5 cursor-pointer block-white outline-gray-soft-16" [class]="{'selected' : color === selectedColor,
                                                'invalid-field' : submittedSize && !selectedColor}"
                                                (click)="selectColor(color)">
                                                <div
                                                    class="flex align-center justify-between relative h-full gap-1 product-container">
                                                    <div class="w-auto border-black">{{getColorName(color)}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="extra-text-invalid" *ngIf="submittedSize && !selectedColor">
                                            {{ 'request.exchange_modal.required_field' | translate }}</div>
                                    </div>
                                    <div *ngIf="!no_option2" class="flex flex-column gap--5">
                                        <div>{{ 'search_product.selected_' + selectedProduct.variants[0].option2 |
                                            translate }}:</div>
                                        <div class="flex gap--5 flex-wrap">
                                            <div *ngFor="let size of sizes"
                                                class="mt--5 cursor-pointer block-white outline-gray-soft-16" [class]="{'selected' : size.sku == selectedSize?.sku,
                                                'invalid-field' : submittedSize && !selectedSize}"
                                                (click)="selectSize(size)">
                                                <div
                                                    class="flex align-center justify-between relative h-full gap-1 product-container">
                                                    <div class="w-auto border-black">{{size.option2_value}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="extra-text-invalid" *ngIf="submittedSize && !selectedSize">
                                            {{ 'request.exchange_modal.required_field' | translate }}</div>
                                    </div>
                                </div>
                                <button class="button-btn" type="submit" (click)="confirmSize()">{{
                                    'request.return_reason_modal.confirm_btn' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex align-center justify-center w-full p-1 md:pl-0 md:pr-0 md:pt-0 next-step">
        <button class="button-btn md:w-90-percent" type="submit" [disabled]="!selectedProduct" (click)="nextStep()">{{
            'choose_piece.next' | translate }}</button>
    </div>
</div>
<footer></footer>