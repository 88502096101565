import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-submission-history',
  templateUrl: './submission-history.component.html',
  styleUrls: ['./submission-history.component.scss']
})
export class SubmissionHistoryComponent implements OnInit {

  title = "Order history";

  steps = []

  stepID = 0;

  previousRoute = '/menu';

  returnDetails: any = JSON.parse(localStorage.getItem('returnDetails')!);

  allSubmissions: any[] = JSON.parse(localStorage.getItem('allSubmissions')!);

  submissionsGroupByType: any[] = []

  constructor(private notificationService: NotificationService) {
    this.notificationService.removeNotification();
  }

  ngOnInit(): void {
    this.initSubmissions();
  }

  initSubmissions() {
    this.allSubmissions = JSON.parse(localStorage.getItem('allSubmissions')!);
  }

  getDateOnFormatString(date: any) {
    var dateString = new Intl.DateTimeFormat("en-US", { month: "short", day: "2-digit", year: "numeric", hour: '2-digit', minute: "2-digit" }).format;
    // var dateStringWithoutYear = new Intl.DateTimeFormat("en-US", { month: "short", day: "2-digit", hour: '2-digit', minute: "2-digit" }).format;
    /*if (new Date(date).getFullYear() == new Date().getFullYear()) {
      return dateStringWithoutYear(new Date(date));
    } else {*/
    return dateString(new Date(date));
    // }
  }

  checkTimeline() {
    return ((typeof this.returnDetails.order_lines == 'undefined' || this.returnDetails.order_lines?.length == 0) && this.returnDetails.repair_lines_length == 0 && this.returnDetails.secondHand_lines_length == 0)
  }

}
