import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReturnService } from 'src/app/service/return.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

import { AngularStripeService } from '@fireflysemantics/angular-stripe-service'
import { AppComponent } from 'src/app/app.component';

@Component({
    selector: 'app-repair-review',
    templateUrl: './repair-review.component.html',
    styleUrls: ['./repair-review.component.css']
})
export class ReviewRepairComponent implements OnInit {

    orderInfo: any = JSON.parse(localStorage.getItem("orderInfo")!);

    title = "Review your return";

    steps = [""]

    stepID = !this.orderInfo ? 2 : 1;

    previousRoute = '/repair/repair-choosepiece';

    returnDetails: any = JSON.parse(localStorage.getItem("returnDetails")!);

    selectedProduct: any = JSON.parse(localStorage.getItem("selectedProduct")!);

    shipping: any = JSON.parse(localStorage.getItem('shipping')!);

    currency: any = JSON.parse(localStorage.getItem('currency')!);

    repairProducts: any = JSON.parse(localStorage.getItem('repairProducts')!);

    repairServices: any = JSON.parse(localStorage.getItem('repairServices')!);

    productsToRepair: any = JSON.parse(localStorage.getItem('productsToRepair')!);

    returnFees: any = JSON.parse(localStorage.getItem('returnFees')!);

    returnFeeSelected: any = JSON.parse(localStorage.getItem('returnFeeSelected')!);

    dataAddress: any = JSON.parse(localStorage.getItem('dataAddress')!);

    countries: any = [];

    serviceIDSelected: any;

    emailAddress: any = JSON.parse(localStorage.getItem('emailAddress')!);

    shippingAddress: any = this.shipping;

    emailAux: any = this.emailAddress;

    editEmail: boolean = false;

    editAddres: boolean = false;

    submittedDiscount: boolean = false;

    errorSubmittedDiscount: boolean = false;

    totalQuantityRepair: number = 0;

    totalRepair: number = 0;

    totalServicesRepair: number = 0;

    submittedEmail: boolean = false;

    submitted: boolean = false;

    errorSubmitted: boolean = false;

    errorSubmittedPayment: boolean = false;

    loadButton: boolean = false;

    errorMessagePayment: any = null;

    heightImg: number = 80;

    id_repair: any = JSON.parse(localStorage.getItem('id_repair')!);

    images: any = []

    paymentActive: boolean = false;

    token: any;

    stripe: any;

    loading = false;

    confirmation: any;

    card: any;

    cardHandler = this.onChange.bind(this);

    error: any;

    summary: any;

    discountCode: string = JSON.parse(localStorage.getItem('discountCode')!);

    repairersList: any = JSON.parse(localStorage.getItem('repairers_list')!);

    selectedRepairPoint: any = JSON.parse(localStorage.getItem('selected_repairer_point')!);

    @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef | undefined;

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private returnService: ReturnService,
        private readonly translateService: TranslateService,
        private cd: ChangeDetectorRef,
        private stripeService: AngularStripeService,
        public app: AppComponent
    ) { }

    teste(product: any, repair_service: any) {
        var service = product.servicesAvailable.find((service: any) => service.id_service == repair_service.idService)
        return service?.description + ' - ' + service?.price + ' ' + this.currency
    }
    async ngOnInit(): Promise<void> {
        await this.app.initMap()
        window.addEventListener("newRepairerPoint", () => {
            this.getSummary();
        });
        // load data into steps, translated
        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('repair').subscribe(val => {
            this.title = val.title
            if (!this.orderInfo) {
                this.steps = [val.steps.find_item, val.steps.choose_service_repair, val.steps.review, val.steps.payment]
            } else {
                this.steps = [val.steps.choose_item, val.steps.review, val.steps.payment]
            }
        });

        this.countries = await this.returnService.getCountries()

        if (!this.returnFeeSelected && this.returnFees) {
            this.returnFeeSelected = this.returnFees[0]
        }
        if (!this.emailAddress && this.returnDetails) {
            this.emailAddress = this.returnDetails.email;
            this.emailAux = this.emailAddress
        }
        this.productsToRepair.map((product: any) => {
            this.totalQuantityRepair = product.repairServices.reduce(getTotal, this.totalQuantityRepair);
            function getTotal(total: any, item: any) {
                return total + item.quantityRepair;
            }
        });

        if (!this.dataAddress && this.returnDetails && this.shipping) {
            this.dataAddress = {
                first_name: this.returnDetails.first_name,
                last_name: this.returnDetails.last_name,
                full_name: this.returnDetails.first_name + ' ' + this.returnDetails.last_name,
                address1: this.shipping.address,
                address2: this.shipping.address2,
                zip: this.shipping.zipcode,
                city: this.shipping.city,
                country: this.shipping.country,
                country_id: this.countries.find((country: any) => country.country_code == this.shipping.country_code).country_id,
                country_code: this.shipping.country_code,
                phone: this.shipping.phone
            }
            localStorage.setItem('dataAddress', JSON.stringify(this.dataAddress))
        }

        await this.getSummary();
        this.images = await this.returnService.GetAllImages();
    }

    ngAfterViewInit() {
        var options = {
            locale: environment.language == 'gb' ? 'en' : environment.language
        }
        this.stripeService.setPublishableKey(environment.pk_payment, options).then(
            stripe => {
                this.stripe = stripe;
                const elements = stripe.elements();
                this.card = elements.create('card');
                this.card.mount(this.cardInfo?.nativeElement);
                this.card.addEventListener('change', this.cardHandler);
            });
    }

    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }

    onChange({ error }: any) {
        if (error) {
            this.error = error.message;
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }

    async onSubmit() {
        this.loadButton = true;
        const { token, error } = await this.stripe.createToken(this.card);
        await this.stripe.confirmCardPayment(this.token, {
            payment_method: {
                card: this.card
            }
        })
        if (error) {
            this.loadButton = false;
            this.errorSubmittedPayment = true;
            this.errorMessagePayment = error.message;
        } else {
            this.loadButton = false;
            this.router.navigate(['/repair/repair-success'])
        }
    }

    saveEmail(event: any, keypress: boolean) {
        if (((event.key === "Enter" && keypress) || !keypress)) {
            this.submittedEmail = true
        }
        if (this.validEmail() && ((event.key === "Enter" && keypress) || !keypress)) {
            this.emailAddress = this.emailAux
            this.editEmail = false
            localStorage.setItem('emailAddress', JSON.stringify(this.emailAddress))
        }
    }

    validEmail() {
        var validateEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.emailAux)
        if (validateEmail) {
            return true
        }
        return false
    }

    editAddress() {
        this.dialog.open(RepairAddressDialog, { disableClose: false, width: '600px', maxWidth: '90%' });
    }
    addPersonalInfo() {
        this.dialog.open(RepairPersonalInfoDialog, { disableClose: false, width: '600px', maxWidth: '90%' });
    }
    repairServiceDescription(idService: any) {
        var repairService = this.repairServices.find((repairService: any) => repairService.id_service == idService)
        return repairService.description + ' - ' + repairService.price + ' ' + this.currency
    }

    getImageById(id: any) {
        return this.images.find((el: any) => el.id == id)?.base64
    }

    async submitRepair() {
        this.submitted = true;
        this.loadButton = true;
        var repairLines = []
        if (this.selectedRepairPoint?.id_repairer) {
            for (var products of this.productsToRepair) {
                for (var repairService of products.repairServices) {
                    var img: any[] = []
                    repairService.images.map((i: any) => {
                        img.push({ blob: (this.images.find((el: any) => el.id == i.image).base64).split(',').pop() })
                    })
                    var productRepair: any = {
                        id_service: repairService.idService,
                        SKU: products.sku,
                        EAN: products.ean,
                        product_variant_id: products.product_variant_id,
                        quantity: repairService.quantityRepair,
                        comment: repairService.comment,
                        photos: img
                    }
                    repairLines.push(productRepair)
                }
            }

            var repairHeader: any = {
                order_id: this.returnDetails?.id,
                //order_name: this.returnDetails.ordername,
                currency: this.currency,
                first_name: this.returnDetails?.first_name,
                last_name: this.returnDetails?.last_name,
                address_name: this.dataAddress?.full_name,
                email_address: this.emailAddress,
                address_line1: this.dataAddress?.address1,
                address_line2: this.dataAddress?.address2,
                phone_number: this.dataAddress?.phone,
                zipcode: this.dataAddress?.zip,
                city: this.dataAddress?.city,
                province: "",
                id_country: this.dataAddress?.country_id,
                return_fee_id: this.returnFeeSelected?.return_fee_id,
                discount_code: this.summary?.discount,
                id_repairer: this.selectedRepairPoint.id_repairer,
                repairLines: repairLines
            }
            if (repairHeader.order_id) {
                this.returnService.postRepair(repairHeader).then(
                    data => {
                        this.id_repair = data.id_repair
                        this.token = data.token
                        localStorage.setItem('id_repair', JSON.stringify(this.id_repair))
                        this.paymentActive = true;
                        this.stepID++
                        this.loadButton = false;
                        this.card.focus()
                    },
                    error => {
                        this.errorSubmitted = true
                        this.loadButton = false
                        setTimeout(async () => {
                            await this.returnService.getLoginSession(this.orderInfo.idBrand).then((appDetails) => {
                                sessionStorage.setItem('appDetails', JSON.stringify(appDetails.brand))
                            })
                        }, 2500);
                    }
                )
            } else {
                this.returnService.postRepairOpenSubmit(repairHeader).then(
                    data => {
                        this.id_repair = data.id_repair
                        this.token = data.token
                        localStorage.setItem('id_repair', JSON.stringify(this.id_repair))
                        this.paymentActive = true;
                        this.stepID++
                        this.loadButton = false;
                        this.card.focus()
                    },
                    error => {
                        this.errorSubmitted = true
                        this.loadButton = false
                        setTimeout(async () => {
                            await this.returnService.getLoginSession(environment.id_brand).then((appDetails) => {
                                sessionStorage.setItem('appDetails', JSON.stringify(appDetails.brand))
                            })
                        }, 2500);
                    }
                )
            }
        } else {
            this.loadButton = false;
        }
    }

    async getSummary() {
        this.selectedRepairPoint = JSON.parse(localStorage.getItem('selected_repairer_point')!);
        if (this.selectedRepairPoint?.id_repairer) {
            var repairLines = []
            for (var products of this.productsToRepair) {
                for (var repairService of products.repairServices) {
                    var productRepair: any = {
                        id_service: repairService.idService,
                        SKU: products.sku,
                        EAN: products.ean,
                        product_variant_id: products.product_variant_id,
                        quantity: repairService.quantityRepair,
                    }
                    repairLines.push(productRepair)
                }
            }
            var currency = JSON.parse(localStorage.getItem('currency')!)
            var repairHeader: any = {
                currency: currency,
                id_country: this.dataAddress?.country_id,
                discount_code: this.discountCode,
                id_repairer: this.selectedRepairPoint.id_repairer,
                repairLines: repairLines
            }
            await this.returnService.applyDiscountCode(repairHeader).then(
                (data) => {
                    if (data.discount_total === 0 && this.discountCode !== null && this.discountCode !== "") {
                        this.errorSubmittedDiscount = true
                    } else {
                        this.summary = data
                        this.summary.discount = this.discountCode
                        if (this.discountCode && this.discountCode !== "") {
                            this.submittedDiscount = false
                            this.errorSubmittedDiscount = false
                            localStorage.setItem('discountCode', JSON.stringify(this.discountCode))
                        }
                        this.discountCode = ""
                        this.paymentActive = false
                        this.token = null
                    }
                },
                (error) => {
                    this.errorSubmittedDiscount = true
                }
            )
        }
    }
    applyDiscountCode() {
        this.submittedDiscount = true
        this.errorSubmittedDiscount = false
        this.getSummary()
    }
    removeDiscountCode() {
        this.discountCode = ""
        localStorage.setItem('discountCode', JSON.stringify(this.discountCode))
        this.getSummary()
    }
}


@Component({
    selector: 'address-dialog',
    templateUrl: 'address-dialog.html',
})
export class RepairAddressDialog {

    selectedCountryCallingCode: any = null;

    email: any = null;

    full_name: any = null;

    address: any = null;

    address2: any = "";

    city: any = null;

    zip: any = null;

    phone: any = null;

    submitted: boolean = false;

    selectedAddress: any = null;

    selectedCountry: any = null;

    countries: any = [];

    countryCallingCode: any = [];

    dataAddress = JSON.parse(localStorage.getItem('dataAddress')!);

    login_data: any = JSON.parse(localStorage.getItem('login_data')!);

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private returnService: ReturnService,
        public readonly translateService: TranslateService,
        private cd: ChangeDetectorRef,
        private stripeService: AngularStripeService,
        public app: AppComponent
    ) { }

    async ngOnInit() {
        this.countries = await this.returnService.getCountriesWithReturnFee()
        this.countryCallingCode = await this.returnService.getCountriesX();
    }

    async saveAddress() {
        this.submitted = true
        if (this.full_name && this.address && this.city && this.zip && this.phone && this.selectedCountry && this.selectedCountryCallingCode) {
            //const country_code = this.countries.find((country:any) => country.country_id === this.selectedCountry).country_code
            const callingCode = this.countryCallingCode.find((country: any) => country.cca2 == this.selectedCountryCallingCode);
            const callingCodePref = callingCode.idd.root + callingCode.idd.suffixes[0];
            this.dataAddress = {
                full_name: this.full_name,
                address1: this.address,
                address2: this.address2,
                zip: this.zip,
                city: this.city,
                country_id: this.selectedCountry,
                country: this.countries.find((country: any) => country.country_id == this.selectedCountry).country_name,
                phone: callingCodePref + this.phone.toString(),
            }
            var returnFeeSelected = this.countries.find((country: any) => country.country_id == this.selectedCountry)
            localStorage.setItem('dataAddress', JSON.stringify(this.dataAddress));
            localStorage.setItem('returnFeeSelected', JSON.stringify(returnFeeSelected));
            new ReviewRepairComponent(this.router, this.dialog, this.returnService, this.translateService, this.cd, this.stripeService, this.app).dialog.closeAll()
            window.location.reload();
        }
    }

    cancelAddress() {
        this.full_name = null
        this.address = null
        this.address2 = null
        this.city = null
        this.zip = null

        new ReviewRepairComponent(this.router, this.dialog, this.returnService, this.translateService, this.cd, this.stripeService, this.app).dialog.closeAll()
    }
}

@Component({
    selector: 'personalInfo-dialog',
    templateUrl: 'personalInfo-dialog.html',
})
export class RepairPersonalInfoDialog {

    email: any = null;

    first_name: any = null;

    last_name: any = null;

    submitted: boolean = false;

    returnDetails: any = JSON.parse(localStorage.getItem('returnDetails')!);

    constructor(
        private router: Router,
        public dialog: MatDialog,
        private returnService: ReturnService,
        public readonly translateService: TranslateService,
        private cd: ChangeDetectorRef,
        private stripeService: AngularStripeService,
        public app: AppComponent
    ) { }

    async saveAddress() {
        this.submitted = true
        if (this.email && this.first_name && this.last_name && this.validEmail()) {
            this.returnDetails = {
                first_name: this.first_name,
                last_name: this.last_name
            }

            localStorage.setItem('emailAddress', JSON.stringify(this.email));
            localStorage.setItem('returnDetails', JSON.stringify(this.returnDetails));
            new ReviewRepairComponent(this.router, this.dialog, this.returnService, this.translateService, this.cd, this.stripeService, this.app).dialog.closeAll()
            window.location.reload();
        }
    }

    cancelAddress() {
        this.email = null
        this.first_name = null
        this.last_name = null

        new ReviewRepairComponent(this.router, this.dialog, this.returnService, this.translateService, this.cd, this.stripeService, this.app).dialog.closeAll()
    }

    validEmail() {
        var validateEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.email)
        if (validateEmail) {
            return true
        }
        return false
    }
}
