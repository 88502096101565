import { Injectable } from '@angular/core';
import { returndbCon  } from './return.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor() {
    // turn on jsstore log status - help you to debug
    // turn it off in production or when you dont need
    //this.connection.setLogStatus(true);
  }

   get connection() {
    return returndbCon;
   }
}
