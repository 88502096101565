<div style="width: 100%; height: 100%; position: relative;">
    <change-language></change-language>
    <div class="flex justify-between header">
        <div *ngIf="appDetails.logo" id="header-logo" class="flex align-center relative">
            <img src="{{'data:image/png;base64,' + appDetails.logo}}" alt="logo" width="0" height="0"
                class="visibility-hidden cursor-pointer" (click)="goLoginPage()">
        </div>
        <div class="flex align-center" *ngIf="!appDetails.logo">
            <div class="header-label cursor-pointer" (click)="goLoginPage()"> {{ 'header.circular_center' | translate }}
            </div>
        </div>
        <div id="order-name" *ngIf="(!login_div && orderInfo?.ordername) || (login_div && !login_data)"
            class="order-details hidden w-full flex-column justify-start align-end text-end lg:flex sm:w-auto">
            <div class="bold-featured-text">{{ 'header.order' | translate }}
                {{orderInfo?.ordername}}</div>
            <!-- <div>{{customTitle}}</div> -->
        </div>
        <div *ngIf="login_div && login_data" class="hidden lg:flex w-auto">
            <div class="flex gap--5 justify-center align-center">
                <img *ngIf="login_data.picture" [matMenuTriggerFor]="menu" src="{{login_data.picture}}" width="35"
                    height="35" class="cursor-pointer border-radius-50-percent">
                <div *ngIf="!login_data.picture" [matMenuTriggerFor]="menu" class="cursor-pointer icon-user">
                    {{login_data.initial}}</div>
                <mat-menu #menu="matMenu">
                    <div style="padding: 1rem;">
                        <div>{{login_data.FirstName}} {{login_data.LastName}}</div>
                        <div class="extra-text">{{login_data.Email}}</div>
                    </div>
                    <div class="line"></div>
                    <button mat-menu-item (click)="logOut()">
                        <mat-icon>logout</mat-icon>
                        {{ 'header.log_out' | translate }}
                    </button>
                </mat-menu>
            </div>
        </div>
        <div *ngIf="numberNotification != 0 && orderInfo  != null" class="flex justify-center align-center"
            (click)="goHistorySubmissions()">
            <img src="../../assets/bellNotV2.svg" alt="Ícone Bell" style="width: 20px; height: 20px;">
            <span *ngIf="numberNotification!=0" class=" badge-custom">{{numberNotification}}</span>
        </div>
    </div>
</div>