<header></header>
<div id="menu" class="page-container-menu w-full h-full background-color">
    <div class="page-menu w-full h-full flex flex-column align-center">
        <div class="page-menu-cards flex w-full h-full sm:flex-column">
            <div *ngFor="let div of divs; let i = index" (click)="openSelected(div)"
                class="card relative flex h-full cursor-pointer" [class]="'card-' + div.id">
                <div class="card-image absolute w-full h-full" [style]="{background: 'url('+ div.image +')'}"></div>
                <div class="w-full h-full flex flex-column justify-center align-center absolute color-white">
                    <div class="card-text">{{div.title}}</div>
                    <div class="card-text-2 relative w-60-percent text-center" [class]="'card-div-' + div.id" ></div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer></footer>