import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recycle-choosepiece',
  templateUrl: './recycle-choosepiece.component.html',
  styleUrls: ['./recycle-choosepiece.component.css']
})
export class ChoosePieceRecycleComponent implements OnInit {

  orderInfo: any = JSON.parse(localStorage.getItem('orderInfo')!);

  title = "What would you like to recycle?";

  steps = [""]

  stepID = 0;

  previousRoute = '/menu';

  currency: any = JSON.parse(localStorage.getItem('currency')!);

  recycleProducts: any = JSON.parse(localStorage.getItem('recycleProducts')!);

  productsToRecycle: any = JSON.parse(localStorage.getItem('productsToRecycle')!);

  productsToRecycleID: any = JSON.parse(localStorage.getItem('productsToRecycleID')!);

  heightImg: number = 80;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private readonly translateService: TranslateService,
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.orderInfo == null && sessionStorage.getItem('hasLogged') == "true-submit-product") {
      this.previousRoute = '/product/' + this.productsToRecycle[0].sku
    }
    // load data into steps, translated
    await this.translateService.get('dummyTranslation').toPromise().then();

    this.translateService.stream('recycle').subscribe(val => {
      this.title = val.title
      if (!this.orderInfo) {
        this.steps = [val.steps.find_item, val.steps.review]
      } else {
        this.steps = [val.steps.choose_item, val.steps.review]
      }
    });
    if (!this.productsToRecycle) {
      this.productsToRecycle = []
      this.productsToRecycleID = []
    }
  }

  selectProduct(product: any) {
    if (!this.productsToRecycleID.includes(product.product_variant_id)) {
      product.quantityRecycle = 1
      this.productsToRecycle.push(product)
      this.productsToRecycleID.push(product.product_variant_id)
    }
    localStorage.setItem('productsToRecycle', JSON.stringify(this.productsToRecycle))
    localStorage.setItem('productsToRecycleID', JSON.stringify(this.productsToRecycleID))
  }

  decrementQuantity(product: any) {
    product.quantityRecycle--
    localStorage.setItem('productsToRecycle', JSON.stringify(this.productsToRecycle))
  }

  incrementQuantity(product: any) {
    product.quantityRecycle++
    localStorage.setItem('productsToRecycle', JSON.stringify(this.productsToRecycle))
  }

  removeProduct(event: any, product: any) {
    event.stopPropagation()
    this.productsToRecycleID = this.productsToRecycleID.filter((p: any) => p !== product.product_variant_id)
    this.productsToRecycle = this.productsToRecycle.filter((p: any) => this.productsToRecycle.includes(product.product_variant_id))
    localStorage.setItem('productsToRecycleID', JSON.stringify(this.productsToRecycleID))
    localStorage.setItem('productsToRecycle', JSON.stringify(this.productsToRecycleID))
  }

  nextStep() {
    localStorage.setItem('recycleProducts', JSON.stringify(this.recycleProducts))
    localStorage.setItem('productsToRecycle', JSON.stringify(this.productsToRecycle))
    this.router.navigate(['/recycle/recycle-review']);
  }
}