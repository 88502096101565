import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as JsStore from 'jsstore';
import { BaseService } from './base.service';
declare var require: any;

const getWorkerPath = () => {
    return require('file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js');
};

// const AUTH_API = 'http://localhost:3000/api/' || 'https://swyco-app-dev.azurewebsites.net/api/';
const AUTH_API = 'https://swyco-app-dev.azurewebsites.net/api/';
const AUTH_MARKET_API = 'https://api-dev.swyco.io/api/';

@Injectable({
    providedIn: 'root',
})
export class ReturnService extends BaseService {

    token: any;

    order_lines: any;

    isLoggedIn = false;

    constructor(private http: HttpClient) {
        super();
    }

    async getLoginSession(idbrand: any) {
        let data;
        var id_token = sessionStorage.getItem('id_token');
        if (id_token && id_token != "null") {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `${id_token}`,
            });
            data = await this.http.get<any>(AUTH_API + 'return/branddetail?id_brand=' + idbrand, { headers: headers }).toPromise();
        } else {
            data = await this.http.get<any>(AUTH_API + 'return/branddetail?id_brand=' + idbrand).toPromise();
        }
        this.token = data.User['Bearer Token'];
        sessionStorage.setItem('token', JSON.stringify(data.User['Bearer Token']))
        return data;
    }

    isAuthenticated() {
        var token = localStorage.getItem('session');
        if (this.token || token) {
            this.isLoggedIn = true;
        }

        return this.isLoggedIn;
    }

    async getReturnDetails(orderNumber: any, email: any, id_token: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http.get<any>(AUTH_API + 'return/orderdetail?email=' + email + '&orderName=' + orderNumber + '&id_token=' + id_token, { headers: headers }).toPromise();
        this.order_lines = data.returnDetails;
        return data;
    }

    async sendEmailReturnDetails(email: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        return await this.http.get<any>(AUTH_API + 'return/history/ordersEmail?email=' + email, { headers: headers }).toPromise();

    }

    async getReturnDetailsBKO(orderNumber: any, email: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http.get<any>(AUTH_API + 'return/orderdetailBKO?email=' + email + '&orderName=' + orderNumber, { headers: headers }).toPromise();
        this.order_lines = data.returnDetails;
        return data;
    }

    async getVariantQuantity(product_id: any, variant_id: any, id_token: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })
        let data = await this.http.get<any>(AUTH_API + 'return/variantquantity?product_id=' + product_id + '&variant_id=' + variant_id + '&id_token=' + id_token, { headers: headers }).toPromise();
        this.order_lines = data.returnDetails;
        return data;
    }

    async postReturn(orderReturn: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http
            .post<any>(AUTH_API + 'return', orderReturn, { headers: headers })
            .toPromise();
        return data;
    }

    async getFreeFont(id_font: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.get<any>(AUTH_API + 'return/freeFont?id_font=' + id_font, { headers: headers }).toPromise();
        return data;
    }

    async get2HandProducts(orderID: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.get<any>(AUTH_API + 'return/2hand?id_order=' + orderID, { headers: headers }).toPromise();
        return data;
    }


    async getAllSearchProducts(searchParam: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.get<any>(AUTH_API + 'return/products/all?q=' + searchParam, { headers: headers }).toPromise();
        return data;
    }

    async get2HandSearchProducts(searchParam: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.get<any>(AUTH_API + 'return/products/all-2hand?q=' + searchParam, { headers: headers }).toPromise();
        return data;
    }

    async getRepairSearchProducts(searchParam: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.get<any>(AUTH_API + 'return/products/all-repairs?q=' + searchParam, { headers: headers }).toPromise();
        return data;
    }

    async InsertImages(base64: any) {
        await this.connection.insert({
            into: "Images",
            values: [{
                base64: base64
            }]
        })
    }

    async GetAllImages() {

        var results = await this.connection.select({
            from: "Images"
        });

        return results;
    }

    async GetImage(id: any) {
        var results = await this.connection.select({
            from: "Images",
            where: {
                id: id
            }
        });
        return results;
    }

    async GetLastInput() {
        var results = await this.connection.select({
            from: "Images",
            order: {
                by: "id",
                type: "desc" //supprted sort type is - asc,desc
            },
            limit: 1
        });

        return results[0]
    }

    async DeleteImage(id: any) {
        var rowsDeleted = await this.connection.remove({
            from: "Images",
            where: {
                id: id
            }
        });
        return rowsDeleted;
    }

    
    async UpdateImage(id: any, base64:string) {
        const rowsUpdated = await this.connection.update({
            in: "Images",
            set: {
                base64: base64
            },
            where: {
                id: id
            }
        });
        return rowsUpdated;
    }

    async ClearStorage() {
        await this.connection.clear("Images");
    }

    async customerLoginWithGoogle(code: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.post<any>(AUTH_MARKET_API + 'customers/google', code, { headers: headers }).toPromise();
        return data;
    }

    async customerSignin(body: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.post<any>(AUTH_MARKET_API + 'customers/signin', body, { headers: headers }).toPromise();
        return data;
    }

    async customerLogin(body: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.post<any>(AUTH_MARKET_API + 'customers/login', body, { headers: headers }).toPromise();
        return data;
    }

    async addAddress(body: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.post<any>(AUTH_API + '2hand/customer/address', body, { headers: headers }).toPromise();
        return data;
    }

    async getCountries() {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.get<any>(AUTH_API + 'country/getCountries', { headers: headers }).toPromise();
        return data;
    }

    async getCountriesWithReturnFee() {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.get<any>(AUTH_API + 'country/getCountriesWithReturnFee', { headers: headers }).toPromise();
        return data;
    }

    async getCountriesWith2HandFee() {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.get<any>(AUTH_API + 'country/getCountriesWith2HandFee', { headers: headers }).toPromise();
        return data;
    }

    async postListing(body: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        let data = await this.http.post<any>(AUTH_API + '2hand/product/child', body, { headers: headers }).toPromise();
        return data;
    }

    async getProductsRepair(orderID: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http.get<any>(AUTH_API + 'repair/repair?id_order=' + orderID, { headers: headers }).toPromise();
        this.order_lines = data.returnDetails;
        return data;
    }

    async getServicesRepair() {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http.get<any>(AUTH_API + 'repair/repairservices', { headers: headers }).toPromise();
        return data;
    }

    async postRepair(repairData: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http.post<any>(AUTH_API + 'repair', repairData, { headers: headers }).toPromise();
        return data;
    }
    async postRepairOpenSubmit(repairData: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http.post<any>(AUTH_API + 'repair/open-submit', repairData, { headers: headers }).toPromise();
        return data;
    }
    async postRecycle(recycleData: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http.post<any>(AUTH_API + 'recycle', recycleData, { headers: headers }).toPromise();
        return data;
    }
    async postRecycleOpenSubmit(recycleData: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });

        let data = await this.http.post<any>(AUTH_API + 'recycle/open-submit', recycleData, { headers: headers }).toPromise();
        return data;
    }
    async getCountriesX() {
        let data = await this.http.get<any>('https://restcountries.com/v3.1/all?fields=name,idd,cca2').toPromise();
        data.sort((a: any, b: any) => a.name.common.localeCompare(b.name.common))
        return data;
    }

    async applyDiscountCode(repair: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });
        let data = await this.http.patch<any>(AUTH_API + 'repair/repairreview', repair, { headers: headers }).toPromise();
        return data;
    }

    getProductSKU(SKU: any) {
        if (!this.token) {
            this.token = JSON.parse(sessionStorage.getItem('token')!)
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`,
        });
        let data = this.http.get<any>(AUTH_API + 'qrCode?sku=' + SKU, { headers: headers }).toPromise();
        return data;
    }
}

// This will ensure that we are using only one instance. 
// Otherwise due to multiple instance multiple worker will be created.
export const returndbCon = new JsStore.Connection(new Worker(getWorkerPath().default));
export const dbname = 'ReturnImages';

const getDatabase = () => {
    const tblStudent: JsStore.ITable = {
        name: 'Images',
        columns: {
            id: {
                primaryKey: true,
                autoIncrement: true
            },
            base64: {
                notNull: true,
                dataType: JsStore.DATA_TYPE.String
            }
        }
    };
    const dataBase: JsStore.IDataBase = {
        name: dbname,
        tables: [tblStudent]
    };
    return dataBase;
};


export const initJsStore = async () => {
    const dataBase = getDatabase();

    const isDbCreated = await returndbCon.initDb(dataBase);
    if (isDbCreated) {
        await returndbCon.insert({
            into: "Images",
            values: [{
                base64: "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII="
            }]
        })
    }
};