import { Component, Injectable, OnInit } from '@angular/core';
//import { ActivatedRoute, Router } from '@angular/router';
import { ReturnService } from 'src/app/service/return.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
//import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recover-history-orders',
  templateUrl: './recover-history-orders.component.html',
  styleUrls: ['./recover-history-orders.component.css'],
})

@Injectable({
  providedIn: 'root', // just before your class
})

export class RecoverHistoryOrdersComponent implements OnInit {

  appDetails: any = this.main.appDetails;
  email: any | undefined;
  submitted: boolean = false;
  loadButton: boolean = false;
  idBrand: any = this.main.idBrand;
  showButton: boolean = false;
  loadPage: boolean = this.main.loadPage;
  cookieAccept: any = this.cookieService.get('accept-cookie');
  permissionReturn: boolean = JSON.parse(sessionStorage.getItem('appDetails')!)['returns']
  permissionRepair: boolean = JSON.parse(sessionStorage.getItem('appDetails')!)['repair'];
  permission2Hand: boolean = JSON.parse(sessionStorage.getItem('appDetails')!)['2hand'];
  coockie_policy: any = environment.coockie_policy;
  messageError: string = "";
  errorGetEmail: boolean = false;
  successRecoverPassword: boolean = false;

  constructor(private main: AppComponent,
    private router: Router,
    //private readonly translateService: TranslateService,
    private cookieService: CookieService,
    private returnService: ReturnService,
    // private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    localStorage.clear()
    this.returnService.ClearStorage()
    sessionStorage.setItem('hasLogged', JSON.stringify(false))
    /* this.route.queryParams.subscribe(params => {
       this.email = params['email']
       if (this.email ) {
         this.onSubmit()
       }
     });*/
  }

  async onSubmit() {
    this.submitted = true;
    this.loadButton = true;
    if (this.email) {
      this.returnService.sendEmailReturnDetails(this.email).then(
        async (returnDetails) => {
          this.submitted = false;
          this.loadButton = false;
          this.successRecoverPassword = true;
        }).catch(error => {
          this.submitted = false;
          this.errorGetEmail = true;
          this.successRecoverPassword = false;
          this.messageError = error.error.description;
        });
    } else {
      this.loadButton = false;
      this.errorGetEmail = true;
      this.successRecoverPassword = false;
      this.messageError = 'Required field'
    }
  }
  previousPage() {
    this.successRecoverPassword = false
    this.router.navigate([''])
  }

  //submit with enter key on login form
  onSubmitEnter(event: any): void {
    this.errorGetEmail = false;
    if (event.key === "Enter") {
      this.onSubmit()
    }
  }
  howItWorks() {
    document.getElementById("explain-block")?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  scroll = (): void => {
    var scrollHeight = window.document.getElementById("return-app")?.scrollTop as number
    var screenHeight = document.documentElement.clientHeight as number
    if (scrollHeight > (screenHeight / 2)) {
      this.showButton = true
    } else {
      this.showButton = false
    }
  };

  acceptCookies() {
    this.cookieService.set('accept-cookie', 'true')
    this.cookieAccept = this.cookieService.get('accept-cookie')
  }

  goToTop() {
    document.getElementById("login-block")?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}