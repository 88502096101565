<div id="return-success-app" class="h-full flex flex-column justify-between background-color">
    <div class="h-full flex justify-center align-center text-center">
        <div class="flex flex-column align-center return-success">
            <div class="flex flex-column gap-2">
                <div>
                    <mat-icon class="icon-success">check_circle</mat-icon>
                </div>
                <div>
                    <h1>{{ 'success.success' | translate }}</h1>
                    <div class="extra-text">{{translateService.instant('success.return_submitted' , {ordername:
                        ordername})}}</div>
                </div>
                <button class="button-btn" type="submit" (click)="backToHome()">{{ 'success.back_home' | translate
                    }}</button>
            </div>
        </div>
    </div>
</div>