import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ReturnService } from 'src/app/service/return.service';

@Component({
  selector: 'app-repair-choosepiece',
  templateUrl: './repair-choosepiece.component.html',
  styleUrls: ['./repair-choosepiece.component.css']
})
export class ChoosePieceRepairComponent implements OnInit {

  orderInfo: any = JSON.parse(localStorage.getItem('orderInfo')!);

  title = "What would you like to repair?";

  steps = [""]

  stepID = !this.orderInfo ? 1 : 0;

  previousRoute = '/menu';

  currency: any = JSON.parse(localStorage.getItem('currency')!);

  repairProducts: any = JSON.parse(localStorage.getItem('repairProducts')!);

  repairServices: any = JSON.parse(localStorage.getItem('repairServices')!);

  productsToRepair: any = JSON.parse(localStorage.getItem('productsToRepair')!);

  productsToRepairSKU: any = JSON.parse(localStorage.getItem('productsToRepairSKU')!);

  // repairServicesByCategory: any = JSON.parse(localStorage.getItem('repairServicesByCategory')!);

  error_upload: boolean = false;

  error_upload_format: boolean = false;

  productSelected: any = JSON.parse(localStorage.getItem('productSelected')!);

  repairServiceID: any = JSON.parse(localStorage.getItem('repairServiceID')!);

  images: any = []

  imagesUpload: any = []

  heightImg: number = 80;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private returnService: ReturnService,
    private readonly translateService: TranslateService,
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.orderInfo == null && sessionStorage.getItem('hasLogged') == "true-submit-search") {
      this.previousRoute = '/submit-repair'
    } else if (this.orderInfo == null && sessionStorage.getItem('hasLogged') == "true-submit-product") {
      this.previousRoute = '/product/' + this.productsToRepair[0].sku
    }
    // load data into steps, translated
    await this.translateService.get('dummyTranslation').toPromise().then();

    this.translateService.stream('repair').subscribe(val => {
      this.title = val.title
      if (!this.orderInfo) {
        this.steps = [val.steps.find_item, val.steps.choose_service_repair, val.steps.review, val.steps.payment]
      } else {
        this.steps = [val.steps.choose_item, val.steps.review, val.steps.payment]
      }
    });

    // this.productsToRepair.repairServices = this.repairServices
    if (!this.productsToRepairSKU) {
      this.productsToRepairSKU = []
      this.productsToRepair = []
    }

    this.images = await this.returnService.GetAllImages();
  }

  selectProduct(product: any) {
    if (!this.productsToRepairSKU.includes(product.sku)) {
      product.totalQuantityRepair = 1
      if (!product.servicesAvailable) {
        product.servicesAvailable = this.repairServices.filter((service: any) => product.Tags.split(",").includes(service.product_tag))
      }
      if (!product.repairServices || product.repairServices.length == 0) {
        product.repairServices = [{ id: 0, idService: product.servicesAvailable[0].id_service, description: product.servicesAvailable[0].description, price: product.servicesAvailable[0].price, quantityRepair: 1, editComment: false, comment: "", commentEditable: "", images: [] }];
      }
      this.productsToRepair.push(product)
      this.productsToRepairSKU.push(product.sku)
    }
    localStorage.setItem('productsToRepairSKU', JSON.stringify(this.productsToRepairSKU))
    localStorage.setItem('productsToRepair', JSON.stringify(this.productsToRepair))
    localStorage.setItem('repairProducts', JSON.stringify(this.repairProducts))
  }

  openMoreReason(product: any) {
    var productServices = product.repairServices.map((repair: any) => repair.idService)
    var servicesCategory = product.servicesAvailable.map((repair: any) => repair.id_service)
    let unique1 = productServices.filter((o: any) => servicesCategory.indexOf(o) === -1);
    let unique2 = servicesCategory.filter((o: any) => productServices.indexOf(o) === -1);
    const idService = unique1.concat(unique2)[0];
    const service = product.servicesAvailable.find((service: any) => service.id_service === idService)
    product.repairServices.push({ id: product.repairServices.length, idService: idService, description: service.description, price: service.price, quantityRepair: 1, editComment: false, comment: "", commentEditable: "", images: [] })
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).repairServices = product.repairServices
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).totalQuantityRepair++
    localStorage.setItem('productsToRepair', JSON.stringify(this.productsToRepair))
    localStorage.setItem('repairProducts', JSON.stringify(this.repairProducts))
  }

  changeService(product: any, repairService: any, index: any) {
    var repairService = product.servicesAvailable.find((service: any) => service.id_service === repairService.idService)
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).repairServices[index].idService = repairService.id_service
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).repairServices[index].description = repairService.description
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).repairServices[index].price = repairService.price
    localStorage.setItem('productsToRepair', JSON.stringify(this.productsToRepair))
  }

  decrementQuantity(product: any, index: any) {
    product.repairServices[index].quantityRepair--
    product.totalQuantityRepair--
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).repairServices = product.repairServices
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).totalQuantityRepair--
  }

  incrementQuantity(product: any, index: any) {
    product.repairServices[index].quantityRepair++
    product.totalQuantityRepair++
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).repairServices = product.repairServices
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).totalQuantityRepair++
  }

  getArrayServices(product: any, id: any) {
    let servicesID = product.repairServices.map((repair: any) => repair.idService)
    let newServicesAvailable = product.servicesAvailable.filter((service: any) => !servicesID.includes(service.id_service) || service.id_service == id);
    return newServicesAvailable
  }

  removeProduct(event: any, product: any, index: any) {

    // product.allServicesRemovedAux=[]
    // let indexAux = 0;
    // if ((index - 1) < 0) {
    //   indexAux = 0;
    // }
    // else {
    //   indexAux = index - 1;
    // }

    // for (let i = (product.repairServices.length - 1); i >= 0; i--) {//index to delete in any pos, get always last pos, to insert the remaining services
    //   if (i != index) {
    //     indexAux = i;
    //     break;
    //   }

    // }
    // product.selectedService = product.repairServices[indexAux].description;
    // const servicesToAdd = product.repairServices[index].servicesAvailable;
    // const servicesDestination = product.repairServices[indexAux].servicesAvailable;
    // const servicesToAddUnique = servicesToAdd.filter((service: any) => (
    //   !servicesDestination.some((existingService: any) => existingService.description === service.description)
    // ));
    // product.allServicesRemoved = []

    // servicesDestination.push(...servicesToAddUnique);// add non-repeated services, removed
    // product.allServicesRemoved.push(...servicesDestination)//informe what services you removed to add later

    // const allServices = product.allServices;//services servicesAvailable to choose
    // const servicesToAdd2 = product.repairServices[indexAux].servicesAvailable;


    // const existingDescriptions = new Set(allServices.map((service: any) => service.description));

    // // add services that don't exist to the services that can be chosen 
    // const servicesToAddUnique2 = servicesToAdd2.filter((service: any) => !existingDescriptions.has(service.description));

    // // add services that don't exist to the services that can be chosen  in the dropdown
    // allServices.push(...servicesToAddUnique2);// services to be shown

    // /*   if(product.allServicesAux.length==product.repairServices.length ){
    //     // product.allServicesRemovedAux=[]
    //    //  product.allServicesRemovedAux.push(...servicesToAddUnique)// force one service in the last dropdown options
    //    }*/

    if (product.repairServices.length === 1 && this.orderInfo == null && sessionStorage.getItem('hasLogged') == "true-submit-search") {
      this.router.navigate(['/submit-repair'])
    }
    event.stopPropagation()
    product.totalQuantityRepair--
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).totalQuantityRepair = product.totalQuantityRepair

    this.productsToRepair.find((p: any) => p.sku == product.sku).repairServices = this.productsToRepair.find((p: any) => p.sku == product.sku).repairServices.filter((r: any) => r.id !== index)
    product.repairServices = product.repairServices.filter((r: any) => r.id !== index)
    var i = 0;
    this.productsToRepair.find((p: any) => p.sku == product.sku).repairServices.map(function (repairService: any) { repairService.id = i; i++ })
    var y = 0;
    product.repairServices.map(function (repairService: any) { repairService.id = y; y++ })

    if (this.productsToRepair.find((p: any) => p.sku == product.sku).repairServices.length == 0) {
      this.productsToRepair = this.productsToRepair.filter((p: any) => p.sku !== product.sku)
      this.productsToRepairSKU = this.productsToRepairSKU.filter((sku: any) => sku !== product.sku)
      product.totalQuantityRepair = 0
    }
    localStorage.setItem('repairProducts', JSON.stringify(this.repairProducts))
    localStorage.setItem('productsToRepair', JSON.stringify(this.productsToRepair))
    localStorage.setItem('productsToRepairSKU', JSON.stringify(this.productsToRepairSKU))
  }

  selectedProduct(product: any, i: any) {
    this.repairServiceID = i
    this.productSelected = product
    localStorage.setItem('productSelected', JSON.stringify(this.productSelected))
    localStorage.setItem('repairServiceID', JSON.stringify(this.repairServiceID))
  }

  async onFileSelected(event: any) {
    var images = this.productSelected.repairServices[this.repairServiceID].images
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        await this.returnService.InsertImages(reader.result);
        let id_db: any = await this.returnService.GetLastInput()
        this.images.push({ id: id_db.id, base64: id_db.base64 })
        this.imagesUpload.push(id_db.id)
        this.productSelected.repairServices[this.repairServiceID].images.push({ id: images.length, image: id_db.id });
        this.productsToRepair.find((p: any) => p.sku == this.productSelected.sku).repairServices = this.productSelected.repairServices
        localStorage.setItem('repairProducts', JSON.stringify(this.repairProducts))
        localStorage.setItem('productsToRepair', JSON.stringify(this.productsToRepair))
        resolve(reader.result)
      };
      reader.onerror = error => reject(error);
    });

    for (var file of event.target.files) {
      var imagesTypesAccepted = ['image/png', 'image/jpeg', 'image/webp']
      if (images.length < 6 && imagesTypesAccepted.includes(file.type)) {
        await toBase64(file)
      } else if (!imagesTypesAccepted.includes(file.type)) {
        this.error_upload_format = true
      } else {
        this.error_upload = true
      }
      setTimeout(() => {
        this.error_upload_format = false
        this.error_upload = false
      }, 5000)
    }
  }

  async removeImage(product: any, index: any, idFile: number) {
    product.repairServices[index].images = product.repairServices[index].images.filter((e: any) => e.image != idFile)
    this.productsToRepair.find((p: any) => p.sku == product.sku).repairServices = product.repairServices
    await this.returnService.DeleteImage(idFile);
    this.images = await this.returnService.GetAllImages();
  }

  getImageById(id: any) {
    return this.images.find((el: any) => el.id == id)?.base64
  }

  openEditComment(product: any, repair_service: any) {
    repair_service.editComment = true
    this.productsToRepair.find((productRepair: any) => productRepair.sku == product.sku).repairServices = product.repairServices
  }

  saveComment(event: any, product: any, repair_service: any) {
    if (event.key === "Enter") {
      repair_service.editComment = false
      repair_service.comment = repair_service.commentEditable
      repair_service.description = product.servicesAvailable.find((service: any) => service.id_service == repair_service.idService).description
      repair_service.price = product.servicesAvailable.find((service: any) => service.id_service == repair_service.idService).price
      localStorage.setItem('productsToRepair', JSON.stringify(this.productsToRepair))
    }
  }

  checkValidProductToRepair() {
    return this.productsToRepair?.some((prod: any) => !prod.repairServices || prod.repairServices.length == 0)
  }

  nextStep() {
    /*const lastSelectElement = this.selectDropbox.nativeElement.childNodes[this.selectDropbox.nativeElement.childNodes.length-2].childNodes[0].childNodes[0].childNodes[0].childNodes[0].selected ;
    this.lastel= lastSelectElement;
    if(!this.lastel) return;*/
    localStorage.setItem('repairProducts', JSON.stringify(this.repairProducts))
    localStorage.setItem('productsToRepair', JSON.stringify(this.productsToRepair))
    this.router.navigate(['/repair/repair-review']);
  }
}