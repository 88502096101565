<div id="review-return-app" class="page-container flex flex-column justify-between background-color">
    <div class="w-full overflow-y-scroll">
        <header [customTitle]='steps[stepID]'></header>
        <div class="lg:block hidden timeline" [ngStyle]="{width: (100/steps.length * (stepID+1)) + '%'}"></div>
        <div class="pt-1-25 flex flex-column gap-2 align-center mb-3">
            <timeline [previousRoute]='previousRoute' [customTitle]='title' [steps]='steps' [stepID]='stepID'
                class="w-full flex justify-center"></timeline>
            <div class="flex flex-column gap-2 md:w-90-percent review-return">
                <div>
                    <h1 class="lg:hidden">{{ 'review.title' | translate }}</h1>
                    <div class="flex justify-between w-full gap-2 md:flex-column">
                        <div class="flex flex-column gap-1 md:w-full w-60-percent">
                            <div class="flex flex-column gap-1 return-items">
                                <h2>{{ 'review.return_items' | translate }}</h2>
                                <div *ngFor="let product of returnableProducts" class="flex flex-column gap-1 product">
                                    <div class="flex align-center relative h-full gap-1 product-container">
                                        <div *ngIf="product.image" class="product-image"
                                            [style]="{background: 'url(' + product.image + ') center center / cover no-repeat', height: '80px'}">
                                        </div>
                                        <div class="product-details-left">
                                            <div class="bold-featured-text">{{product.nameAux}}</div>
                                            <div>{{product.size}} / {{product.color}}</div>
                                            <div>{{product.price}} {{currency}}</div>
                                            <div class="md:block hidden">x{{product.totalQuantityReturn}}</div>
                                        </div>
                                        <div class="product-details-right md:hidden block">
                                            <div>x{{product.totalQuantityReturn}}</div>
                                        </div>
                                    </div>
                                    <div class="flex flex-column gap--5">
                                        <div>
                                            <div class="extra-text mb--5">{{ (product.return_reasons.length == 1 ?
                                                'review.return_reason' : 'review.return_reasons') | translate }}</div>
                                            <div class="line"></div>
                                        </div>
                                        <div *ngFor="let return_reasons of product.return_reasons"
                                            class="flex flex-column gap--5">
                                            <div class="flex justify-between align-center">
                                                <div>
                                                    <div *ngIf="return_reasons.option" class="bold-featured-text">
                                                        {{return_reasons.option.option}}</div>
                                                    <div *ngIf="return_reasons.exchange" class="bold-featured-text">
                                                        {{ 'review.return_exchange_reason' | translate }}
                                                        {{return_reasons.sizeExchange}}</div>
                                                </div>
                                                <div>
                                                    x{{return_reasons.quantityReturn}}
                                                </div>
                                            </div>
                                            <div *ngIf="return_reasons.comment || return_reasons.images.length > 0"
                                                class="flex flex-column gap--5 return-reason-details">
                                                <div>{{return_reasons.comment}}</div>
                                                <div class="flex flex-wrap gap--8">
                                                    <div *ngFor="let image of return_reasons.images">
                                                        <div class="upload-image"
                                                            [style]="{background: 'url(' + getImageById(image.image) + ') center center / cover no-repeat', border: 'none'}">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="haveRefunds" class="flex flex-column gap-1">
                                <h2>{{ 'review.resolution' | translate }}</h2>
                                <div class="block-white">
                                    <div>{{selectPaymentOption.description}}</div>
                                </div>
                            </div>
                            <div class="flex flex-column gap--5">
                                <h2>{{ 'review.shipping_address' | translate }}</h2>
                                <div class="block-white flex justify-between">
                                    <div>
                                        <div>{{dataAddress?.full_name}}</div>
                                        <div>{{dataAddress?.address1}}</div>
                                        <div>{{dataAddress?.address2}}</div>
                                        <div>{{dataAddress?.city}} {{dataAddress?.zip}}</div>
                                        <div>{{dataAddress?.province}}-{{dataAddress?.country}}</div>
                                    </div>
                                    <a *ngIf="!paymentActive" class="flex align-center extra-text" style="color: var(--color-primary)"
                                        (click)="editAddress()">{{ 'repair_review.edit' | translate }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-column gap-1 md:w-full w-40-percent">
                            <h2>{{ 'review.summary' | translate }}</h2>
                            <div class="flex flex-column gap-1 block-white">
                                <div class="flex justify-between">
                                    <div>{{ 'review.return_credits' | translate }} ({{totalQuantityReturn}})</div>
                                    <div>({{totalRefund}} {{currency}})</div>
                                </div>
                                <div *ngIf="selectPaymentOption.fee_flag == 1 && returnFees[0]" class="flex justify-between">
                                    <div>{{ 'review.return_fee' | translate }}</div>
                                    <div>(-{{returnFees[0].value}} {{currency}})</div>
                                </div>
                                <div class="line"></div>
                                <div class="flex justify-between">
                                    <div class="bold-featured-text">{{ 'review.total_refund' | translate }}</div>
                                    <div class="bold-featured-text">{{totalAll}} {{currency}}</div>
                                </div>
                            </div>
                            <section>
                                <mat-checkbox class="text-small" [(ngModel)]="acceptTerms" name="acceptTerms">{{
                                    'review.agree' | translate }}<b><a href="{{terms_conditions}}"
                                            target="_blank">{{'review.terms_conditions' | translate
                                            }}</a></b></mat-checkbox>
                                <div *ngIf="!acceptTerms && submitted"
                                    class="extra-text-invalid height-24 flex align-center">
                                    {{ 'login_modal.required_field' | translate }}</div>
                            </section>
                            <!-- <div class="extra-text">{{ 'review.review_total_info' | translate }}</div> -->
                            <div class="w-full">
                                <div class="error-message justify-center" *ngIf="submitted && errorSubmitted">
                                    <div class="flex">
                                        <mat-icon>error_outline</mat-icon>
                                    </div>
                                    {{ 'review.submit_error' | translate }}
                                </div>
                                <button class="w-full button-btn" type="submit" (click)="submitReturn()">
                                    <div class="flex h-100 relative gap-1 align-center justify-center">
                                        <div class="relative justify-start">
                                            <mat-spinner *ngIf="submitted && loadButton"></mat-spinner>{{
                                            'review.submit' | translate }}
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer></footer>