// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  id_brand: 5,
  theme: 'theme3',
  clientId: "77323059631-39p98uk84lvt3aqkr5jeng5dkvuo9o42.apps.googleusercontent.com",
  language: "gb", // pt, gb
  coockie_policy: "https://swyco.io/",
  terms_conditions: "https://swyco.io/",
  pre_label: false,
  pk_payment: 'pk_test_51MibeYCzF1a6IlQRqOLsUVyGpiz8mUMxC1vBXxsCrj72VlMGKvvVS2ik7rQKZ7KjIyZxBrGxn28cFx5YgZ7NpVwc00Nafjfle9',
  accounts_not_same: "Your primary account is not the same as a </b>second hand</b> account and they operate independently of each other."
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
